/* global  fetch */
import React, { Component } from 'react'
global.isclient && require('./rxUpload.css')
const { apiget } = global.rootRequire('classes/request')
// const rxu = global.rxu

class RxUpload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            images: props.images || [],
            callback: props.callback,
            single: props.single || 1
        }
    }

    componentDidMount() {
    }

    onClickDataUpload(e) {
        this.refs.data_image.click()
    }

    onChangeDataImageFile(e) {
        e.stopPropagation()
        e.preventDefault()

        // const match = document.cookie.match(new RegExp('authorize=([^;]+)'))

        if (typeof (e.target.files[0]) !== 'undefined') {
            const file = e.target.files[0]
            // const data = new FormData()
            apiget(global.rxu.config.base_api + '/upload/presignedUrl', {
                playlist: 'the-general-to-soldiers-01',
                filename: file.name
            }, {
                1: (json) => {
                    if (json && json.data) {
                        console.log(json.data)
                        fetch(json.data, { method: 'PUT', body: file }).then(() => {
                            console.log()
                            console.log('Upload Success')
                        }).catch((e) => {
                            console.error(e);
                        })
                    }
                }
            })
        }
    }

    onBlurData(json) {
        this.state.callback(this.state)
    }

    // Array
    onClickDataUploadArr(e, index) {
        this.refs['data_image_' + index].click()
    }

    // onChangeDataImageFileArr(e, index) {
    //     e.stopPropagation()
    //     e.preventDefault()

    //     if (typeof (e.target.files[0]) !== 'undefined') {
    //         const file = e.target.files[0]


    //         const data = new FormData()
    //         data.append('uploadFile', file, file.name)
    //         fetch(global.rxu.config.base_api + '/upload', { method: 'POST', body: data }).then(res => res.json()).then((json) => {
    //             if (json.success === 1) {
    //                 this.onBlurDataArr(json, index)
    //             }
    //         })
    //     }
    // }

    onBlurDataArr(json, index) {
        const tempImages = this.state.images
        tempImages[index] = json.data
        this.setState({ images: tempImages }, () => {
            if (typeof this.state.callback === 'function') {
                this.state.callback(this.state)
            }
        })
    }

    onClickImgRemove(e, index) {
        const tempImages = this.state.images
        tempImages.splice(index, 1)
        this.setState({ images: tempImages }, () => {
            if (typeof this.state.callback === 'function') {
                this.state.callback(this.state)
            }
        })
    }

    capture(file, callback) {
        try {
            var fileReader = new FileReader()
            fileReader.onload = function () {
                var blob = new Blob([fileReader.result], { type: file.type })
                var url = URL.createObjectURL(blob)
                var video = document.createElement('video')
                var timeupdate = function () {
                    if (snapImage()) {
                        video.removeEventListener('timeupdate', timeupdate)
                        video.pause()
                    }
                }
                video.addEventListener('loadeddata', function () {
                    if (snapImage()) {
                        video.removeEventListener('timeupdate', timeupdate)
                    }
                })
                var dataURLtoFile = function (dataurl, filename) {
                    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                    }
                    return new File([u8arr], filename, { type: mime });
                }
                var snapImage = function () {
                    var canvas = document.createElement('canvas')
                    canvas.width = video.videoWidth * 0.25
                    canvas.height = video.videoHeight * 0.5
                    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
                    var image = canvas.toDataURL('image/jpeg')
                    var success = image.length > 5000
                    if (success) {
                        let filename = 'thumbnail_' + Math.floor(Date.now()) + '.jpg'
                        let arrimg = image.split(',')[1]

                        let objImg = {
                            base64: image,
                            width: canvas.width,
                            height: canvas.height,
                            videoWidth: video.videoWidth,
                            videoHeight: video.videoHeight,
                            duration: Math.ceil(video.duration),
                            type: 'image/png',
                            size: Math.floor((new Buffer(arrimg, 'base64').length)),
                            filename: filename,
                            file: dataURLtoFile(image, `${filename}.jpg`),
                        }
                        callback(objImg)
                        URL.revokeObjectURL(url)
                    }
                    return success
                }
                video.addEventListener('timeupdate', timeupdate)
                video.preload = 'metadata'
                video.src = url
                video.muted = true
                video.playsInline = true
                video.play()
            }

            fileReader.readAsArrayBuffer(file)
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        let tmpImgStr
        let tmpImgStrAdd
        // const tempImages = (typeof (this.state.images) === 'string') ? this.state.images : 'ico_app_default.jpg'
        tmpImgStr =
            <div>
                <input type='file' id='file' ref='data_image' style={{ display: 'none' }} onChange={(e) => this.onChangeDataImageFile(e)} />

                <div className='betable__uploads clearfix' onClick={(e) => this.onClickDataUpload(e)}>
                    <span className='icon-cloud-upload betable__uploads-icon' />
                    <div className='betable__uploads-text'><span>Click to Upload</span> or, drag and drop a file here</div>

                </div>

            </div>


        return (
            <div className='clearfix'>{tmpImgStr}{tmpImgStrAdd}</div>
        )
    }
}

export default RxUpload
