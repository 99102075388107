/* global rxu */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { apiget, apipost } from '../../classes/request' // eslint-disable-line no-unused-vars

const { MainTable } = global.rootRequire('components/shares/rxTemplates')

const FolderComponent = () => {
  const [, setEditingData] = useState()
  const [, setCreateItemPopupOpen] = useState(false)
  const [items, setItems] = useState([])
  const [, setIsEditData] = useState(false)
  const [extra, setExtra] = useState(10)
  const [isView, setIsView] = useState('table')

  const [paging, setPaging] = useState({
    st_col: 'created_at',
    st_type: -1,
    pg_page: 1,
    pg_size: 10,
  });

  const fetchData = useCallback(() => {
    apiget(global.rxu.config.api_folder, paging, {
      1: (json) => {
        setItems(json.data);
        setExtra(json.extra)
      },
    });
  }, [paging]);

  // const handleCreateItem = (newItem) => {
  //   setLoading(true)
  //   apipost(rxu.get(rxu.config, 'api_items'), newItem, {
  //     1: (json) => {
  //       setLoading(false)
  //       fetchData()
  //       setCreateItemPopupOpen(false)
  //       toast.success('Form submitted successfully! Video processing, Please wait...');
  //     },
  //     '-2': (json) => {
  //       setLoading(false)
  //       setMsgError(json.msg)
  //       setCreateItemPopupOpen(true)
  //     }
  //   })
  // }

  // const handleEditItem = (newData) => {
  //   setLoading(true)
  //   apipost(rxu.get(rxu.config, 'api_items_edit'), newData, {
  //     1: (json) => {
  //       setLoading(false)
  //       fetchData()
  //       toast.success('Edit data success!');
  //     },
  //     '-2': (json) => {
  //       setLoading(false)
  //       setMsgError(json.msg)
  //     }
  //   })
  // }

  // const handleEdit = (item) => {
  //   setEditingData()
  //   setIsEditData(true)
  //   setEditingData(item)
  //   setCreateItemPopupOpen(true)
  // }

  // const handleDelete = (item) => {
  //   setLoading(true)
  //   const r = window.confirm('Bạn muốn xoá dữ liệu này !')
  //   if (r === true) {
  //     apiget(rxu.get(rxu.config, 'api_items_delete'), item, {
  //       1: (json) => {
  //         setLoading(false)
  //         fetchData()
  //         toast.success('Delete data success!');
  //       },
  //       '-2': (json) => {
  //         setLoading(false)
  //         setMsgError(json.msg)
  //       }
  //     })
  //   }

  // }

  const helpSortClass = (stcol, extraclass) => {
    extraclass = extraclass || []
    let result = ''
    if (paging.st_col === stcol) {
      result = paging.st_type === 1 ? 'rx-sort-asc' : 'rx-sort-desc'
    }
    for (let i = 0; i < extraclass.length; i++) { result += ' ' + extraclass[i] }
    return result
  }

  const onClickSort = (e, stcol) => {
    setPaging((prevState) => ({
      ...prevState,
      st_type: (prevState.st_col !== stcol) ? -1 : (-1 * (prevState.st_type)),
      st_col: stcol

    }));
    fetchData()
  }
  const disPlayView = (view) => {
    setIsView(view)
  }
  const handleAddItem = () => {
    setEditingData()
    setCreateItemPopupOpen(true)
    setIsEditData(false)
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  // P A G I N
  const renderPagin = () => {
    const count = global.rxu.get(extra, 'count', paging.pg_size)
    const maxpage = Math.ceil(count / paging.pg_size)
    const pages = global.rxu.arrayFromTo(1, maxpage)
    return (
      <div className='clearfix rxpagingwrap'>
        <div className='rxpagingcount'>Total Rows <b>{global.rxu.get(extra, 'count', 0)}</b></div>
        <div className='rxpaging'>
          <div className='rxpagesize'>
            <span>Pagesize</span>
            <select value={paging.pg_size} onChange={e => setPaging((prevState) => ({ ...prevState, pg_size: e }))}>
              {[1, 2, 5, 10, 20].map(ele => (<option key={ele} value={ele * 10}>{ele * 10}</option>))}
            </select>
          </div>
          <div className='rxpage' onClick={(evt) => { setPaging((prevState) => ({ ...prevState, pg_page: paging.pg_page - 1 })) }}><i className='icon-arrow-left' /></div>
          {pages.map((ele) => ([1, paging.pg_page, maxpage].indexOf(ele) !== -1 || ele % Math.floor((maxpage / 10)) === 0) &&
            (<div key={ele} className={ele === paging.pg_page ? 'rxpage rxactive' : 'rxpage'} onClick={(evt) => { setPaging(ele) }}>{ele}</div>))}
          <div className='rxpage' onClick={(evt) => { setPaging((prevState) => ({ ...prevState, pg_page: paging.pg_page + 1 })) }}><i className='icon-arrow-right' /></div>
        </div>
      </div>
    )
  }

  // F I L T E R
  const renderFilter = () =>
    <div>
      <div className='betable__filter'>
        <div className='betable__btns clearfix'>
          <span className='betable__btnadd btn' onClick={() => handleAddItem()}><i className='icon-plus betable__addbtn' />Add</span>
        </div>
        <div className='betable__findwrap'>
          <input className='betable__findinput' type='text' placeholder='Find' onKeyUp={(e) => fetchData()} />

          <div className='betable__display_view btn--default'>
            <div className="css-1mzgw0n">
              <div className={isView === 'grid' ? 'css-1b87zq7 active' : 'css-1b87zq7'} onClick={(e) => disPlayView('grid')}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M216,56v60a4,4,0,0,1-4,4H136V44a4,4,0,0,1,4-4h60A16,16,0,0,1,216,56ZM116,40H56A16,16,0,0,0,40,56v60a4,4,0,0,0,4,4h76V44A4,4,0,0,0,116,40Zm96,96H136v76a4,4,0,0,0,4,4h60a16,16,0,0,0,16-16V140A4,4,0,0,0,212,136ZM40,140v60a16,16,0,0,0,16,16h60a4,4,0,0,0,4-4V136H44A4,4,0,0,0,40,140Z"></path></svg>
              </div>
              <div className={isView === 'table' ? 'css-1b87zq7 active' : 'css-1b87zq7'} onClick={(e) => disPlayView('table')}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M208,136H48a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A16,16,0,0,0,208,136Zm0,56H48V152H208v40Zm0-144H48A16,16,0,0,0,32,64v40a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V64A16,16,0,0,0,208,48Zm0,56H48V64H208v40Z"></path></svg>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  const ListTableView = ({ items }) => (
    <MainTable>
      <thead>
        <tr>
          <th className='rxwidth--100 t-al'>No</th>
          <th className={helpSortClass('name', ['rx-th-width-220 t-al'])}>Name</th>
          <th className={helpSortClass('created_at', ['rxwidth--120 t-al'])} onClick={(e) => onClickSort(e, 'created_at')}>Created at</th>
          <th className={helpSortClass('updated_at', ['rxwidth--120 t-al'])} onClick={(e) => onClickSort(e, 'updated_at')}>Updated at</th>
          <th className={helpSortClass('', ['rx-th-width-50'])}>Action</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={item._id}>
            <td className='t-al'>{index + 1}</td>
            <td className='t-al'><Link rel="noopener noreferrer" to={{ pathname: `/admin/dashboard?slug=${item._id}` }}>{global.rxu.shorten(rxu.get(item, 'name'), 15, 5)}</Link></td>
            <td className='t-al'><small>{global.rxu.date(rxu.get(item, 'created_at'))}</small></td>
            <td className='t-al'><small>{global.rxu.date(rxu.get(item, 'updated_at'))}</small></td>
          </tr>
        ))}
      </tbody>
    </MainTable>

  );

  return (
    <div className='adblock'>
      <div className='adblock__body'>
        <div className='row adblock__inner'>
          <div className='rxcol-100'>
            <div>
              <div className='betable'>
                {renderFilter()}
                {renderPagin()}
                {isView === 'table' && <ListTableView items={items} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FolderComponent;
