/* global rxu */
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ReactPlayer from "react-player";

import CreateItemPopup from '../shares/rxCreateItemPopup'
import { apiget, apipost } from './../../classes/request' // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify';
import EditSrt from '../shares/rxSubeditorSrt';
import axios from 'axios';

const { MainTable } = global.rootRequire('components/shares/rxTemplates')
const StatusColorMap = ['black', 'orange', 'green', 'red', 'yellow', 'lightgreen', 'lightblue']

const createItem = {
  folder: '',
  folderObj: {},
  name: '',
  hasHardsub: false,
  hasSrt: false,
  hasSoftsub: true,
  hasLink: false,
  srtInfo: { name: '', path: '' },
  linkYt: ''
};

const DashboardComponent = () => {
  const { slug } = useParams();
  const [editingData, setEditingData] = useState(createItem)
  // const [flagUpdateCat, setFlagUpdateCat] = useState(false)
  const [isCreateItemPopupOpen, setCreateItemPopupOpen] = useState(false)
  const [items, setItems] = useState([])
  const [folders, setFolders] = useState([])
  const [loading, setLoading] = useState(false)
  const [msgErr, setMsgError] = useState(null)
  const [isEditSrt, setIsEditSrt] = useState(false)
  const [isEditData, setIsEditData] = useState(false)
  const [extra, setExtra] = useState(10)
  const [isView, setIsView] = useState('grid')
  const [videoReloadState, setVideoReloadState] = useState({});
  const [dropdownStates, setDropdownStates] = useState({});

  const [paging, setPaging] = useState({
    st_col: 'created_at',
    st_type: -1,
    pg_page: 1,
    pg_size: 10,
  });

  const fetchData = useCallback(() => {
    apiget(global.rxu.config.api_items, paging, {
      1: (json) => {
        setItems(json.data);
        setExtra(json.extra)
      },
    });
  }, [paging]);

  useEffect(() => {
    if (slug) {
      const searchId = slug.split('?slug=')[1];
      console.log('Slug from URL:', searchId);
      setPaging((prevState) => ({
        ...prevState,
        searchid_folder: searchId,
      }));
    }
  }, [slug]);

  // useEffect(() => {
  //   fetchData(); // Fetch data when paging changes
  // }, [fetchData]);

  const fetchDataFolder = useCallback(() => {
    apiget(global.rxu.config.api_folder, {}, {
      1: (json) => {
        setFolders(json.data)
      },
    })
  }, [])

  useEffect(() => {
    fetchData()
    fetchDataFolder()
  }, [fetchData, fetchDataFolder])

  const handleCreateItem = (newItem) => {
    setLoading(true)
    apipost(rxu.get(rxu.config, 'api_items'), newItem, {
      1: (json) => {
        setLoading(false)
        fetchData()
        setCreateItemPopupOpen(false)
        toast.success('Form submitted successfully! Video processing, Please wait...');
      },
      '-2': (json) => {
        setLoading(false)
        setMsgError(json.msg)
        setCreateItemPopupOpen(true)
      }
    })
  }

  const handleEditItem = (newData) => {
    setLoading(true)
    console.log(newData)
    apipost(rxu.get(rxu.config, 'api_items_edit'), newData, {
      1: (json) => {
        setLoading(false)
        fetchData()
        toast.success('Edit data success!');
      },
      '-2': (json) => {
        setLoading(false)
        setMsgError(json.msg)
      }
    })
  }

  const handleEdit = (item) => {
    setEditingData(createItem)
    setIsEditData(true)
    setEditingData(item)
    setCreateItemPopupOpen(true)
  }

  const handleDelete = (item) => {
    setLoading(true)
    const r = window.confirm('Bạn muốn xoá dữ liệu này !')
    if (r === true) {
      apiget(rxu.get(rxu.config, 'api_items_delete'), item, {
        1: (json) => {
          setLoading(false)
          fetchData()
          toast.success('Delete data success!');
        },
        '-2': (json) => {
          setLoading(false)
          setMsgError(json.msg)
        }
      })
    }

  }

  const handleDownload = (item) => {
    const link = <>{renderHelpLink(item.linkDownload, item)}</>
    return link
  }

  const handleDownloadVideo = () => {

  }

  const toggleDropdown = (itemId) => {
    setDropdownStates((prevStates) => ({
      [itemId]: !prevStates[itemId]
    }));
  };

  const run = (name, params) => {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }

  const helpStatus = (status, type) => {
    if (status === 10) { return global[type][6] }
    return global[type][status]
  }

  const renderHelpLink = (perdata, item) => {
    let result = ''
    let jsonData = perdata && JSON.parse(perdata)

    const { hasHardsub, hasSoftsub } = item
    const isActive = (linkType) => {
      if (linkType === 'subHard') { return hasHardsub }
      if (linkType === 'sub') { return hasSoftsub }
    }
    result = Object.entries(jsonData).map(([linkType, link]) => {
      return <div key={linkType} style={{ color: '#1890ff' }}><i className='icon-cloud-download nav-icon' />
        <a style={{ color: '#1890ff' }} href={`${rxu.config.PathDownload}${link}`} target="_blank" rel="noopener noreferrer"> {linkType}</a>
      </div>
    }
    )

    return result
  }

  const helpSortClass = (stcol, extraclass) => {
    extraclass = extraclass || []
    let result = ''
    if (paging.st_col === stcol) {
      result = paging.st_type === 1 ? 'rx-sort-asc' : 'rx-sort-desc'
    }
    for (let i = 0; i < extraclass.length; i++) { result += ' ' + extraclass[i] }
    return result
  }

  const onClickSort = (e, stcol) => {
    setPaging((prevState) => ({
      ...prevState,
      st_type: (prevState.st_col !== stcol) ? -1 : (-1 * (prevState.st_type)),
      st_col: stcol

    }));
    fetchData()
  }
  const disPlayView = (view) => {
    setIsView(view)
  }
  const handleAddItem = () => {
    setEditingData(createItem)
    setCreateItemPopupOpen(true)
    setIsEditData(false)

  }

  const editSrt = (perdata) => {
    setIsEditSrt(true)
    setEditingData(perdata)
  }

  const handlePopupSubEditor = () => {
    setIsEditSrt(false)
  }

  const handleSaveSubEdit = async (data) => {
    const srtData = global.rxu.jsonToSrt(data)
    await helperHandleUpload(srtData, data, (newUpdate) => {
      newUpdate.srtEdited = true
      handleEditItem(newUpdate)
    })

  }

  const helperHandleUpload = async (editedContent, data, onHandleSave) => {
    try {
      const pathEdited = 'pathEdited.srt';
      apiget(global.rxu.config.base_api + '/upload/presignedUrl', {
        playlist: global.rxu.get(editingData, ['folderObj', 'slug'], 'images'),
        filename: `${global.rxu.get(editingData, ['folderObj', 'slug'], 'default')}/input/${pathEdited}`,
      }, {
        1: async (json) => {
          if (json && json.data) {
            try {
              const blob = new Blob([editedContent], { type: 'text/plain;charset=utf-8' });
              const formData = new FormData();
              formData.append('file', blob, `${pathEdited}`);
              let arrKeys = ['Content-Type', 'key', 'policy', 'x-amz-algorithm', 'x-amz-credential', 'x-amz-date', 'x-amz-meta-netachat', 'x-amz-signature'];
              for (let key of arrKeys) {
                if (json.data.formData[key]) {
                  formData.append(key, json.data.formData[key]);
                }
              }
              console.log(22222233333);
              await axios.post(json.data.postURL, formData, {
                onUploadProgress: (progressEvent) => {
                  const progress = (progressEvent.loaded / progressEvent.total) * 100;
                  if (progress && progress === 100) {
                    // setEditingData((prevItem) => {
                    //   const updatedItem = { ...prevItem, srtIsCheck: 1, status: 0 };
                    //   updatedItem['srtPath'] = `${json.data.postURL}/${json.data.formData.key}`;
                    //   // updatedItem['srtInfo']['pathEdited'] = `${json.data.postURL}/${json.data.formData.key}`
                    //   updatedItem['srtInfo']['edited'] = data;
                    //   return updatedItem;
                    // });
                    const newUpdate = { ...editingData, srtIsCheck: 1, status: 0, srtEdited: true, srtPath: `${json.data.postURL}/${json.data.formData.key}` }
                    onHandleSave(newUpdate)
                    return true;
                  } else {
                    return false;
                  }
                },
              });

            } catch (error) {
              console.log(error);
              return false;
            }
          }
        },
        'default': (err) => {
          console.log(err);
          return false;
        }
      })
      console.log(222)
      return true
    } catch (error) {
      console.error('Error uploading the video:', error);
      return false
    }
  }

  // P A G I N
  const renderPagin = () => {
    const count = global.rxu.get(extra, 'count', paging.pg_size)
    const maxpage = Math.ceil(count / paging.pg_size)
    const pages = global.rxu.arrayFromTo(1, maxpage)
    return (
      <div className='clearfix rxpagingwrap'>
        <div className='rxpagingcount'>Total Rows <b>{global.rxu.get(extra, 'count', 0)}</b></div>
        <div className='rxpaging'>
          <div className='rxpagesize'>
            <span>Pagesize</span>
            <select value={paging.pg_size} onChange={e => setPaging((prevState) => ({ ...prevState, pg_size: e }))}>
              {[1, 2, 5, 10, 20].map(ele => (<option key={ele} value={ele * 10}>{ele * 10}</option>))}
            </select>
          </div>
          <div className='rxpage' onClick={(evt) => { setPaging((prevState) => ({ ...prevState, pg_page: paging.pg_page - 1 })) }}><i className='icon-arrow-left' /></div>
          {pages.map((ele) => ([1, paging.pg_page, maxpage].indexOf(ele) !== -1 || ele % Math.floor((maxpage / 10)) === 0) &&
            (<div key={ele} className={ele === paging.pg_page ? 'rxpage rxactive' : 'rxpage'} onClick={(evt) => { setPaging(ele) }}>{ele}</div>))}
          <div className='rxpage' onClick={(evt) => { setPaging((prevState) => ({ ...prevState, pg_page: paging.pg_page + 1 })) }}><i className='icon-arrow-right' /></div>
        </div>
      </div>
    )
  }

  // F I L T E R
  const renderFilter = () =>
    <div>
      <div className='betable__filter'>
        <div className='betable__btns clearfix'>
          <span className='betable__btnadd btn' onClick={() => handleAddItem()}><i className='icon-plus betable__addbtn' />Add</span>
        </div>
        <div className='betable__findwrap'>
          <input className='betable__findinput' type='text' placeholder='Find' onKeyUp={(e) => fetchData()} />

          <div className='betable__display_view btn--default'>
            <div className="css-1mzgw0n">
              <div className={isView === 'grid' ? 'css-1b87zq7 active' : 'css-1b87zq7'} onClick={(e) => disPlayView('grid')}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M216,56v60a4,4,0,0,1-4,4H136V44a4,4,0,0,1,4-4h60A16,16,0,0,1,216,56ZM116,40H56A16,16,0,0,0,40,56v60a4,4,0,0,0,4,4h76V44A4,4,0,0,0,116,40Zm96,96H136v76a4,4,0,0,0,4,4h60a16,16,0,0,0,16-16V140A4,4,0,0,0,212,136ZM40,140v60a16,16,0,0,0,16,16h60a4,4,0,0,0,4-4V136H44A4,4,0,0,0,40,140Z"></path></svg>
              </div>
              <div className={isView === 'table' ? 'css-1b87zq7 active' : 'css-1b87zq7'} onClick={(e) => disPlayView('table')}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M208,136H48a16,16,0,0,0-16,16v40a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V152A16,16,0,0,0,208,136Zm0,56H48V152H208v40Zm0-144H48A16,16,0,0,0,32,64v40a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V64A16,16,0,0,0,208,48Zm0,56H48V64H208v40Z"></path></svg>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  const ListTableView = ({ items }) => (
    <MainTable>
      <thead>
        <tr>
          <th className='rxwidth--100 t-al'>No</th>
          <th className={helpSortClass('name', ['rx-th-width-220 t-al'])}>Name</th>
          <th className={helpSortClass('orderID', ['rx-th-width-220 t-al'])} onClick={(e) => onClickSort(e, 'orderID')}>Order ID</th>
          <th className={helpSortClass('fileSize', ['rx-th-width-220 t-al'])} onClick={(e) => onClickSort(e, 'fileSize')}>File size</th>
          <th className={helpSortClass('status', ['rx-th-width-220 t-al'])}>Status</th>
          <th className={helpSortClass('created_at', ['rxwidth--120 t-al'])} onClick={(e) => onClickSort(e, 'created_at')}>Created at</th>
          <th className={helpSortClass('updated_at', ['rxwidth--120 t-al'])} onClick={(e) => onClickSort(e, 'updated_at')}>Updated at</th>
          <th className={helpSortClass('linkDownload', ['rx-th-width-220 t-al'])}>Download</th>
          <th className={helpSortClass('', ['rx-th-width-50'])}>Action</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={item._id}>
            <td className='t-al'>{index + 1}</td>
            <td className='t-al'>{global.rxu.shorten(rxu.get(item, 'name'), 15, 5)}</td>
            <td className='t-al'>{rxu.get(item, 'orderID')}</td>
            <td className='t-al'>{global.rxu.formatBytes(rxu.get(item, ['videoInfo', 'size'], 0))}</td>
            <td className='t-al' style={{ fontWeight: 'bold', color: `${StatusColorMap[rxu.get(item, 'status', 'red')]}` }}>{helpStatus(rxu.get(item, 'status'), 'ITEMSTATUS')}</td>
            <td className='t-al'><small>{global.rxu.date(rxu.get(item, 'created_at'))}</small></td>
            <td className='t-al'><small>{global.rxu.date(rxu.get(item, 'updated_at'))}</small></td>
            <td className='t-al'>{[2, 3, 4, 10].indexOf(item?.status) !== -1 ? renderHelpLink(item.linkDownload, item) : <i className='icon-cloud-download nav-icon unactive' />}</td>
            <td className='t-al' onClick={(e) => editSrt(item)}><div><span className='voice-btn betable__btnedit'>Edit with AI</span></div></td>
          </tr>
        ))}
      </tbody>
    </MainTable>

  );

  const GridView = ({ items }) => (
    <div className="grid-container">
      {items.map((item, index) => (
        <React.Fragment key={item._id}>
          <div className='grid-item-border'>
            <div className='grid-item grid-item-banner'>
              <ReactPlayer url={item.videoInfo.path} width="100%" height="100%" controls={false} loop config={{
                file: {
                  attributes: {
                    poster: 'images/aicola/Rectangle6.png'
                  }
                }
              }} />
            </div>
            <div className="grid-item grid-item-header">
              <div className='grid-header header-title'>{global.rxu.shorten(rxu.get(item, 'name'), 15, 5)}</div>
              <div className='grid-header header-orderId'>{item.orderID} - {global.rxu.formatBytes(rxu.get(item, ['videoInfo', 'size'], 0))} - {global.rxu.date(rxu.get(item, 'created_at'))}</div>
              <div className='grid-header header-info'>
                <div className="grid-header header-process">
                  <span className="badge badge-success badge-pill hidden-status-check-id disp-status-648485d3-0f7f-4635-b2e8-9b3f72991acb" data-value="648485d3-0f7f-4635-b2e8-9b3f72991acb" data-status="1">
                    <i className="fas fa-check-circle" aria-hidden="true"></i>
                    {helpStatus(rxu.get(item, 'status'), 'ITEMSTATUS')}

                  </span>
                  <span className="badge badge-pill badge-primary" title="Voice change"> <i className="fas fa-microphone" aria-hidden="true"></i> Dubbing</span>
                </div>
              </div>
              <div className='grdb-item grid-item-actions' onClick={() => toggleDropdown(item._id)}>
                <div className="css-79elbk">
                  <div className="css-1lby940">
                    <div className="css-15ojs4x">
                      <svg width="17.2" height="5" fill="#bcbcbc" viewBox="0 0 17.222 5" className="css-c40ex6"><path d="M16.611,186.014a.486.486,0,1,1-.486.486.488.488,0,0,1,.486-.486m0-2.014a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,16.611,184Zm6.111,2.014a.486.486,0,1,1-.486.486.488.488,0,0,1,.486-.486m0-2.014a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,22.722,184ZM10.5,186.014a.486.486,0,1,1-.486.486.488.488,0,0,1,.486-.486m0-2.014a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,10.5,184Z" transform="translate(-8 -184)"></path></svg>
                    </div>
                  </div>
                  {dropdownStates[item._id] && (
                    <div className={`rx-edit-folder_content active`}>
                      <span className='rx-edit-folder-label' onClick={() => editSrt(item)}>
                        Edit sub with AI
                      </span>
                      {[2, 3, 4, 10].indexOf(item?.status) !== -1 && <span className='rx-edit-folder-label' onClick={() => handleDownload(item)}>
                        <div> Download {renderHelpLink(item.linkDownload, item)}</div>
                      </span>}
                      <span className='rx-edit-folder-label' onClick={() => handleEdit(item)}>
                        Edit info
                      </span>
                      <span className='rx-edit-folder-label' onClick={() => handleDelete(item)}>
                        Delete
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

          </div>
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <div className='adblock'>
      <div className='adblock__head'>
        <div className='card-body dashboard-cta-type-2'>
          <div className='d-flex flex-wrap'>
            <div className='card d-card-1 px-3' onClick={() => setCreateItemPopupOpen(true)}>
              <div className='w-100'><img className='p-icon' alt='aicola' src='/images/static/icon2.svg' /></div>
              <div className='w-100'>
                <label className='text-bold title'>Get Subtitle</label>
              </div>
              <div className='w-100'>
                <div className='desc'><p>Generate Subtitles in any language.</p></div>
              </div>
              <div className="css-1tezknb">
                <div className="css-1fsikiu">TRY IT OUT NOW</div>
                <div className="css-0"><svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 15 15" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z" fill="currentColor"></path></svg></div>
              </div>
            </div>
            <div className='card d-card-1' onClick={() => setCreateItemPopupOpen(true)}>
              {/* <Link className='d-card-1-link' to='/admin/items'> */}
              <div className='w-100'><img className='p-icon' alt='aicola' src='/images/static/icon1.svg' /></div>
              <div className='w-100'>
                <label className='text-bold title'>Get Dubbing</label>
              </div>
              <div className='w-100'>
                <div className='desc'><p>Dubbing Powered By AI.</p></div>
              </div>
              <div className="css-1tezknb">
                <div className="css-1fsikiu">TRY IT OUT NOW</div>
                <div className="css-0"><svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 15 15" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z" fill="currentColor"></path></svg></div>
              </div>
              {/* </Link> */}
            </div>
          </div>
        </div>

        {isCreateItemPopupOpen && (
          <CreateItemPopup
            folders={folders}
            onClose={() => setCreateItemPopupOpen(false)}
            onCreate={handleCreateItem}
            onUpdate={handleEditItem}
            msgError={msgErr}
            loading={loading}
            isEditData={isEditData}
            editingData={editingData}
          />
        )}

        <div className='adblock__title'>My Videos</div>
      </div>

      <div className='adblock__body'>
        <div className='row adblock__inner'>
          <div className='rxcol-100'>
            <div>
              {/* {vm.state.loading && <div className='bg_loading'><div className='rxloading'></div></div>} */}
              <div className='betable'>
                {renderFilter()}
                {renderPagin()}
                {isView === 'table' && <ListTableView items={items} />}
                {isView === 'grid' && <GridView items={items} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isEditSrt && <EditSrt editingData={editingData} onSave={handleSaveSubEdit} onClose={handlePopupSubEditor} onDownload={handleDownloadVideo} />}
    </div>
  );
};

export default DashboardComponent;
