import React, { Component } from 'react'
import CKEditor from 'react-ckeditor-component'
import { Link } from 'react-router-dom'
import * as Jsx from './items.jsx'

const { apiget } = global.rootRequire('classes/request')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default
const rxu = global.rxu

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')

class overviewComponent extends Component {
  constructor(props, context) {
    super(props, context, Jsx)
    this.onClickCatItem = this.onClickCatItem.bind(this)
    this.runUpdateCat = this.runUpdateCat.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.renderCatBody = this.renderCatBody.bind(this)
    this.state = {
      flagUpdateCat: false,
      curCat: 0,
      cate: [{ "name": "Default", "originLanguage": "zh", "targetLanguge": "vi", "type": "dub", "is_deleted": 0, "is_active": 1, "created_at": 1698920898, "updated_at": 1698920898, "_id": "6544528d152f6e22c4093709", "__v": 0 }]
    }

    this.fetchData()
  }

  componentDidMount() {
  }

  fetchData() {
    apiget(global.rxu.config.api_playlists, {}, { 1: (json) => { this.setState({ cate: json.data }, () => { }) } })
  }

  onClickDataNew(e) {
    global.scrolltop()
  }

  onClickCatItem(e) {
    this.setState({ flagUpdateCat: !this.state.flagUpdateCat, curCat: e._id })
  }

  runUpdateCat(e) {
    const paging = (this.state.curCat) ? { searchid_playlist: this.state.curCat, st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 } : { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 }
    e.inthis.setState({ paging: paging }, () => { e.inthis.fetchData() })
  }

  // R E N D E R S
  renderCatHead(inthis) {
    return (<tr>
      <th className='rxwidth--100'>No</th>
      <th className={inthis.helpSortClass('name')} onClick={(e) => inthis.onClickSort(e, 'name')}>Playlist</th>
    </tr>)
  }

  renderCatBody(inthis) {
    const tempData = global.rxu.array(inthis.state.data).slice(0)
    tempData.unshift({ _id: 0, name: 'All cate' })
    return tempData.map((perdata, index) => (
      <tr key={perdata._id} onClick={(e) => inthis.run('onClickCatItem', perdata)} className={(this.state.curCat === perdata._id) ? 'betable__row--active' : ''}>
        <td className='t-al'>{index}</td>
        <td>{perdata.name}</td>
      </tr>))
  }

  renderTableHead(inthis) {
    return (<tr>
      <th className='rxwidth--100'>No</th>
      <th className='rxwidth--100'>Playlist</th>
      <th className='rxwidth--100'>Name</th>
      <th className={inthis.helpSortClass('orderID', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'orderID')}>Order ID</th>
      <th className={inthis.helpSortClass('fileSize', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'fileSize')}>File size</th>
      <th className={inthis.helpSortClass('epi', ['rx-th-width-220 t-al'])} onClick={(e) => inthis.onClickSort(e, 'epi')}>Episodes</th>
      <th className='rxwidth--100'>Status</th>
      {/* <th className={inthis.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Created at</th>
      <th className={inthis.helpSortClass('updated_at', ['rxwidth--120'])} onClick={(e) => inthis.onClickSort(e, 'updated_at')}>Updated at</th> */}
      <th className='rxwidth--100'>Download</th>
    </tr>)
  }

  renderTableBody(inthis) {
    return global.rxu.array(inthis.state.data).map((perdata, index) => (<tr key={perdata._id}>
      <td className='t-al'>{index + 1}</td>
      <td>{inthis.helpProductcat(rxu.get(perdata, 'playlistObj'))}</td>
      <td>{rxu.get(perdata, 'name')}</td>
      <td>{rxu.get(perdata, 'orderID')}</td>
      <td>{rxu.get(perdata, 'fileSize')}</td>
      <td className='t-al'>{rxu.get(perdata, 'epi')}</td>
      <td>{inthis.helpStatus(rxu.get(perdata, 'status'), 'ITEMSTATUS')}</td>
      {/* <td><small>{global.rxu.date(rxu.get(perdata, 'created_at'))}</small></td>
      <td><small>{global.rxu.date(rxu.get(perdata, 'updated_at'))}</small></td> */}
      <td><Link target="_blank" rel="noopener noreferrer" to={{ pathname: rxu.get(perdata, 'linkDownload') }} style={{ color: '#1890ff' }}><i className='icon-cloud-download nav-icon' /> Link</Link></td>
    </tr>))
  }

  render() {
    return (
      <AdminBlock name='Overview' desc='Manage overview playlist'>
        <div className='rxcol-30'>
          <RxCrud
            flagsAction={true} renderTableHead={this.renderCatHead} dataCallback={this.fetchData} renderTableBody={this.renderCatBody} onClickCatItem={this.onClickCatItem} name='Playlist' api='api_playlists' form={[
              { name: 'Name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name || ''} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },
              { type: 'devide' },

              { name: 'Origin Language', func: (inthis) => (<RxSelectbox options={global.LANGUAGES} results={inthis.state.editingData.originLang || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'originLang') }} />) },
              { name: 'Targe Language', func: (inthis) => (<RxSelectbox options={global.LANGUAGES} results={inthis.state.editingData.targetLang || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'targetLang') }} />) },
              { type: 'devide' },
              { name: 'Type (sub or dub )', func: (inthis) => (<RxSelectbox options={global.TYPES} results={inthis.state.editingData.type || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'type') }} />) },
              // { name: 'SoftSub or HardSub', func: (inthis) => (<RxSelectbox options={global.SUBTYPES} results={inthis.state.editingData.subtype || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'subtype') }} />) },
              { name: 'Technical', func: (inthis) => (<CKEditor activeClass='p10' content={inthis.state.editingData.infoExtra} events={{ change: (evt) => { const editingData = inthis.state.editingData; const newContent = evt.editor.getData(); editingData.infoExtra = newContent; inthis.setState({ editingData: editingData }) } }} />) },

            ]}
          />
        </div>

        <div className='rxcol-70'>
          <RxCrud
            flagsAction={true} renderTableHead={this.renderTableHead} flagUpdate={this.state.flagUpdateCat} parentUpdate={this.runUpdateCat} renderTableBody={this.renderTableBody} name='item' api='api_items' form={[
              { type: 'devide' },
              {
                type: 'form', name: 'Basic', func: (inthis) => (<div>
                  <div className='rx-form__item'>
                    <div>
                      <span>Choose Playlist (1): </span>
                      <div style={{ width: '100%' }}>
                        <RxSelectbox options={this.state.cate} results={inthis.state.editingData.playlist || ''} onChange={(result, item) => { inthis.onBlurDataValue(result, 'playlist', item) }} />
                      </div>
                      <div className='info-playlist'>
                        <div><span className='s-l'>Origin Language: </span> <span className='s-r'>{inthis.state.playlistSelected.originLang}</span></div>
                        <div><span className='s-l'>Target Language: </span><span className='s-r'>{inthis.state.playlistSelected.targetLang}</span></div>
                        <div><span className='s-l'>Type Sub/dub: </span><span className='s-r'>{inthis.state.playlistSelected.type}</span></div>
                        {/* <div><span className='s-l'>Hardsub / softsub: </span> <span className='s-r'></span>{inthis.state.playlistSelected.name}</span> </div> */}
                        {/* <div><span className='s-l'>Voice Option: </span> <span className='s-r'> {inthis.state.playlistSelected.name}</span> </div> */}
                      </div>
                    </div>
                  </div>
                </div>)
              },
              { type: 'devide' },
              {
                type: 'form', name: 'Embed ', func: (inthis) => (<div>
                  <div className='rx-form__item'>
                    <div>
                      <span>Upload video (*): </span>
                      <div style={{ width: '100%' }}>
                        <RxUpload playlistSelected={inthis.state.playlistSelected} callback={(e, file) => inthis.callbackUpload(e, 'filePath', file)} images={inthis.state.editingData.filePath} />
                      </div>
                    </div>
                  </div>
                  <div className='rx-form__item'>
                    <div>
                      <span>Upload SRT File (*): </span>
                      <div style={{ width: '100%' }}>
                        <RxUpload playlistSelected={inthis.state.playlistSelected} callback={(e) => inthis.callbackUpload(e, 'srt')} images={inthis.state.editingData.srt} />
                      </div>
                    </div>
                  </div>

                </div>)
              },
              { type: 'devide' },
              {
                type: 'form', name: 'Advance', func: (inthis) => (<div>
                  <div className='rx-form__item'>
                    <div>
                      <span>Name (): </span>
                      <div style={{ width: '100%' }}>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.name || ''} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />
                      </div>
                    </div>
                  </div>

                  <div className='rx-form__item'>
                    <div>
                      <span>Episode (): </span>
                      <div style={{ width: '100%' }}>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.eip || ''} onChange={(e) => inthis.onBlurData(e, 'eip')} className='fullwidth-input' />
                      </div>
                    </div>
                  </div>

                </div>)
              },
              {
                type: 'form', name: 'For Admin', func: (inthis) => (<div>
                  <div className='rx-form__item'>
                    <div>
                      <span>Link Youtube (): </span>
                      <div style={{ width: '100%' }}>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.link || ''} onChange={(e) => inthis.onBlurData(e, 'link')} className='fullwidth-input' />
                      </div>
                    </div>
                  </div>

                  <div className='rx-form__item'>
                    <div>
                      <span>Link Download (): </span>
                      <div style={{ width: '100%' }}>
                        <input tabIndex='1' type='text' value={inthis.state.editingData.linkDownload || ''} onChange={(e) => inthis.onBlurData(e, 'linkDownload')} className='fullwidth-input' />
                      </div>
                    </div>
                  </div>

                  <div className='rx-form__item'>
                    <div>
                      <span>Srt Status (): </span>
                      <div style={{ width: '100%' }}>
                        <RxToggle value={inthis.state.editingData.srtIsCheck} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'srtIsCheck')} />
                      </div>
                    </div>
                  </div>


                </div>)
              },
            ]}
          />
        </div>
      </AdminBlock>
    )
  }
}

export default overviewComponent
