import React, { useEffect } from 'react';

export default function Ruler() {
  useEffect(() => {
    document.subtitleEditorElement = document.getElementById('subtitle-editor').parentNode;
  }, [])

  return (
    <div id='subtitle-editor'></div>
  )
}