// Website - rxshop
// src/classes/ulti.js
import { rxsecret } from './../config/index'
const rxu = require('@rxframework/rxulti')

rxu.navClass = function (route, props, showObj, activeClass) {
  let result = ''
  showObj = showObj || {}
  const showPermission = showObj.showPermission || ''
  const showClass = showObj.showClass || ' rxshow '
  const hideClass = showObj.hideClass || ' rxhide '

  if (props.location) {
    const currentLocation = props.location.pathname || ''
    activeClass = activeClass || ' rxactive '

    if (currentLocation === route) {
      result += ' ' + activeClass
    }

    if (rxu.get(props, 'auth.user.arrper', ['']).concat(['']).indexOf(showPermission) !== -1) {
      result += ' ' + showClass
    } else {
      result += ' ' + hideClass
    }
  }

  return result
}

const secret = rxsecret()
export const rxconfig = (function () {
  const config = []

  config.MinioBaseUrl = secret.MinioBaseUrl
  config.MinioDirectory = secret.MinioDirectory
  config.PathDownload = secret.MinioBaseUrl + secret.PathDownload

  // Web
  config.web_port = secret.web_port

  // Server
  config.base_api = secret.base_api

  // Authorize
  // Homepage
  config.api_home = config.base_api + '/site'
  config.api_site_product = config.base_api + '/site/product'
  config.api_site_productcate = config.base_api + '/site/productcate'
  config.api_site_order_add = config.base_api + '/site/orderadd'

  // Note
  config.api_note = config.base_api + '/note'

  // Note cate
  config.api_cate = config.base_api + '/cate'

  // Note cate

  config.api_items = config.base_api + '/items'
  config.api_items_edit = config.api_items + '/edit'
  config.api_items_delete = config.api_items + '/delete'
  config.api_items_restore = config.api_items + '/restore'
  config.api_items_import = config.api_items + '/importExcel'


  config.api_file = config.base_api + '/file'
  config.api_file_edit = config.api_file + '/edit'
  config.api_file_delete = config.api_file + '/delete'
  config.api_file_restore = config.api_file + '/restore'

  config.api_playlists = config.base_api + '/playlists'
  config.api_playlists_edit = config.api_playlists + '/edit'
  config.api_playlists_delete = config.api_playlists + '/delete'
  config.api_playlists_restore = config.api_playlists + '/restore'

  config.api_folder = config.base_api + '/folders'
  config.api_folder_edit = config.api_folder + '/edit'
  config.api_folder_delete = config.api_folder + '/delete'
  config.api_folder_restore = config.api_folder + '/restore'


  config.api_authorize = config.base_api + '/authorize'
  config.api_user = config.base_api + '/user'
  config.api_user_edit = config.api_user + '/edit'
  config.api_user_delete = config.api_user + '/delete'
  config.api_user_restore = config.api_user + '/restore'
  config.api_role = config.base_api + '/role'
  config.api_role_edit = config.api_role + '/edit'
  config.api_role_delete = config.api_role + '/delete'
  config.api_role_restore = config.api_role + '/restore'
  config.api_role_all = config.api_role + '/all'
  config.api_permission = config.base_api + '/permission'
  config.api_permission_edit = config.api_permission + '/edit'
  config.api_permission_delete = config.api_permission + '/delete'
  config.api_permission_restore = config.api_permission + '/restore'
  config.api_permission_all = config.api_permission + '/all'
  config.api_permission_genpermission = config.api_permission + '/generation'
  // [[RX_APIS]] //

  return config
}())

rxu.secret = secret
rxu.config = rxconfig

rxu.date = function (timestamp) {
  const d = new Date(timestamp * 1000)
  const yyyy = d.getFullYear()
  const mm = ('0' + (d.getMonth() + 1)).slice(-2)
  const dd = ('0' + d.getDate()).slice(-2)
  // const hh = d.getHours()
  // let h = hh

  // let ampm = 'AM'

  let time

  // if (hh > 12) {
  //   h = hh - 12
  //   ampm = 'PM'
  // } else if (hh === 12) {
  //   h = 12
  //   ampm = 'PM'
  // } else if (hh === 0) {
  //   h = 12
  // }
  time = dd + '/' + mm + '/' + yyyy + ' '
  return time
}

rxu.strToSlug = function (str) {
  str = str.replace(/^\s+|\s+$/g, '')
  str = str.toLowerCase()

  let from = 'âấầậẫẩăằắặẵẳàáäâẩạãêềếệễèéëêẽểẻẹìíïîỉịòóöôỏọơờớợỡởôồốộỗổùúüûủụưừứựữửñçđ·/_,:;'
  let to = 'aaaaaaaaaaaaaaaaaaaeeeeeeeeeeeeeiiiiiioooooooooooooooooouuuuuuuuuuuuncd------'
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

rxu.rxslug = function (srt) {
  return srt.split(' ').join('-').toLowerCase()
}
rxu.formatBytes = function (bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}

rxu.shorten = (hash, head = 10, tail = 6) => {
  const n = hash.length;

  return n > 10 ? hash.slice(0, head) + "…" + hash.slice(n - tail) : hash;
};
rxu.jsonToSrt = (jsonData) => {
  let srtContent = ''
  try {
    const arrayTemp = JSON.parse(jsonData)
    if (Array.isArray(arrayTemp)) {

      arrayTemp.map((entry, index) => {
        if (entry) {
          const startTime = entry.start.replace('.', ',');
          const endTime = entry.end.replace('.', ',');

          srtContent += `${index + 1}\n${startTime} --> ${endTime}\n${entry.text.trim()}\n\n`;
        }
      });
    }
  } catch (error) {
    console.log(error)
  }

  return srtContent.trim();
}


export default rxu
