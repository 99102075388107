import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import axios from 'axios';
const { apiget } = global.rootRequire('classes/request')

const EditSrt = (editingData, onSave) => {
    const [srtContent, setSrtContent] = useState('');
    const [editedContent, setEditedContent] = useState('');
    const [isEditing, setIsEditing] = useState(true);
    const [newItem, setNewItem] = useState(editingData.editingData);

    const srtFile = newItem.srtInfo.path || '';
    const srtFileName = newItem.srtInfo.name || '';
    const videoUrl = `${newItem.videoInfo.path}` || '';

    useEffect(() => {
        const fetchSrtFile = async () => {
            try {
                const response = await fetch(srtFile);
                if (!response.ok) {
                    throw new Error('Failed to fetch SRT file');
                }
                const content = await response.text();
                setSrtContent(content);
                setEditedContent(content);
            } catch (error) {
                console.error('Error fetching SRT file:', error.message);
            }
        };
        fetchSrtFile();
    }, [srtFile]);

    const handleSave = () => {
        console.log('Saving SRT content:', editedContent);

        // Save the edited content to a file
        helperHandleUpload()

        // onSave(newItem);
        setIsEditing(false);
    };

    const videoConfig = {
        file: {
            tracks: [
                {
                    kind: "subtitles",
                    src: srtFile,
                    srcLang: "vn",
                    label: "Engligh",
                    default: true,
                },
            ],
        },
    };

    const helperHandleUpload = async (formData) => {
        try {
            await apiget(global.rxu.config.base_api + '/upload/presignedUrl', {
                playlist: global.rxu.get(newItem, ['folderObj', 'slug'], 'images'),
                filename: `${global.rxu.get(newItem, ['folderObj', 'slug'], 'default')}/input/${srtFileName}`,
            }, {
                1: async (json) => {

                    if (json && json.data) {
                        try {
                            const blob = new Blob([editedContent], { type: 'text/plain;charset=utf-8' });
                            // Upload the file to the server
                            const formData = new FormData();
                            formData.append('file', blob, `${srtFileName}_1`);
                            let arrKeys = ['Content-Type', 'key', 'policy', 'x-amz-algorithm', 'x-amz-credential', 'x-amz-date', 'x-amz-meta-netachat', 'x-amz-signature']
                            for (let key of arrKeys) {
                                if (json.data.formData[key]) {
                                    formData.append(key, json.data.formData[key])
                                }
                            }
                            await axios.post(json.data.postURL, formData, {
                                onUploadProgress: (progressEvent) => {
                                    const progress = (progressEvent.loaded / progressEvent.total) * 100;
                                    if (progress && progress === 100) {
                                        setNewItem((prevItem) => {
                                            const updatedItem = { ...prevItem };
                                            updatedItem['srtInfo']['path'] = `${json.data.postURL}/${json.data.formData.key}`
                                            console.log({ updatedItem })
                                            return updatedItem;
                                        });

                                        return true
                                    } else {
                                        return false
                                    }
                                },
                            })

                        } catch (error) {
                            console.log(error)

                            setNewItem((prevItem) => {
                                const updatedItem = { ...prevItem };
                                updatedItem['srtInfo']['isActive'] = false
                                return updatedItem;
                            });

                            console.log(error)
                            return false
                        }
                    }
                },
                'default': (err) => {
                    console.log(err)
                    return false
                }
            })
            return true
        } catch (error) {
            console.error('Error uploading the video:', error);
            return false
        }
    }

    return (
        <div>
            <Modal
                isOpen={isEditing}
                onRequestClose={() => setIsEditing(false)}
                contentLabel="SRT Editor"
            >
                <div className='main-container content-wrapper'>
                    <div className='row d-flex studio-container-one'>
                        <div className='editor-container col-xl-7 col-lg-7 col-md-7 col-sm-12  p-0'>
                            <div>
                                <h3>Edit SRT</h3>
                                <textarea
                                    rows={40}
                                    cols={70}
                                    value={editedContent}
                                    onChange={(e) => setEditedContent(e.target.value)}
                                />
                                <button onClick={handleSave}>Save</button>
                                <button onClick={() => setIsEditing(false)}>Cancel</button>
                            </div>
                        </div>
                        <div className='player-container col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12'>
                            <div>
                                <ReactPlayer
                                    url={videoUrl}
                                    controls={true}
                                    width="100%"
                                    height="50%"
                                    config={videoConfig}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>

        </div>
    );
};

export default EditSrt;
