import React from 'react'
import { Link } from 'react-router-dom'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const rxu = global.rxu

const RxToggle = global.rootRequire('components/shares/rxToggle').default

export const renderTableHead = (inthis, vm) =>
  <tr>
    <th className='rxwidth--100'>No</th>
    <th className={inthis.helpSortClass('playlist', ['rx-th-width-220'])}>Playlist</th>
    <th className={inthis.helpSortClass('name', ['rx-th-width-220'])}>Name</th>
    <th className={inthis.helpSortClass('orderID', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'orderID')}>Order ID</th>
    <th className={inthis.helpSortClass('eip', ['rx-th-width-220 t-al'])}>Episodes</th>
    <th className={inthis.helpSortClass('status', ['rx-th-width-220'])}>Status</th>
    <th className={inthis.helpSortClass('created_at', ['rxwidth--120'])} onClick={(e) => inthis.onClickSort(e, 'date')}>Created at</th>
    <th className={inthis.helpSortClass('updated_at', ['rxwidth--120'])} onClick={(e) => inthis.onClickSort(e, 'date')}>Updated at</th>
    <th className={inthis.helpSortClass('linkDownload', ['rx-th-width-220'])}>Download</th>
    {/* <th className={inthis.helpSortClass('', ['rx-th-width-220'])}>Action</th> */}
  </tr>

export const renderTableBody = (inthis, vm) =>
  global.rxu.array(inthis.state.data).map((perdata, index) => (<tr key={perdata._id}>
    <td className='t-al'>{index + 1}</td>
    <td>{inthis.helpProductcat(rxu.get(perdata, 'playlistObj'))}</td>
    {/* <td>{perdata.name.slice(0, 5) + '...'}</td> */}
    <td>{rxu.get(perdata, 'name')}</td>
    <td>{rxu.get(perdata, 'orderID')}</td>
    <td className='t-al'>{rxu.get(perdata, 'epi')}</td>
    <td>{inthis.helpStatus(rxu.get(perdata, 'status'), 'ITEMSTATUS')}</td>
    <td><small>{global.rxu.date(rxu.get(perdata, 'created_at'))}</small></td>
    <td><small>{global.rxu.date(rxu.get(perdata, 'updated_at'))}</small></td>
    <td className='t-al'><Link target="_blank" rel="noopener noreferrer" to={{ pathname: rxu.get(perdata, 'linkDownload') }} style={{ color: '#1890ff' }}><i className='icon-cloud-download nav-icon' /> Link</Link></td>
    {/* {inthis.renderTableBodyBtns(inthis, perdata)} */}
  </tr>))

export const renderComponent = (vm) =>
  <AdminBlock name='Download Items' desc='Manage download items'>
    <div className='rxcol-100'>
      <RxCrud
        flagsAction={true} renderTableHead={vm.renderTableHead} renderTableBody={vm.renderTableBody} onClickDataNew={vm.onClickDataNew} name='item' api='api_items' form={[
          { type: 'devide' },
          {
            type: 'form', name: 'Basic', func: (inthis) => (<div>
              <div className='rx-form__item'>
                <div>
                  <span>Choose Playlist (1): </span>
                  <div style={{ width: '100%' }}>
                    <RxSelectbox options={vm.state.cate} results={inthis.state.editingData.playlist || ''} onChange={(result, item) => { inthis.onBlurDataValue(result, 'playlist', item) }} />
                  </div>
                  <div className='info-playlist'>
                    <div><span className='s-l'>Origin Language: </span> <span className='s-r'>{inthis.state.playlistSelected.originLang}</span></div>
                    <div><span className='s-l'>Target Language: </span><span className='s-r'>{inthis.state.playlistSelected.targetLang}</span></div>
                    <div><span className='s-l'>Type Sub/dub: </span><span className='s-r'>{inthis.state.playlistSelected.type}</span></div>
                    {/* <div><span className='s-l'>Hardsub / softsub: </span> <span className='s-r'></span>{inthis.state.playlistSelected.name}</span> </div> */}
                    {/* <div><span className='s-l'>Voice Option: </span> <span className='s-r'> {inthis.state.playlistSelected.name}</span> </div> */}
                  </div>
                </div>
              </div>
            </div>)
          },
          { type: 'devide' },
          {
            type: 'form', name: 'Embed ', func: (inthis) => (<div>
              <div className='rx-form__item'>
                <div>
                  <span>Upload video (*): </span>
                  <div style={{ width: '100%' }}>
                    <RxUpload playlistSelected={inthis.state.playlistSelected} callback={(e, file) => inthis.callbackUpload(e, 'filePath', file)} images={inthis.state.editingData.filePath} />
                  </div>
                </div>
              </div>
              <div className='rx-form__item'>
                <div>
                  <span>Upload SRT File (*): </span>
                  <div style={{ width: '100%' }}>
                    <RxUpload playlistSelected={inthis.state.playlistSelected} callback={(e) => inthis.callbackUpload(e, 'srt')} images={inthis.state.editingData.srt} />
                  </div>
                </div>
              </div>

            </div>)
          },
          { type: 'devide' },
          {
            type: 'form', name: 'Advance', func: (inthis) => (<div>
              <div className='rx-form__item'>
                <div>
                  <span>Name (): </span>
                  <div style={{ width: '100%' }}>
                    <input tabIndex='1' type='text' value={inthis.state.editingData.name || ''} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />
                  </div>
                </div>
              </div>

              <div className='rx-form__item'>
                <div>
                  <span>Episode (): </span>
                  <div style={{ width: '100%' }}>
                    <input tabIndex='1' type='text' value={inthis.state.editingData.eip || ''} onChange={(e) => inthis.onBlurData(e, 'eip')} className='fullwidth-input' />
                  </div>
                </div>
              </div>

            </div>)
          },
          {
            type: 'form', name: 'For Admin', func: (inthis) => (<div>
              <div className='rx-form__item'>
                <div>
                  <span>Link Youtube (): </span>
                  <div style={{ width: '100%' }}>
                    <input tabIndex='1' type='text' value={inthis.state.editingData.link || ''} onChange={(e) => inthis.onBlurData(e, 'link')} className='fullwidth-input' />
                  </div>
                </div>
              </div>

              <div className='rx-form__item'>
                <div>
                  <span>Link Download (): </span>
                  <div style={{ width: '100%' }}>
                    <input tabIndex='1' type='text' value={inthis.state.editingData.linkDownload || ''} onChange={(e) => inthis.onBlurData(e, 'linkDownload')} className='fullwidth-input' />
                  </div>
                </div>
              </div>

              <div className='rx-form__item'>
                <div>
                  <span>Srt Status (): </span>
                  <div style={{ width: '100%' }}>
                    <RxToggle value={inthis.state.editingData.srtIsCheck} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'srtIsCheck')} />
                  </div>
                </div>
              </div>


            </div>)
          },
        ]}
      />
    </div>
  </AdminBlock>
