import React from 'react'

const ProgressBar = (props) => {
    const { completed } = props;

    const containerStyles = {
        height: 2,
        width: '100%',
        // backgroundColor: "#ef6c00",
        borderRadius: 50,
    }

    const labelStyles = {
        padding: 0,
        color: 'white',
        fontWeight: 'bold',
        position: 'relative',
        top: 0
    }

    return (
        <div style={containerStyles}>
            <div className='progressbar'>
                <span style={labelStyles}>{`${completed}%`}</span>
            </div>
        </div>
    );
};

export default ProgressBar;