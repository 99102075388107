// Import necessary dependencies
import React, { useState, useEffect, useCallback } from 'react';
import { Link, Switch, Redirect } from 'react-router-dom';
import { renderRoutes } from 'react-router-config'

import Parser from 'html-react-parser';
import { connect } from 'react-redux';
import FolderPopup from '../shares/rxPopup';
import { apiget, apipost, rxsetCookie, rxgetLocal, rxJson } from './../../classes/request' // eslint-disable-line no-unused-vars
const { rxnavToggle, rxnavClose, rxnavChange, logoutAction } = global.rootRequire('redux')

const adminCSS = '<style>.main-container{ width: 100% !important; padding: 0px !important; } .homenav, .footer{ display: none !important; }</style>'

// Define the functional component
const Admin = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  // const [activeNavs, setActiveNavs] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  // const [folderName, setFolderName] = useState('');
  const [folders, setFolders] = useState([]);
  const [editingFolder, setEditingFolder] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleEditFolder = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const [paging,] = useState({
    st_col: 'created_at',
    st_type: -1,
    pg_page: 1,
    pg_size: 5,
  });

  const checkAuth = useCallback(() => {
    if (typeof document !== 'undefined') {
      const match = document.cookie.match(new RegExp('authorize=([^;]+)'))
      if (!match) {
        setIsAuthenticated(false)
      }
    }
  }, [])

  const fetchData = useCallback(() => {
    apiget(global.rxu.config.api_folder, paging, {
      1: (json) => {
        setFolders(json.data)
      },
    })
  }, [paging])

  const handleEditFolder = (folder) => {
    setEditingFolder(folder)
    setIsPopupOpen(true)
    setActiveIndex(null)
  }

  const handleCancelClick = useCallback(() => {
    setIsPopupOpen(false);
  }, [])

  const onClickAddFolder = (e) => {
    setEditingFolder(null)
    setIsPopupOpen(true)
    setActiveIndex(null)
  }

  const handleDeleteFolder = (folder) => {
    if (folder) {
      apiget(
        global.rxu.config.api_folder_delete,
        { _id: folder._id },
        { '1': (json) => { fetchData() } }
      );
      setActiveIndex(null)
    }
  }

  const handleSaveFolder = (newFolderName) => {
    if (newFolderName && newFolderName.trim() === '') { return }
    if (editingFolder) {
      apipost(
        global.rxu.config.api_folder_edit,
        { _id: editingFolder._id, name: newFolderName },
        { '1': (json) => { fetchData() } }
      );
    } else {
      apipost(
        global.rxu.config.api_folder,
        { name: newFolderName },
        { '1': (json) => { fetchData() } }
      );
    }
  };

  // const handleFolderNameChange = useCallback((e) => {
  //   setFolderName(e.target.value);
  // }, []);

  useEffect(() => {
    checkAuth();
    fetchData();
  }, [checkAuth, fetchData]);


  // const toggleNavs = (navName) => {
  //   if (typeof activeNavs[navName] === 'undefined') { activeNavs[navName] = 0 }
  //   activeNavs[navName] = !activeNavs[navName]
  //   setActiveNavs(activeNavs)
  // }

  const renderActiveNavClass = (navpath, addclass = ' rxactive ') => {
    const { pathname } = props.location
    if (pathname === navpath || (Array.isArray(navpath) && navpath.indexOf(pathname) !== -1)) {
      return addclass
    } else {
      return ''
    }
  }
  const onClickLogout = () => {
    // const user = { status: false, user: {}, arrper: [] }
    // this.setState({ user: user })
    setIsAuthenticated(false)
    rxsetCookie('authorize', '', 120)
    props.history.push('/login')
    props.logoutAction()

  }

  return (
    <div>
      <div>
        {isAuthenticated && <div>
          {Parser(adminCSS)}
          <div className='adtopbar'>
            <div className={global.rxu.get(props.rxnav, 'navs.righttoggle') ? 'adtopbar__logo rxsideactive' : 'adtopbar__logo'}>
              <Link to='/admin/dashboard'><img className='adminnav__logoimg' alt='logoimg' src='/images/aicola/logo.png' /></Link>
            </div>
            <div className={global.rxu.get(props.rxnav, 'navs.righttoggle') ? 'adtopbar__togglesidebar rxsideactive' : 'adtopbar__togglesidebar'}><i className='icon-grid topnav-icon' onClick={(e) => props.rxnavToggle('righttoggle')} /></div>
            <div className='adtopbar__right'>
              <div className='adminnav__search'><i className='icon-magnifier topnav-icon' /></div>
              <div className='adminnav__help'><i className='icon-question topnav-icon' /></div>
              <div className='adminnav__notify'>
                {/* <div className='adminnav__notifynum'>5</div> */}
                <i className='icon-bell topnav-icon' />
              </div>
              <div className='adminnav__avatar'>
                <span onClick={ele => props.rxnavToggle('ex_avatar')}>A</span>
                <div className={props.rxnav.navs.ex_avatar ? 'adminnav__avatarmenu rxactive' : 'adminnav__avatarmenu'}>
                  <div className='avatarmenu__fullname'>
                    <div>{global.rxu.get(props, 'auth.user.user.fullname')}</div>
                    <div className='avatarmenu__username'>{global.rxu.get(props, 'auth.user.user.username')}</div>
                  </div>
                  <div className='avatarmenu__logout' onClick={() => onClickLogout()}>
                    <span><i className='icon-power nav-icon' />Logout</span>
                  </div>
                </div>
              </div>
              <div className='adminnav__adname'>{global.rxu.get(props, 'auth.user.fullname')}</div>
              <div className='adminnav__language'><i className='icon-globe topnav-icon' /></div>
            </div>
          </div>

          <div className={global.rxu.get(props.rxnav, 'navs.righttoggle') ? 'beside rxsideactive' : 'beside'}>
            <div className='beside__nav'>
              <div className='beside__navitems'>
                <Link to='/admin/dashboard'>
                  <div className='beside__logo'>
                    {/* <img className='beside__logo_img' src='/images/static/logo.png' alt='AICOLA' /> */}
                    {/* <div className='beside__logoicon'><div>AICOLA</div></div> */}
                  </div>
                </Link>
                <div className='beside_nav_info'>
                  <Link to='/admin/dashboard'>
                    <div className="--icon">
                      <div className="circle-inner"></div>
                      <div className="circle-outer"></div>
                      <div id="box">
                        <span className="avatar">
                          <i className='icon-user nav-icon' />
                        </span>
                      </div>
                    </div>
                    <div className="--text">
                      {global.rxu.get(props, ['auth', 'user', 'fullname'], 'Ho Merch')}
                      <p>Plan: Free trial</p>
                    </div>
                    <i className="fas fa-chevron-down" aria-hidden="true"></i>
                  </Link>
                </div>
                <div className='beside__navitemsinner rxwindowheight' >

                  <div className={props.rxnav.navs.ex_studio ? 'adnav__groupname adnav__groupname--expand' + renderActiveNavClass('/admin/dashboard') : 'adnav__groupname' + renderActiveNavClass('/admin/dashboard')}><Link to='/admin/dashboard'><i className='icon-home nav-icon' />Dashboard</Link></div>

                  <div className={props.rxnav.navs.ex_folders ? 'adnav__groupname adnav__groupname--expand' + renderActiveNavClass('/admin/folders') : 'adnav__groupname' + renderActiveNavClass('/admin/folders')} onClick={ele => props.rxnavToggle('ex_folders')}><span><i className='icon-folder nav-icon' />Folders</span></div>
                  <div className={!props.rxnav.navs.ex_folders ? 'adnav__groupwrap rxactive' : 'adnav__groupwrap'}>
                    {folders && folders.map((folder, index) => {
                      return (
                        <div key={index} className={'adnav__itemname'}><Link rel="noopener noreferrer" to={{ pathname: `/admin/dashboard?slug=${folder._id}` }}><i className='icon-graph nav-icon' />{folder.name}</Link>
                          <div className='rx-edit-folder'>
                            <span className='edit-folder' onClick={() => toggleEditFolder(index)}>
                              <i className='icon-options-vertical nav-icon' />
                            </span>

                            <div className={`rx-edit-folder_content ${activeIndex === index ? 'active' : ''}`}>
                              <div className='rx-edit-folder-label' onClick={() => handleEditFolder(folder)}>
                                <i className='dropdown-icon icon-pencil' /> Rename
                              </div>
                              <div className='dropdown-divider'></div>
                              <div className='rx-edit-folder-label' onClick={() => handleDeleteFolder(folder)}>
                                <i className='dropdown-icon icon-pencil' />Delete
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <div className={'adnav__itemname'}><Link rel="noopener noreferrer" to={{ pathname: `/admin/folder` }}><i className='icon-graph nav-icon' />All folder</Link></div>

                  </div>

                  <div className={'adnav__groupname adnav__groupname--link'} onClick={() => onClickAddFolder()}>
                    <span>
                      <i className='nav-icon nav-icon-custom'><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M216,72H131.31L104,44.69A15.86,15.86,0,0,0,92.69,40H40A16,16,0,0,0,24,56V200.62A15.4,15.4,0,0,0,39.38,216H216.89A15.13,15.13,0,0,0,232,200.89V88A16,16,0,0,0,216,72ZM92.69,56l16,16H40V56ZM216,200H40V88H216Zm-88-88a8,8,0,0,1,8,8v16h16a8,8,0,0,1,0,16H136v16a8,8,0,0,1-16,0V152H104a8,8,0,0,1,0-16h16V120A8,8,0,0,1,128,112Z"></path></svg></i>
                      {/* <i className='icon-folder-alt nav-icon' /> */}
                      New folder</span>
                  </div>


                  {/* {(this.isAdmin === 1) && <div className={props.rxnav.navs.ex_config ? 'adnav__groupname expand iconcustom-up' : 'adnav__groupname iconcustom-down'} onClick={ele => props.rxnavToggle('ex_config')}><span><i className='icon-diamond nav-icon' />Config</span></div>} */}

                  {/* {(this.isAdmin === 1) && <div className={props.rxnav.navs.ex_config ? 'adnav__groupwrap rxactive' : 'adnav__groupwrap'}>
                    <div className={'adnav__itemname' + renderActiveNavClass('/rbac/user')}><Link to='/rbac/user'><i className='icon-graph nav-icon' />Rbac User</Link></div>
                  </div>} */}

                  {isPopupOpen && (
                    <FolderPopup onClose={handleCancelClick} onSave={handleSaveFolder} folderToEdit={editingFolder} />
                  )}

                  <div className={'adnav__groupname adnav__groupname--last adnav__groupname--link' + renderActiveNavClass('/login')} onClick={() => onClickLogout()}>
                    <span><i className='icon-power nav-icon' />Logout</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!!global.rxu.get(props.rxnav, 'navs.righttoggle') && <div className='rxsideactive_back' onClick={(e) => props.rxnavClose('righttoggle')} />}

          <div className={global.rxu.get(props.rxnav, 'navs.righttoggle') ? 'bemain rxsideactive' : 'bemain'}>
            <div className=''>
              <Switch>{renderRoutes(props.route.routes)}</Switch>
            </div>
          </div>
        </div>}
        {!isAuthenticated && <Redirect to='/login' />}
      </div>
    </div >
  );
};
const mapStateToProps = (state, ownProps) => ({
  rxnav: state.rxnav,
  auth: state.auth
})

const mapDispatchToProps = {
  rxnavToggle,
  rxnavClose,
  rxnavChange,
  logoutAction
}

const Admin_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin)

export default Admin_
