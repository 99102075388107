import * as Jsx from './rxCrudUpload.jsx'
global.isclient && require('./rxCrudUpload.css')
global.isclient && require('jsoneditor/dist/jsoneditor.css')

const { apiget, apipost } = global.rootRequire('classes/request')
const RxComponent = global.rootRequire('components/shares/rxComponent').default

const rxu = global.rxu
const WAIT_INTERVAL = 500
class RxCrud extends RxComponent {
    constructor(props, context) {
        super(props, context, Jsx)
        this.state = {
            flagUpdate: false,
            paging: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 },
            pagingGame: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 100 },
            editingData: {},
            extra: {},
            data: {},
            flags: {},
            temp: {},
            studio: [],
            gamge: []
        }

        this.onChangeContentCKE = this.onChangeContentCKE.bind(this)
    }

    componentDidMount() {
        this.fetchAlldata()
    }

    componentWillUnmount() {
        super.componentWillUnmount()
        if (this.jsoneditor) { this.jsoneditor.destroy() }
    }

    componentDidUpdate(prevProps) {
        if ([false, true].indexOf(prevProps.flagUpdate) !== -1 && prevProps.flagUpdate !== this.props.flagUpdate) {
            this.setState({ flagUpdate: prevProps.flagUpdate })
            this.run('parentUpdate', {})
        }
    }

    // D A T A
    fetchAlldata() {
        this.fetchData()
    }

    fetchData(runCallback = false) {
        apiget(rxu.get(rxu.config, this.props.api), this.state.paging, {
            1: (json) => {
                this.setState({ data: json.data, extra: json.extra }, () => {
                    if (!rxu.get(this.state, ['temp', 'filterFields'])) {
                        const temp = this.state.temp; temp.filterFields = rxu.get(this.state, ['data', 0])
                        this.setState({ temp })
                    }
                })
            }
        })
        runCallback ? this.run('dataCallback', {}) : console.log()
    }



    fetchGame(paging) {
        paging = paging || this.state.pagingGame
        apiget(global.rxu.config.api_game_list, { ...paging }, { 1: (json) => { this.setState({ game: json.data }) } })
    }

    run(name, params) {
        if (params) { params.inthis = this } else { params = this }
        if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
            return this.props[name](params)
        } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
            return this[name]()
        }
    }

    toggle(flagname) {
        const flags = this.state.flags
        flags[flagname] = flags[flagname] ? 0 : 1
        this.setState({ flags: flags })
    }

    flag(flagname) {
        return rxu.get(this.state, ['flags', flagname], 0)
    }

    // C L I C K   E V E N T
    onClickData(e, perdata) { }
    onClickSort(e, stcol) {
        const paging = this.state.paging
        paging.st_type = (paging.st_col !== stcol) ? -1 : (-1 * (paging.st_type))
        paging.st_col = stcol
        this.setState({ paging: paging }, () => { this.fetchData() })
    }

    onClickDataImport(e) {

    }

    onClickDataExport(e) {
        apiget(rxu.get(rxu.config, this.props.api), { ...this.state.paging, pg_size: 10000 }, {
            1: (json) => { global.JSONToCSVConvertor(json.data, '') }
        })
    }

    onClickDataNew(e) {
        global.scrolltop()
        const timeStr = Date.now().toString()
        const clone = { name: this.props.name + '_' + timeStr.substr(timeStr.length - 5), desc: '', created_at: 1, is_deleted: 0, is_active: 1, is_hot: 0, price: 100000, app: '', appdist: '' }
        this.setState({ editingData: clone })
    }

    onClickDataEdit(e, perdata) {
        global.scrolltop()
        if (typeof perdata.inthis !== 'undefined') { delete perdata.inthis }
        const clone = rxu.clone(perdata)
        this.setState({ editingData: clone, flags: {} })
    }

    onClickDataDownload(e, perdata) {
        // downloadFile(file_id, file_name) {
        let file_name = perdata.name
        const src = global.rxu.config.base_api + '/upload/image/' + perdata.link
        try {
            // if (!this.blockDownload) {
            this.blockDownload = true
            fetch(src)
                .then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.style.display = 'none'
                    a.href = url
                    a.download = file_name
                    document.body.appendChild(a)
                    a.click()
                    window.URL.revokeObjectURL(url)
                    this.blockDownload = false
                })
                .catch(() => console.log('Error'))


        } catch (e) { }

    }

    onClickDataDelete(e, perdata) {
        const r = window.confirm('Bạn muốn xoá dữ liệu này !')
        if (r === true) {
            apiget(rxu.get(rxu.config, this.props.api) + '/delete', perdata, {
                1: (json) => { this.fetchData(true) }
            })
        } else { }
    }

    onClickDataRestore(e, perdata) {
        apiget(rxu.get(rxu.config, this.props.api) + '/restore', perdata, {
            1: (json) => { this.fetchData(true) }
        })
    }

    onClickDataUpdateSubmit(e, perdata) {
        global.scrolltop()
        apipost(rxu.get(rxu.config, this.props.api) + '/edit', this.state.editingData, {
            1: (json) => { this.fetchData(true); this.onClickDataEdit({}, {}) }
        })
    }

    onClickDataUpdateSubmitRaw(e, perdata) {
        global.scrolltop()
        apipost(rxu.get(rxu.config, this.props.api) + '/edit', { ...this.jsoneditor.get(), _id: this.state.editingData._id, rxraw: 1 }, {
            1: (json) => { this.fetchData(true) },
            default: () => { }
        })
        this.onClickDataEdit({}, {})
    }

    onClickDataCreateSubmit(e, perdata) {
        global.scrolltop()
        apipost(rxu.get(rxu.config, this.props.api), this.state.editingData, {
            1: (json) => { this.fetchData(true); this.onClickDataEdit({}, {}) }
        })
    }

    onClickDataTrash(e, isdeleted) {
        const paging = this.state.paging
        paging.search_is_deleted = isdeleted
        this.setState({ paging: paging }, () => {
            this.fetchData()
        })
    }

    onClickGenPermission() {
        apiget(rxu.config.api_permission_genpermission, {}, {
            1: (json) => {
                this.fetchData()
            }
        })
    }

    // B L U R   E V E N T
    onBlurData(e, name, options) {
        options = options || {}
        const editingData = this.state.editingData
        if (options.strim) {
            editingData[name] = e.target.value.toString().replace(/(,)/g, '')
        } else {
            editingData[name] = e.target.value
        }
        this.setState({ editingData: editingData })
    }

    onChangeValue(e, name) {
        const editingData = this.state.editingData
        editingData[name] = e.target.value
        this.setState({ editingData: editingData })
    }

    onBlurDataValue(value, name) {
        const editingData = this.state.editingData
        editingData[name] = value
        if (name === 'studio') {
            const paging = this.state.pagingGame
            paging.searchin_studio = value.split(',')
            this.fetchGame(paging)
        }
        this.setState({ editingData: editingData })
    }

    onBlurDatafilter(e, name) {
        clearTimeout(this.timerDatafilter)
        const paging = this.state.paging
        paging['search_' + name] = e.target.value
        this.setState({ paging: paging })

        this.timerDatafilter = setTimeout((e, name) => {
            this.fetchData()
        }, WAIT_INTERVAL)
    }

    // C H A N G E  E V E N T
    onChangeContentCKE(evt) {
        const editingData = this.state.editingData
        const newContent = evt.editor.getData()
        editingData.content = newContent
        this.setState({ editingData: editingData })
    }

    // P A G I N  E V E N T
    onClickPaging(page) {
        const paging = this.state.paging
        const count = rxu.get(this.state.extra, 'count', paging.pg_size)
        const maxpage = Math.ceil(count / paging.pg_size)

        if (page < 1) { page = 1 }
        if (page > maxpage) { page = maxpage }

        paging.pg_page = page
        this.setState({ paging: paging }, () => { this.fetchData() })
    }

    onChangePageSize(evt) {
        clearTimeout(this.timerPageSize)
        const pagesize = parseInt(evt.target.value, 10)
        const paging = this.state.paging
        paging.pg_size = pagesize
        paging.pg_page = 1
        if (pagesize >= 1 && pagesize <= 1000) {
            this.setState({ paging: paging }, () => { this.timerPageSize = setTimeout((e, name) => { this.fetchData() }, WAIT_INTERVAL) })
        }
    }

    // U P L O A D  E V E N T
    callbackUpload(e) {
        this.fetchData()
    }

    callbackUploadDetail(e) {
        this.onBlurData({ target: { value: e.images } }, 'img_detail')
    }

    // H E L P E R S
    helpProductcat(cats) {
        let result = ''
        if (cats) {
            for (let i = 0; i < cats.length; i++) { result += cats[i].name + ', ' }
            return result
        }
    }

    helpSortClass(stcol, extraclass) {
        extraclass = extraclass || []
        let result = ''
        if (this.state.paging.st_col === stcol) {
            result = this.state.paging.st_type === 1 ? 'rx-sort-asc' : 'rx-sort-desc'
        }
        for (let i = 0; i < extraclass.length; i++) { result += ' ' + extraclass[i] }
        return result
    }

    // R E N D E R S
    render() { return this.renderComponent() }
}

RxCrud.defaultProps = { name: '', api: '', form: [], dataCallback: () => { }, renderPage: () => { }, data: {} }
export default RxCrud
