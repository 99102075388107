import React, { Component } from 'react'
import CKEditor from 'react-ckeditor-component'
import { Link } from 'react-router-dom'

// const { apiget } = global.rootRequire('classes/request')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default

class studioComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  renderTableHead(inthis) {
    return (<tr>
      <th className='rxwidth--100 t-al'>No</th>
      <th className={inthis.helpSortClass('name', ['rx-th-width-220 t-al'])} onClick={(e) => inthis.onClickSort(e, 'name')}>Playlists</th>
      <th className={inthis.helpSortClass('originLang', ['rx-th-width-220 t-al'])} onClick={(e) => inthis.onClickSort(e, 'name')}>Origin Language</th>
      <th className={inthis.helpSortClass('targetLang', ['rx-th-width-220 t-al'])} onClick={(e) => inthis.onClickSort(e, 'targetLang')}>Target Language</th>
      {/* <th className={inthis.helpSortClass('status', ['rx-th-width-220 t-al'])} onClick={(e) => inthis.onClickSort(e, 'status')}>Status</th> */}
      <th className={inthis.helpSortClass('created_at', ['rxwidth--120 t-al'])} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Created at</th>
      <th className={inthis.helpSortClass('updated_at', ['rxwidth--120 t-al'])} onClick={(e) => inthis.onClickSort(e, 'updated_at')}>Updated at</th>

      <th className='rxwidth--30 t-al'>Action</th>
    </tr>)
  }

  renderTableBody(inthis) {
    return global.rxu.array(inthis.state.data).map((perdata, index) => (<tr key={perdata._id}>
      <td className='t-al'>{index + 1}</td>
      <td><Link rel="noopener noreferrer" to={{ pathname: `/admin/items?slug=${perdata._id}` }} style={{ color: '#1890ff' }}>{perdata.name}</Link></td>

      {/* <td className=''>{perdata.name}</td> */}
      <td className='betable__gamelink'>{perdata.originLang}</td>
      <td className='betable__gamelink'>{perdata.targetLang}</td>
      {/* <td className='t-al'>{(perdata.status === 0) ? 'Processing' : 'Completed'}</td> */}
      <td className='t-al'><small>{global.rxu.date(perdata.created_at)}</small></td>
      <td className='t-al'><small>{global.rxu.date(perdata.updated_at)}</small></td>
      {inthis.renderTableBodyBtns(inthis, perdata)}

    </tr>))
  }

  // R E N D E R S
  render() {
    return (
      <div className='adblock'>
        <div className='adblock__head'>
          <div className='card-body dashboard-cta-type-2'>
            <div className='d-flex flex-wrap'>
              <div className='card d-card-1 px-3'>
                <Link className='d-card-1-link' to='/admin/items'>
                  <div className='w-100'><img className='p-icon' alt='aicola' src='/images/static/icon2.svg' /></div>
                  <div className='w-100'>
                    <label className='text-bold title'>Get Subtitle</label>
                  </div>
                  <div className='w-100'>
                    <div className='desc'><p>Generate Subtitles in any language.</p></div>
                  </div>
                </Link>
              </div>
              <div className='card d-card-1'>
                <Link className='d-card-1-link' to='/admin/items'>
                  <div className='w-100'><img className='p-icon' alt='aicola' src='/images/static/icon1.svg' /></div>
                  <div className='w-100'>
                    <label className='text-bold title'>Get Dubbing</label>
                  </div>
                  <div className='w-100'>
                    <div className='desc'><p>Dubbing Powered By AI.</p></div>
                  </div>
                </Link>
              </div>
            </div>
          </div>




          <div className='adblock__title'>My Videos</div>
          {/* <div className='adblock__desc'>Manage Projects</div> */}
        </div>
        <div className='adblock__body'>
          <div className='row adblock__inner'>
            <div className='rxcol-100'>
              <RxCrud
                flagsAction={false} renderTableHead={this.renderTableHead} renderTableBody={this.renderTableBody} flagUpdate={this.state.flagUpdateCat} parentUpdate={this.runUpdateCat} name='Playlists' api='api_playlists' form={[
                  { name: 'Name', keyInput: 'name', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.name || ''} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />) },
                  { type: 'devide' },

                  { name: 'Origin Language', keyInput: 'originLang', func: (inthis) => (<RxSelectbox options={global.LANGUAGES} results={inthis.state.editingData.originLang || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'originLang') }} />) },
                  { name: 'Targe Language', keyInput: 'targetLang', func: (inthis) => (<RxSelectbox options={global.LANGUAGES} results={inthis.state.editingData.targetLang || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'targetLang') }} />) },
                  { type: 'devide' },
                  { name: 'Type (sub or dub )', keyInput: 'type', func: (inthis) => (<RxSelectbox options={global.TYPES} results={inthis.state.editingData.type || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'type') }} />) },
                  // { name: 'SoftSub or HardSub', func: (inthis) => (<RxSelectbox options={global.SUBTYPES} results={inthis.state.editingData.subtype || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'subtype') }} />) },
                  { name: 'Technical', keyInput: 'infoExtra', func: (inthis) => (<CKEditor activeClass='p10' content={inthis.state.editingData.infoExtra} events={{ change: (evt) => { const editingData = inthis.state.editingData; const newContent = evt.editor.getData(); editingData.infoExtra = newContent; inthis.setState({ editingData: editingData }) } }} />) },

                ]}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default studioComponent


// name
// file (tương ứng tên file trên ổ cứng )
// link ( link down file mp4)
// srtType
// srtIsCheck
