import React, { Component } from 'react'
global.isclient && require('./rxSelectbox.css')

class RxSelectbox extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      toggle: false,
      options: this.props.options,
      multi: this.props.multi || true,
      results: this.props.results ? (this.props.results.constructor === Array ? this.props.results : this.props.results.split(',')) : []
    }
  }

  toggle() {
    // this.setState({toggle: !this.state.toggle})
  }

  onClickItem(e, item) {
    e.stopPropagation()
    if (this.state.results.indexOf(item._id) === -1) {
      let tempResults = this.state.results
      tempResults.unshift(item._id)
      if (!this.props.multi) {
        tempResults = tempResults.slice(0, 1)
      }
      this.setState({ results: tempResults }, () => {
        const tempResults = this.state.results ? (this.state.results.constructor === Array ? this.state.results.join(',') : this.state.results) : ''

        this.props.onChange(tempResults, item)
      })
    }
  }

  onClickResult(e, item) {
    e.stopPropagation()
    const tempIndex = this.state.results.indexOf(item._id)
    if (tempIndex !== -1) {
      const tempResults = this.state.results
      tempResults.splice(tempIndex, 1)

      this.setState({ results: tempResults }, () => {
        const tempResults = this.state.results ? (this.state.results.constructor === Array ? this.state.results.join(',') : this.state.results) : ''
        this.props.onChange(tempResults)
      })
    }
  }

  onChangeFilter(e) {
    // let tempValue = e.target.value
  }

  render() {
    const options = this.props.options.map((item, index) => (
      this.state.results.indexOf(item._id) === -1 ? <div key={index} onClick={(e) => this.onClickItem(e, item)}>{item.name}</div> : <span key={index} />
    ))
    const results = this.props.options.map((item, index) => (
      this.state.results.indexOf(item._id) !== -1 ? <div key={index} onClick={(e) => this.onClickResult(e, item)}>{item.name} <b>x</b></div> : <span key={index} />
    ))
    return (
      <div className='rxselectbox-wrap'>
        <div className='rxselectbox-result clearfix' onClick={() => this.toggle()}>
          {!this.state.results.length && <span className='rxselectbox-resultempty'>Click here to select</span>} {results}
        </div>
        {!this.state.toggle && <div className='rxselectbox-toggle'>
          <div className='rxselectbox-filter'>{/* <input type='text' className='' onChange={this.onChangeFilter(e)} /> */}</div>
          {options}
        </div>}
      </div>
    )
  }
}
RxSelectbox.defaultProps = { onChange: () => { }, options: [], results: [] }
// Options : {key: , text: }
export default RxSelectbox
