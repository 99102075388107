import React from 'react'
import './rxTemplates.css'
import { Link } from 'react-router-dom'

export const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <div>
      <h3>Breadcrumbs:</h3>
      <ul>
        {breadcrumbs && breadcrumbs.map((crumb, index) => (
          <li key={index}>{crumb?.name}</li>
        ))}
      </ul>
    </div>
  );
};

export const MainTable = (props) => { return <div className='betable__main'><table className='betable__inner'>{props.children}</table></div> }
export const AdminBlock = (props) => {
  return <div className='adblock'>

    <div className={'adblock__head ' + props.exclass}>

      {/* <div className='card-body dashboard-cta-type-2'>
        <div className='d-flex flex-wrap'>
          <div className='card d-card-1 px-3'>
            <Link className='d-card-1-link' to='/admin/items'>
              <div className='w-100'><img className='p-icon' alt='aicola' src='/images/static/icon2.svg' /></div>
              <div className='w-100'>
                <label className='text-bold title'>Get Subtitle</label>
              </div>
              <div className='w-100'>
                <div className='desc'><p>Generate Subtitles in any language.</p></div>
              </div>
            </Link>
          </div>
          <div className='card d-card-1'>
            <Link className='d-card-1-link' to='/admin/items'>
              <div className='w-100'><img className='p-icon' alt='aicola' src='/images/static/icon1.svg' /></div>
              <div className='w-100'>
                <label className='text-bold title'>Get Dubbing</label>
              </div>
              <div className='w-100'>
                <div className='desc'><p>Dubbing Powered By AI.</p></div>
              </div>
            </Link>
          </div>
        </div>
      </div> */}
      <div className='adblock__title'>{props.name}</div>
      {/* <div className='adblock__desc'>{props.desc}</div> */}
    </div>

    {/* <Breadcrumbs breadcrumbs={props.breadcrums} /> */}

    <div className={'adblock__body ' + props.exclass}>
      <div className='row adblock__inner'>
        {props.children}
      </div>
      <div className='clearfix-martop' />
    </div>
  </div>
}
// export const
