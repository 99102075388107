import React, { useState, useEffect } from 'react';

const FolderPopup = ({ onClose, onSave, folderToEdit }) => {
    const [folderName, setFolderName] = useState('');

    useEffect(() => {
        if (folderToEdit) {
            setFolderName(folderToEdit.name);
        }
    }, [folderToEdit]);

    const handleFolderNameChange = (e) => {
        setFolderName(e.target.value);
    };

    const handleSaveFolder = () => {
        if (folderName.trim() !== '') {
            onSave(folderName);
            onClose();
        }
    };

    const handleCancelClick = () => {
        onClose();
    };

    return (
        <div className="popup-overlay">
            <div className='DialogSmallContent'>
                <div className='dialog-header css-0'>

                    <div className='dialog-title css-0'>{folderToEdit ? 'Edit Folder' : 'Create New Folder'}</div>
                </div>
                <div className='css-17sqods'>
                    <div className='css-b7jmoi'>
                        <span>
                            <img alt='Create New' sizes='100vw' src='./images/static/create_folder.svg' />
                        </span>
                    </div>
                </div>
                <div className='css-8atqhb'>
                    <div className='dialog-label css-0'>Folder Name</div>
                    <div className='input-container'>
                        <input className='input-element semibold' type="text" value={folderName} onChange={(e) => handleFolderNameChange(e)} />
                    </div>
                </div>
                <div className='dialog-footer css-0'>
                    <div className='css-1rr4qq7'></div>
                    <button onClick={handleCancelClick} type='button' className='chakra-button css-14jsxci'>Cancel</button>
                    <button onClick={handleSaveFolder} type='button' className='chakra-button css-9il83r'>{folderToEdit ? 'Save' : 'Create'}</button>
                </div>
            </div>
        </div>
    );
};

export default FolderPopup;
