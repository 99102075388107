import * as Jsx from './download.jsx'

const RxComponent = global.rootRequire('components/shares/rxComponent').default
const { apiget } = global.rootRequire('classes/request')

class itemsComponent extends RxComponent {
  constructor(props, context) {
    super(props, context, Jsx)
    this.onClickCatItem = this.onClickCatItem.bind(this)
    this.runUpdateCat = this.runUpdateCat.bind(this)
    this.state = {
      flagUpdateCat: false,
      curCat: 0,
      cate: [{ "name": "Default", "originLanguage": "zh", "targetLanguge": "vi", "type": "dub", "is_deleted": 0, "is_active": 1, "created_at": 1698920898, "updated_at": 1698920898, "_id": "6544528d152f6e22c4093709", "__v": 0 }]
    }

    this.fetchData()
  }

  componentDidMount() {
  }

  fetchData() {
    apiget(global.rxu.config.api_playlists, {}, { 1: (json) => { this.setState({ cate: json.data }, () => { }) } })
  }

  onClickCatItem(e) {
    this.setState({ flagUpdateCat: !this.state.flagUpdateCat, curCat: e._id })
  }

  runUpdateCat(e) {
    const paging = (this.state.curCat) ? { searchid_appdist: this.state.curCat, st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 } : { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 }
    e.inthis.setState({ paging: paging }, () => { e.inthis.fetchData() })
  }

  onClickDataNew(e) {
    global.scrolltop()
    const timeStr = Date.now().toString()
    const clone = {
      name: 'Item_' + timeStr,
      eip: 0,
      link: '',
      filePath: '',
      fileSize: 0,
      fileType: '',
      srt: '',
      playlist: '6544528d152f6e22c4093709',
      status: 0,
      args: '',
      srtIsCheck: 0,
      created_at: 1,
      is_deleted: 0,
      is_active: 1,
    }

    e.inthis.setState({ editingData: clone })
  }


  // R E N D E R S
  render() { return this.renderComponent() }
}

export default itemsComponent
