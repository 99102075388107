import React, { useState } from 'react';
import ProgressBar from "./rxProgressBar";


const RxMinioUploader = ({ id, type, callback, isEditData, videoInfo }) => {
    const [video, setVideo] = useState(null)
    const [progress, setProgress] = useState(0)
    const [inputType, setInputType] = useState(type)
    console.log({ isEditData })
    const onChangeVideoFile = (e) => {
        // if (isEditData) return
        const selectedFile = e.target.files[0];
        console.log(selectedFile)
        if (selectedFile) {
            setVideo(selectedFile);
            callback(selectedFile);
        }
    };

    const handleCancelClick = (e) => {
        setVideo(null)
    }

    return (
        <div className='clearfix'>
            <div id="drop-area">
                <p>Click here to select a {inputType}</p>
                <label className='selectfile' htmlFor={`videoInput_${id}`} id={`videoInputLabel_${id}`}>Select {inputType}</label>
                <input
                    disabled={isEditData}
                    id={`videoInput_${id}`}
                    type="file"
                    style={{ display: 'none' }}
                    className="rxinput_upload hidden"
                    onChange={onChangeVideoFile}
                    name={inputType}
                    accept={inputType === 'video' ? 'video/mp4' : "audio/*,video/*,.vtt,.srt, image/png"}
                />

            </div>
            <div id="file-list" className="active">
                {(video) && (
                    <>
                        <div className="css-prtgfy">
                            <div className="css-vflqj6">
                                <span>
                                    {video && inputType === 'video' ?
                                        <video className='admin-product-video-upload' controls>
                                            <source src={URL.createObjectURL(video)} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        :
                                        video && video?.type && video?.type?.startsWith('image/') ? (
                                            <img className='admin-product-img-upload' alt='ico_default' src={URL.createObjectURL(video)} />
                                        ) : (
                                            <div />
                                        )
                                    }
                                </span>
                            </div>
                            <div className="css-9210yo">
                                <div className="css-8atqhb">
                                    <div className="css-1y3ksvi">
                                        <div className="text-trim css-1rxzejg">{global.rxu.shorten(video?.name, 20, 5)}</div>
                                        <div className="GhostDropdownButton">
                                        </div>
                                    </div>
                                    <div className="css-lgdflq"></div>
                                </div>
                                <div className="css-1hel27t">
                                    <div className="css-0">Size: {global.rxu.formatBytes(video?.size)} </div>
                                    <div className="css-7uvy9n"> Type: {video?.type}</div>
                                </div>
                            </div>
                            <div className='css-close-popoup'>
                                <button type="button" onClick={handleCancelClick}>
                                    <svg stroke="currentColor" fill="none" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </>
                )}

                {isEditData && videoInfo &&

                    <>
                        <div className="css-prtgfy">
                            <div className="css-vflqj6">
                                <span>
                                    {videoInfo && videoInfo?.type === 'video/mp4' ?
                                        <video className='admin-product-video-upload' controls>
                                            <source src={videoInfo && videoInfo?.path || ''} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        :
                                        videoInfo && videoInfo.type && videoInfo.type.startsWith('image/') ? (
                                            <img className='admin-product-img-upload' alt='ico_default' src={videoInfo?.path} />
                                        ) : (
                                            <div />
                                        )
                                    }
                                </span>
                            </div>
                            <div className="css-9210yo">
                                <div className="css-8atqhb">
                                    <div className="css-1y3ksvi">
                                        <div className="text-trim css-1rxzejg">{global.rxu.shorten(videoInfo?.name, 20, 5)}</div>
                                        <div className="GhostDropdownButton">
                                        </div>
                                    </div>
                                    <div className="css-lgdflq"></div>
                                </div>
                                <div className="css-1hel27t">
                                    <div className="css-0">Size: {global.rxu.formatBytes(videoInfo?.size)} </div>
                                    <div className="css-7uvy9n"> Type: {videoInfo?.type}</div>
                                </div>
                            </div>
                            <div className='css-close-popoup'>
                                <button type="button" onClick={handleCancelClick}>
                                    <svg stroke="currentColor" fill="none" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </>

                }
            </div>
            {/* {progress > 0 && <ProgressBar bgcolor={'#1990ff'} completed={progress} />} */}
        </div >
    )
}

export default RxMinioUploader