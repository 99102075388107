import React, { Component } from 'react'

const { apiget } = global.rootRequire('classes/request')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxToggle = global.rootRequire('components/shares/rxToggle').default
class userComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arrrole: [],
      studio: [],
      paging: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 100 }
    }
    this.fetchData()
  }

  fetchData() {
    apiget(global.rxu.config.api_role_all, {}, { 1: (json) => { this.setState({ arrrole: json.data }) } })
  }

  renderTableHead(inthis) {
    return (<tr>
      <th className={inthis.helpSortClass('fullname', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'fullname')}>FullName</th>
      <th className={inthis.helpSortClass('phone', ['rx-th-width-220'])} onClick={(e) => inthis.onClickSort(e, 'phone')}>Phone</th>
      <th className={inthis.helpSortClass('created_at')} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Created at</th>
      <th className='rxwidth--100'>Action</th>
    </tr>)
  }

  renderTableBody(inthis) {
    return global.rxu.array(inthis.state.data).map(perdata => (<tr key={perdata._id}>
      <td>{perdata.fullname}</td>
      <td>{perdata.phone}</td>
      <td><small>{global.rxu.date(perdata.created_at)}</small></td>
      <td>
        {inthis.state.paging.search_is_deleted !== 1 &&
          <div> {(perdata.is_hot) && <span className='betable__icohot'><span className='icon-fire' />Hot</span>}
            {(perdata.is_active !== 0) && <span className='betable__icoon'>On</span>}{(perdata.is_active === 0) && <span className='betable__icooff'>Off</span>}
            <span className='betable__btnedit' onClick={(e) => inthis.onClickDataEdit(e, perdata)}><i className='icon-pencil' /></span>
            <span className='betable__btndelete' onClick={(e) => inthis.onClickDataDelete(e, perdata)}><i className='icon-close' /></span>
          </div>}
        {inthis.state.paging.search_is_deleted === 1 &&
          <div> <span className='betable__restore' onClick={(e) => inthis.onClickDataRestore(e, perdata)}>Restore</span></div>}
      </td>
    </tr>))
  }

  // R E N D E R S
  render() {
    return (
      <div className='adblock'>
        <div className='adblock__head'>
          <div className='adblock__title'>user</div>
          <div className='adblock__desc'>user</div>
        </div>
        <div className='adblock__body'>
          <div className='row adblock__inner'>
            <div className='rxcol-100'>
              <RxCrud
                renderTableHead={this.renderTableHead} renderTableBody={this.renderTableBody} flagUpdate={this.state.flagUpdateCat} parentUpdate={this.runUpdateCat} name='User' api='api_user' form={[
                  { name: 'UserName *', keyInput: 'username', func: (inthis) => (<input tabIndex='1' type='text' value={inthis.state.editingData.username || ''} onChange={(e) => inthis.onBlurData(e, 'username')} className='fullwidth-input' />) },
                  { name: 'FullName *', keyInput: 'fullname', func: (inthis) => (<input tabIndex='2' type='text' value={inthis.state.editingData.fullname || ''} onChange={(e) => inthis.onBlurData(e, 'fullname')} className='fullwidth-input' />) },
                  { type: 'devide' },

                  { name: 'Email *', keyInput: 'email', func: (inthis) => (<input tabIndex='3' type='text' value={inthis.state.editingData.email || ''} onChange={(e) => inthis.onBlurData(e, 'email')} className='fullwidth-input' />) },
                  { name: 'Phone *', keyInput: 'phone', func: (inthis) => (<input tabIndex='4' type='text' value={inthis.state.editingData.phone || ''} onChange={(e) => inthis.onBlurData(e, 'phone')} className='fullwidth-input' />) },

                  // { type: 'devide' },
                  // { name: 'Role *', keyInput: 'role', func: (inthis) => (<select value={inthis.state.editingData.roleid} onChange={(e) => inthis.onBlurData(e, 'roleid')} className='fullwidth-input'><option value=''>--Choose Role--</option>{this.state.arrrole.map(role => (<option key={role._id} value={role._id}>{role.name}</option>))}</select>) },

                  { type: 'devide' },
                  { name: 'Password *', keyInput: 'password', func: (inthis) => (<input tabIndex='6' type='password' value={inthis.state.editingData.password || ''} onChange={(e) => inthis.onBlurData(e, 'password')} className='fullwidth-input' />) },
                  { name: 'RePassword *', keyInput: 'repassword', func: (inthis) => (<input tabIndex='6' type='password' value={inthis.state.editingData.repassword || ''} onChange={(e) => inthis.onBlurData(e, 'repassword')} className='fullwidth-input' />) },

                  { type: 'devide' },
                  { name: 'Status', keyInput: 'status', func: (inthis) => (<RxToggle value={inthis.state.editingData.is_active} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'is_active')} />) },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default userComponent
