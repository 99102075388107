import React from 'react'
// import { Link } from 'react-router-dom'
// import CKEditor from 'react-ckeditor-component'

const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const RxCrud = global.rootRequire('components/shares/rxCrud').default
const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxUpload = global.rootRequire('components/shares/rxUpload').default
const rxu = global.rxu

// const RxToggle = global.rootRequire('components/shares/rxToggle').default
const StatusColorMap = ['black', 'orange', 'green', 'red', 'yellow', 'lightgreen', 'lightblue']

export const renderTableHead = (inthis, vm) =>
  <tr>
    <th className='rxwidth--100 t-al'>No</th>
    <th className={inthis.helpSortClass('name', ['rx-th-width-220 t-al'])}>Name</th>
    <th className={inthis.helpSortClass('orderID', ['rx-th-width-220 t-al'])} onClick={(e) => inthis.onClickSort(e, 'orderID')}>Order ID</th>
    <th className={inthis.helpSortClass('fileSize', ['rx-th-width-220 t-al'])} onClick={(e) => inthis.onClickSort(e, 'fileSize')}>File size</th>
    <th className={inthis.helpSortClass('status', ['rx-th-width-220 t-al'])}>Status</th>
    <th className={inthis.helpSortClass('created_at', ['rxwidth--120 t-al'])} onClick={(e) => inthis.onClickSort(e, 'created_at')}>Created at</th>
    <th className={inthis.helpSortClass('updated_at', ['rxwidth--120 t-al'])} onClick={(e) => inthis.onClickSort(e, 'updated_at')}>Updated at</th>
    <th className={inthis.helpSortClass('linkDownload', ['rx-th-width-220 t-al'])}>Download</th>
    <th className={inthis.helpSortClass('', ['rx-th-width-50'])}>Action</th>
  </tr>

export const renderTableBody = (inthis, vm) =>
  global.rxu.array(inthis.state.data).map((perdata, index) => (<tr key={perdata._id}>
    <td className='t-al'>{index + 1}</td>
    {/* <td>{inthis.helpProductcat(rxu.get(perdata, 'playlistObj'))}</td> */}
    {/* <td>{perdata.name.slice(0, 5) + '...'}</td> */}
    <td className='t-al'>{rxu.get(perdata, 'name')}</td>
    <td className='t-al'>{rxu.get(perdata, 'orderID')}</td>
    <td className='t-al'>{inthis.formatBytes(rxu.get(perdata, 'fileSize'))}</td>
    {/* <td className='t-al'>{rxu.get(perdata, 'epi')}</td> */}
    {/* <td>{rxu.get(perdata, 'link')}</td> */}
    <td className='t-al' style={{ fontWeight: 'bold', color: `${StatusColorMap[rxu.get(perdata, 'status', 'red')]}` }}>{inthis.helpStatus(rxu.get(perdata, 'status'), 'ITEMSTATUS')}</td>
    <td className='t-al'><small>{global.rxu.date(rxu.get(perdata, 'created_at'))}</small></td>
    <td className='t-al'><small>{global.rxu.date(rxu.get(perdata, 'updated_at'))}</small></td>
    <td className='t-al'>{[2, 3, 4, 10].indexOf(perdata?.status) !== -1 ? inthis.renderHelpLink(inthis, perdata.linkDownload) : <i className='icon-cloud-download nav-icon unactive' />}</td>
    {/* <td className='t-al'>{perdata?.status === 2 ? <Link target="_blank" rel="noopener noreferrer" to={{ pathname: inthis.helpParseJson(rxu.get(perdata, 'linkDownload')) }} style={{ color: '#1890ff' }}><i className='icon-cloud-download nav-icon' /> Link</Link> : <i className='icon-cloud-download nav-icon unactive' />}</td> */}
    {inthis.renderTableBodyBtns(inthis, perdata)}
  </tr>))


export const renderComponent = (vm) =>
  <AdminBlock name={vm.state.catName ? `Upload item for  ${vm.state.catName} ` : 'Upload Item'} desc='Manage upload items' breadcrums={vm.state.breadcrums}>
    <div className='rxcol-100'>
      {vm.isAdmin ?
        <RxCrud
          flagsAction={false} searchId={vm.state.searchId} renderTableHead={vm.renderTableHead} renderTableBody={vm.renderTableBody} onClickDataNew={vm.onClickDataNew} name='item' api='api_items' form={[
            { type: 'devide' },
            {
              type: 'form', name: 'Upload File', keyInput: 'name', func: (inthis) => (<div>
                <div className='rx-form__item'>
                  {/* <div>
                    <span>Choose Playlist: </span>
                    <div style={{ width: '100%' }}>
                      <RxSelectbox options={vm.state.cate} results={inthis.state.editingData.playlist || ''} onChange={(result, item) => { inthis.onBlurDataValue(result, 'playlist', item) }} />
                      {inthis.state.error && Object.keys(inthis.state.error) && Object.keys(inthis.state.error).indexOf('playlist') !== -1 && <div className='rxerror'>* This is a required information </div>}

                    </div>
                  </div> */}
                </div>
                <div className='rx-form__item'>
                  <div>
                    <span>Choose Folder: </span>
                    <div style={{ width: '100%' }}>
                      <RxSelectbox options={vm.state.folders} results={inthis.state.editingData.folder || ''} onChange={(result, item) => { inthis.onBlurDataValue(result, 'folder', item) }} />
                      {inthis.state.error && Object.keys(inthis.state.error) && Object.keys(inthis.state.error).indexOf('folder') !== -1 && <div className='rxerror'> </div>}

                    </div>
                  </div>
                </div>
                <div className='rx-form__item'>
                  <div>
                    <span>Name: </span>
                    <div style={{ width: '100%' }}>
                      <input tabIndex='1' type='text' value={inthis.state.editingData.name || ''} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />
                      {inthis.state.error && Object.keys(inthis.state.error) && Object.keys(inthis.state.error).indexOf('name') !== -1 && <div className='rxerror'>* This is a required information </div>}

                    </div>
                  </div>
                </div>

                {/* <div className='rx-form__item'>
                  <div>
                    <span>Episode: </span>
                    <div style={{ width: '100%' }}>
                      <input tabIndex='1' type='text' value={inthis.state.editingData.epi || ''} onChange={(e) => inthis.onBlurData(e, 'epi')} className='fullwidth-input' />
                      {inthis.state.error && Object.keys(inthis.state.error) && Object.keys(inthis.state.error).indexOf('epi') !== -1 && <div className='rxerror'>* This is a required information </div>}

                    </div>
                  </div>
                </div> */}
                <div className='rx-form__item'>
                  <div>
                    <span>Upload video (*): </span>
                    <div style={{ width: '100%' }}>
                      <RxUpload id='uploadaudio' playlistSelected={inthis.state.playlistSelected} callback={(e, file) => inthis.callbackUpload(e, 'filePath', file)} images={inthis.state.editingData.filePath} name={inthis.state.editingData?.fileName} />
                      {inthis.state.error && Object.keys(inthis.state.error) && Object.keys(inthis.state.error).indexOf('filePath') !== -1 && <div className='rxerror'>*The upload process needs to be completed </div>}

                    </div>
                  </div>
                </div>
              </div>)
            },
            { type: 'devide' },
            {
              type: 'form', name: 'Upload Srt file or Paste Youtube Link', keyInput: 'name', func: (inthis) => (<div>
                <div className='rx-form__item'>
                  <div>
                    <span>Upload SRT File: </span>
                    <div style={{ width: '100%' }}>
                      <RxUpload id='uploadsrt' playlistSelected={inthis.state.playlistSelected} callback={(e, file) => inthis.callbackUpload(e, 'srt', file)} images={inthis.state.editingData.srt} name={inthis.state.editingData?.fileSrtName} />
                      {inthis.state.error && Object.keys(inthis.state.error) && Object.keys(inthis.state.error).indexOf('srt') !== -1 && <div className='rxerror'>*The upload process needs to be completed </div>}

                    </div>
                  </div>
                </div>
                <div className='rx-devide'><b className='rx-or'>OR</b></div>
                <div className='rx-form__item'>
                  <div>
                    <span>Link Input (Youtube): </span>
                    <div style={{ width: '100%' }}>
                      <input tabIndex='1' placeholder='https://youtube.com/0LOMJWSGS' type='text' value={inthis.state.editingData.linkYt || ''} onChange={(e) => inthis.onBlurData(e, 'linkYt')} className='fullwidth-input' />
                    </div>
                  </div>
                </div>
              </div>)
            },
            // { type: 'devide' },
            // {
            //   type: 'form', name: 'Custom ', keyInput: 'name', func: (inthis) => (<div>
            //     <div className='rx-form__item'>
            //       <div>
            //         <span>Language in Video: </span>
            //         <div style={{ width: '50%' }}>
            //           <RxSelectbox options={global.LANGUAGES} results={rxu.get(inthis.state.playlistSelected, 'originLang', '')} onChange={(result) => { inthis.onBlurOptions(result, 'originLang', 'extra') }} />
            //         </div>
            //       </div>
            //       <div>
            //         <span>Dubbing Language: </span>
            //         <div style={{ width: '50%' }}>
            //           <RxSelectbox options={global.LANGUAGES} results={rxu.get(inthis.state.playlistSelected, 'targetLang', '')} onChange={(e) => inthis.onBlurOptions(e, 'targetLang', 'extra')} />
            //         </div>
            //       </div>
            //     </div>
            //     <div className='rx-form__item'>
            //       <div>
            //         <span>Type Sub/dub: </span>
            //         <div style={{ width: '50%' }}>
            //           <RxSelectbox options={global.TYPES} results={rxu.get(inthis.state.playlistSelected, 'type', '')} onChange={(e) => inthis.onBlurOptions(e, 'type', 'extra')} />
            //         </div>
            //       </div>
            //       <div>
            //         <span>Hardsub / softsub: </span>
            //         <div style={{ width: '50%' }}>
            //           <RxSelectbox options={global.SUBTYPES} results={rxu.get(inthis.state.playlistSelected, 'isHardsub', '')} onChange={(e) => inthis.onBlurOptions(e, 'isHardsub', 'extra')} />
            //         </div>
            //       </div>
            //     </div>
            //     <div className='rx-form__item'>
            //       <div>
            //         <span>FontSize: </span>
            //         <div style={{ width: '100%' }}>
            //           <input tabIndex='1' placeholder='default: 18' type='text' value={rxu.get(inthis.state.editingData, ['extra', 'fontSize'], '18')} onChange={(e) => inthis.onBlurData(e, 'linkYt')} className='fullwidth-input' />
            //         </div>
            //       </div>
            //       <div>
            //         <span>Position: </span>
            //         <div style={{ width: '100%' }}>
            //           <input tabIndex='1' placeholder='defaul: bottom' type='text' value={rxu.get(inthis.state.editingData, ['extra', 'position'], 'bottom')} onChange={(e) => inthis.onBlurData(e, 'linkYt')} className='fullwidth-input' />
            //         </div>
            //       </div>
            //     </div>
            //     <div className='rx-form__item'>
            //       <div>
            //         <span>Speed: </span>
            //         <div style={{ width: '100%' }}>
            //           <input tabIndex='1' placeholder='default: 0.5' type='text' value={rxu.get(inthis.state.editingData, ['extra', 'speed'], '0.5')} onChange={(e) => inthis.onBlurData(e, 'linkYt')} className='fullwidth-input' />
            //         </div>
            //       </div>
            //       <div>
            //         <span>Voice: </span>
            //         <div style={{ width: '100%' }}>
            //           <input tabIndex='1' placeholder='defalt: banmai' type='text' value={rxu.get(inthis.state.editingData, ['extra', 'voice'], 'banmai')} onChange={(e) => inthis.onBlurData(e, 'linkYt')} className='fullwidth-input' />
            //         </div>
            //       </div>
            //     </div>


            //   </div>)
            // },
            // {
            //   type: 'form', name: 'Advance Options', func: (inthis) => (<div>
            //     {vm.isAdmin &&
            //       <div className='rx-form__item'>
            //         <div>
            //           <span>Preview SRT before render: </span>
            //           <div style={{ width: '100%' }}>
            //             <RxToggle value={inthis.state.editingData.srtIsCheck} onToggle={(newValue) => inthis.onBlurDataValue(newValue, 'srtIsCheck')} />
            //           </div>
            //         </div>
            //       </div>
            //     }
            //     {vm.isAdmin &&
            //       <div className='rx-form__item'>
            //         <div>
            //           <span>Status: </span>
            //           <div style={{ width: '100%' }}>
            //             <select className='fullwidth-input' style={{ width: '200px' }} id="dropdown" value={inthis.state.editingData.status} onChange={(e) => inthis.onBlurData(e, 'status')}>
            //               <option value="">Select...</option>
            //               {global.ITEMSTATUS.map((value, key) => <option key={key} value={key}>{value}</option>)}
            //             </select>
            //           </div>
            //           {/* <RxSelectbox options={global.ITEMSTATUSSELECT} results={inthis.state.editingData.status || ''} onChange={(result) => { inthis.onBlurDataValue(result, 'status') }} /> */}
            //         </div>
            //       </div>
            //     }
            //     <div className='rx-form__item'>
            //       <div>
            //         <span>Sub input language: </span>
            //         <div style={{ width: '50%' }}>
            //           <RxSelectbox options={global.LANGUAGE_SUPPORTED} results={rxu.get(inthis.state.playlistSelected, 'inSubLang', '')} onChange={(e) => inthis.onBlurOptions(e, 'inSubLang', 'extra')} />
            //         </div>
            //       </div>
            //     </div>
            //     <div className='rx-form__item'>
            //       <div>
            //         <span>Sub output language: </span>
            //         <div style={{ width: '50%' }}>
            //           <RxSelectbox options={global.LANGUAGE_SUPPORTED} results={rxu.get(inthis.state.playlistSelected, 'outSubLang', '')} onChange={(e) => inthis.onBlurOptions(e, 'outSubLang', 'extra')} />
            //         </div>
            //       </div>
            //     </div>
            //     <div className='rx-form__item'>
            //       <div>
            //         <span>Upload Logo Watermark: </span>
            //         <div style={{ width: '100%' }}>
            //           <RxUpload id='uploadImage' playlistSelected={inthis.state.playlistSelected} callback={(e, file) => inthis.callbackUpload(e, 'logoWatermark', file)} images={inthis.state.editingData.logoWatermark} name={inthis.state.editingData?.logoWatermark} />
            //           {inthis.state.error && Object.keys(inthis.state.error) && Object.keys(inthis.state.error).indexOf('srt') !== -1 && <div className='rxerror'>*The upload process needs to be completed </div>}

            //         </div>
            //       </div>
            //     </div>
            //     {/* <div className='rx-form__item'>
            //       <div>
            //         <span>FontSize: </span>
            //         <div style={{ width: '100%' }}>
            //           <input tabIndex='1' type='text' value={inthis.state.playlistSelected.fontSize || ''} onChange={(e) => inthis.onBlurOptions(e, 'fontSize', 'extra')} />
            //         </div>
            //       </div>
            //     </div> */}

            //     {/* {vm.isAdmin &&
            //       <div className='rx-form__item'>
            //         <div>
            //           <span>Link local SRT: </span>
            //           <div style={{ width: '100%' }}>
            //             <input tabIndex='1' placeholder='https://dubstore.aicola.vn/srt' type='text' value={inthis.state.editingData.linkLocalSrt || ''} onChange={(e) => inthis.onBlurData(e, 'linkLocalSrt')} className='fullwidth-input' />
            //           </div>
            //         </div>
            //       </div>
            //     }
            //     {vm.isAdmin &&

            //       <div className='rx-form__item'>
            //         <div>
            //           <span>Link local File: </span>
            //           <div style={{ width: '100%' }}>
            //             <input tabIndex='1' placeholder='https://dubstore.aicola.vn/srt' type='text' value={inthis.state.editingData.linkLocalFile || ''} onChange={(e) => inthis.onBlurData(e, 'linkLocalFile')} className='fullwidth-input' />
            //           </div>
            //         </div>
            //       </div>
            //     } */}
            //     {/* {vm.isAdmin &&
            //       <div className='rx-form__item'>
            //         <div>
            //           <span>Link output: </span>
            //           <div style={{ width: '100%' }}>
            //             <textarea cols='100' rows='10' tabIndex='1' placeholder='[{name: "hard-dubsub", link: "https://dubstore.aicola.vn/download/video/0LOMJWSGS.mp4"}]' type='text' value={(inthis.state.editingData.linkDownload) || ''} onChange={(e) => inthis.onBlurData(e, 'linkDownload')} className='' />
            //           </div>
            //         </div>
            //       </div>
            //     } */}
            //   </div>)
            // },
            {
              type: 'form', name: 'Logs', func: (inthis) => (<div>
                <div className='rx-form__item'>
                  <div>
                    <span>Show logs action here: </span>
                    <div style={{ width: '100%' }}>
                      <textarea cols='100' rows='100' tabIndex='1' placeholder='' type='text' readOnly
                        value={vm.parseLog(rxu.get(inthis.state.editingData, ['result'], []), 'stdout')}
                      />
                    </div>
                  </div>
                </div>
              </div>)
            },
          ]}
        />
        :
        <RxCrud
          flagsAction={false} searchId={vm.state.searchId} renderTableHead={vm.renderTableHead} renderTableBody={vm.renderTableBody} onClickDataNew={vm.onClickDataNew} name='item' api='api_items' form={[
            { type: 'devide' },
            {
              type: 'form', name: '', keyInput: 'name', func: (inthis) => (<div>
                <div className='rx-form__item'>
                  <div>
                    <span>Choose Playlist: </span>
                    <div style={{ width: '100%' }}>
                      <RxSelectbox options={vm.state.cate} results={inthis.state.editingData.playlist || ''} onChange={(result, item) => { inthis.onBlurDataValue(result, 'playlist', item) }} />
                      {inthis.state.error && Object.keys(inthis.state.error) && Object.keys(inthis.state.error).indexOf('playlist') !== -1 && <div className='rxerror'>* This is a required information </div>}

                    </div>
                  </div>
                </div>
                <div className='rx-form__item'>
                  <div>
                    <span>Choose Folder: </span>
                    <div style={{ width: '100%' }}>
                      <RxSelectbox options={vm.state.folders} results={inthis.state.editingData.folder || ''} onChange={(result, item) => { inthis.onBlurDataValue(result, 'folder', item) }} />
                      {inthis.state.error && Object.keys(inthis.state.error) && Object.keys(inthis.state.error).indexOf('folder') !== -1 && <div className='rxerror'> </div>}

                    </div>
                  </div>
                </div>
                <div className='rx-form__item'>
                  <div>
                    <span>Name: </span>
                    <div style={{ width: '100%' }}>
                      <input tabIndex='1' type='text' value={inthis.state.editingData.name || ''} onChange={(e) => inthis.onBlurData(e, 'name')} className='fullwidth-input' />
                      {inthis.state.error && Object.keys(inthis.state.error) && Object.keys(inthis.state.error).indexOf('name') !== -1 && <div className='rxerror'>* This is a required information </div>}

                    </div>
                  </div>
                </div>

                <div className='rx-form__item'>
                  <div>
                    <span>Episode: </span>
                    <div style={{ width: '100%' }}>
                      <input tabIndex='1' type='text' value={inthis.state.editingData.epi || ''} onChange={(e) => inthis.onBlurData(e, 'epi')} className='fullwidth-input' />
                      {inthis.state.error && Object.keys(inthis.state.error) && Object.keys(inthis.state.error).indexOf('epi') !== -1 && <div className='rxerror'>* This is a required information </div>}

                    </div>
                  </div>
                </div>
                <div className='rx-form__item'>
                  <div>
                    <span>Upload video (*): </span>
                    <div style={{ width: '100%' }}>
                      <RxUpload playlistSelected={inthis.state.playlistSelected} callback={(e, file) => inthis.callbackUpload(e, 'filePath', file)} images={inthis.state.editingData.filePath} name={inthis.state.editingData?.fileName} />
                      {inthis.state.error && Object.keys(inthis.state.error) && Object.keys(inthis.state.error).indexOf('filePath') !== -1 && <div className='rxerror'>*The upload process needs to be completed </div>}

                    </div>
                  </div>
                </div>
                <div className='rx-form__item'>
                  <div>
                    <span>Upload SRT File (*): </span>
                    <div style={{ width: '100%' }}>
                      <RxUpload playlistSelected={inthis.state.playlistSelected} callback={(e, file) => inthis.callbackUpload(e, 'srt', file)} images={inthis.state.editingData.srt} name={inthis.state.editingData?.fileSrtName} />
                      {inthis.state.error && Object.keys(inthis.state.error) && Object.keys(inthis.state.error).indexOf('srt') !== -1 && <div className='rxerror'>*The upload process needs to be completed </div>}

                    </div>
                  </div>
                </div>
              </div>)
            },
            {
              type: 'form', name: 'Logs', func: (inthis) => (<div>
                <div className='rx-form__item'>
                  <div>
                    <span>Show logs action here: </span>
                    <div style={{ width: '100%' }}>
                      <textarea cols='100' rows='100' tabIndex='1' placeholder='' type='text' readOnly
                        value={vm.parseLog(rxu.get(inthis.state.editingData, ['result'], []), 'stdout')}
                      />
                    </div>
                  </div>
                </div>
              </div>)
            },
            {
              type: 'form', name: 'Logs', func: (inthis) => (<div>
                <div className='rx-form__item'>
                  <div>
                    <span>Show logs action here: </span>
                    <div style={{ width: '100%' }}>
                      <textarea cols='100' rows='100' tabIndex='1' placeholder='' type='text' readOnly
                        value={vm.parseLog(rxu.get(inthis.state.editingData, ['resultOld'], []), 'stdout')}
                      />
                    </div>
                  </div>
                </div>
              </div>)
            },
            // { type: 'devide' },
            // {
            //   type: 'form', name: 'Playlist Options ', keyInput: 'name', func: (inthis) => (<div>
            //     <div className='rx-form__item'>
            //       <div>
            //         <span>Origin Language: </span>
            //         <div style={{ width: '50%' }}>
            //           <RxSelectbox options={global.LANGUAGES} results={rxu.get(inthis.state.playlistSelected, 'originLang', '')} onChange={(result) => { inthis.onBlurOptions(result, 'originLang', 'extra') }} />
            //         </div>
            //       </div>
            //       <div>
            //         <span>Target Language: </span>
            //         <div style={{ width: '50%' }}>
            //           <RxSelectbox options={global.LANGUAGES} results={rxu.get(inthis.state.playlistSelected, 'targetLang', '')} onChange={(e) => inthis.onBlurOptions(e, 'targetLang', 'extra')} />
            //         </div>
            //       </div>
            //     </div>
            //     <div className='rx-form__item'>
            //       <div>
            //         <span>Type Sub/dub: </span>
            //         <div style={{ width: '50%' }}>
            //           <RxSelectbox options={global.TYPES} results={rxu.get(inthis.state.playlistSelected, 'type', '')} onChange={(e) => inthis.onBlurOptions(e, 'type', 'extra')} />
            //         </div>
            //       </div>
            //       <div>
            //         <span>Hardsub / softsub: </span>
            //         <div style={{ width: '50%' }}>
            //           <RxSelectbox options={global.SUBTYPES} results={rxu.get(inthis.state.playlistSelected, 'isHardsub', '')} onChange={(e) => inthis.onBlurOptions(e, 'isHardsub', 'extra')} />
            //         </div>
            //       </div>
            //     </div>

            //   </div>)
            // },
          ]}
        />
      }
    </div>
  </AdminBlock>
