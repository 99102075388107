/* global FormData, fetch */
import React, { Component } from 'react'
import axios from 'axios';
import ProgressBar from "./rxProgressBar";

global.isclient && require('./rxUpload.css')
const { apiget, rxgetLocal, rxJson } = global.rootRequire('classes/request')

class RxUpload extends Component {
  constructor(props) {
    super(props)

    let user = rxgetLocal('rxuser')
    if (user) {
      user = rxJson(user)
      this.userID = global.rxu.get(user, ['user', '_id'], 'defaultID')
      this.isAdmin = global.rxu.get(user, ['user', 'admin'], 0)
    } else {
      this.isAdmin = 0
    }

    this.state = {
      images: props.images || [],
      name: props.name || '',
      callback: props.callback,
      single: props.single || 1,
      playlistSelected: props.playlistSelected || { slug: 'playlistSelected' },
      filename: props.filename || 'default_filename',
      progress: 0,
      isProgress: false
    }

  }

  componentDidMount() {
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps && nextProps.playlistSelected !== nextState.playlistSelected) {
      this.setState({ playlistSelected: nextProps.playlistSelected })
    }
  }

  onClickDataUpload(e) {
    this.refs.data_image.click()
  }

  onBlurData(json, file) {
    this.setState({ images: json.data }, () => {
      if (typeof this.state.callback === 'function') {
        this.state.callback(this.state, file)
      }
    })
  }

  // Array
  onClickDataUploadArr(e, index) {
    this.refs['data_image_' + index].click()
  }

  onChangeDataImageFileArr(e, index) {
    e.stopPropagation()
    e.preventDefault()

    if (typeof (e.target.files[0]) !== 'undefined') {
      const file = e.target.files[0]
      const data = new FormData()
      data.append('uploadFile', file, file.name)
      fetch(global.rxu.config.base_api + '/upload', { method: 'POST', body: data }).then(res => res.json()).then((json) => {
        if (json.success === 1) {
          this.onBlurDataArr(json, index)
        }
      })
    }
  }

  onBlurDataArr(json, index) {
    const tempImages = this.state.images
    tempImages[index] = json.data
    this.setState({ images: tempImages }, () => {
      if (typeof this.state.callback === 'function') {
        this.state.callback(this.state)
      }
    })
  }

  onClickImgRemove(e, index) {
    const tempImages = this.state.images
    tempImages.splice(index, 1)
    this.setState({ images: tempImages }, () => {
      if (typeof this.state.callback === 'function') {
        this.state.callback(this.state)
      }
    })
  }

  rxChangeSlug(x, isNotNull = false) {
    let str = x
    if (typeof (x) !== 'undefined') {
      str = str.toLowerCase()
      str = str.replace(/[`~!@#$%^&*()_|+=?;:'",<>{}[\]\\/]/gi, '')
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
      str = str.replace(/đ/g, 'd')
      str = str.replace(/ + /g, ' ')
      str = str.replace(/ /g, '-')
      str = str.trim()
    }
    return isNotNull ? (str || x) : str
  }


  onChangeDataImageFile(e) {
    e.stopPropagation()
    e.preventDefault()

    // const match = document.cookie.match(new RegExp('authorize=([^;]+)'))
    // let authorization = ''
    // if (match) {
    //   authorization = match[1]
    // }

    if (typeof (e.target.files[0]) !== 'undefined') {
      const file = e.target.files[0]
      const formData = new FormData();
      formData.append('file', file);
      let fileName = this.rxChangeSlug(file.name)
      // console.log(rxu.strToSlug(fileName))

      try {
        apiget(global.rxu.config.base_api + '/upload/presignedUrl', {
          playlist: this.state.playlistSelected.slug || 'images',
          filename: `${this.userID}/input/${fileName}`,
        }, {
          1: async (json) => {
            if (json && json.data) {
              try {
                let dataUpload = new FormData()
                let arrKeys = ['Content-Type', 'key', 'policy', 'x-amz-algorithm', 'x-amz-credential', 'x-amz-date', 'x-amz-meta-netachat', 'x-amz-signature']
                for (let key of arrKeys) {
                  if (json.data.formData[key]) {
                    dataUpload.append(key, json.data.formData[key])
                  }
                }
                dataUpload.append('file', file, file.name)
                axios.post(json.data.postURL, dataUpload, {
                  onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100;
                    this.setState({ progress: progress, isProgress: true })
                    if (progress && progress === 100) {
                      console.log(json.data)
                      this.onBlurData(json, file)
                    }
                  },
                })
              } catch (error) {
                console.log(error)
              }
            }
          }
        })
      } catch (error) {
        console.error('Error uploading the video:', error);
      }


    }
  }

  render() {
    return (
      <div className='clearfix'>
        <div id="drop-area">
          <p>Click here to select a file</p>
          <label className='selectfile' htmlFor={`fileInput_${this.props.id}`} id={`fileInputLabel_${this.props.id}`}>Select File</label>
          <input
            id={`fileInput_${this.props.id}`}
            type="file"
            style={{ display: 'none' }}
            className="rxinput_upload hidden"
            onChange={(e) => this.onChangeDataImageFile(e)}
            name="file" accept="audio/*,video/*,.vtt,.srt, image/png"
          />
          <div id="file-list" className="active">
            {this.state.isProgress && <ProgressBar bgcolor={'#1990ff'} completed={this.state.progress} />}
            {this.state.name && this.props.id === 'uploadImage'
              ? <img className='admin-product-img-upload' alt='ico_default' src={this.state.name} />
              : this.state.name && <div className='rxFileName'>File Name: <span>{this.state.name}</span></div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default RxUpload
