import React, { useState, useEffect, createRef, useCallback, useMemo, memo } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Translate } from 'react-i18nify';
import styled from 'styled-components';
import backlight from '../libs/backlight';
import { isPlaying } from '../utils';
import { t } from 'react-i18nify';

import { file2sub, sub2vtt, sub2srt, sub2txt } from '../libs/readSub';
import sub2ass from '../libs/readSub/sub2ass';

import languages from '../libs/languages';
import googleTranslate from '../libs/googleTranslate';

const Style = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #000;
    overflow: hidden;
    position: relative;

    .video_tool {
        position: absolute;
        width: 100%;
        height: 150px;
        top: 0px;
        padding: 5px;

        span {
            background-color: #2196F3;
            color: #fff;
            font-size: 12px;
            padding: 3px 5px;
            margin: 5px;
            cursor: pointer;
        }

        .translate {
            margin-left: 5px;
        }
    }

    .video {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: auto;
        position: relative;

        video {
            position: relative;
            z-index: 10;
            outline: none;
            max-height: 100%;
            max-width: 100%;
            background-color: #000;
            cursor: pointer;
        }

        .subtitle {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 20;
            left: 0;
            right: 0;
            bottom: 5%;
            width: 100%;
            padding: 0 20px;
            user-select: none;
            pointer-events: none;

            .operate {
                padding: 5px 15px;
                color: #fff;
                font-size: 13px;
                border-radius: 3px;
                margin-bottom: 5px;
                background-color: rgb(0 0 0 / 75%);
                border: 1px solid rgb(255 255 255 / 20%);
                cursor: pointer;
                pointer-events: all;
            }

            .textarea {
                width: 100%;
                outline: none;
                resize: none;
                text-align: center;
                line-height: 1.2;
                border: none;
                color: #fff;
                font-size: 20px;
                padding: 5px 10px;
                user-select: all;
                pointer-events: all;
                background-color: rgb(0 0 0 / 0);
                text-shadow: rgb(0 0 0) 1px 0px 1px, rgb(0 0 0) 0px 1px 1px, rgb(0 0 0) -1px 0px 1px,
                    rgb(0 0 0) 0px -1px 1px;

                &.pause {
                    background-color: rgb(0 0 0 / 50%);
                }
            }
        }
    }
`;

const VideoWrap = memo(
    ({ setPlayer, setCurrentTime, setPlaying, inputVideo }) => {
        const $video = createRef();

        useEffect(() => {
            setPlayer($video.current);

            (function loop() {
                window.requestAnimationFrame(() => {
                    if ($video.current) {
                        setPlaying(isPlaying($video.current));
                        setCurrentTime($video.current.currentTime || 0);
                    }
                    loop();
                });
            })();
        }, [setPlayer, setCurrentTime, setPlaying, $video]);

        const onClick = useCallback(() => {
            if ($video.current) {
                if (isPlaying($video.current)) {
                    $video.current.pause();
                } else {
                    $video.current.play();
                }
            }
        }, [$video]);

        return <video onClick={onClick} src={inputVideo} ref={$video} crossOrigin="anonymous" referrerPolicy="referrer" />;
    },
    () => true,
);

export default function Player(props) {
    const [translate, setTranslate] = useState('vi');
    const [currentSub, setCurrentSub] = useState(null);
    const [focusing, setFocusing] = useState(false);
    const [inputItemCursor, setInputItemCursor] = useState(0);
    const $player = createRef();

    useEffect(() => {
        if ($player.current && props.player && !backlight.state) {
            backlight.state = true;
            backlight($player.current, props.player);
        }
    }, [$player, props.player]);

    useMemo(() => {
        setCurrentSub(props.subtitle[props.currentIndex]);
    }, [props.subtitle, props.currentIndex]);

    const onChange = useCallback(
        (event) => {
            props.player.pause();
            props.updateSub(currentSub, { text: event.target.value });
            if (event.target.selectionStart) {
                setInputItemCursor(event.target.selectionStart);
            }
        },
        [props, currentSub],
    );

    const onClick = useCallback(
        (event) => {
            props.player.pause();
            if (event.target.selectionStart) {
                setInputItemCursor(event.target.selectionStart);
            }
        },
        [props],
    );

    const onFocus = useCallback((event) => {
        setFocusing(true);
        if (event.target.selectionStart) {
            setInputItemCursor(event.target.selectionStart);
        }
    }, []);

    const onBlur = useCallback(() => {
        setTimeout(() => setFocusing(false), 500);
    }, []);

    const onSplit = useCallback(() => {
        props.splitSub(currentSub, inputItemCursor);
    }, [props, currentSub, inputItemCursor]);

    const onSaveSubtitle = () => {
        let text = '';
        switch (props.onSaveFormat) {
            case 'vtt':
                text = sub2vtt(props.subtitle);
                break;
            case 'srt':
                text = sub2srt(props.subtitle);
                break;
            case 'ass':
                text = sub2ass(props.subtitle);
                break;
            case 'txt':
                text = sub2txt(props.subtitle);
                break;
            case 'json':
                text = JSON.stringify(props.subtitle);
                break;
            default:
                text = JSON.stringify(props.subtitle);
                break;
        }

        props.onSave(text)
    }

    const onTranslate = useCallback(() => {
        props.setLoading(t('TRANSLATING'));
        googleTranslate(props.formatSub(props.subtitle), translate)
            .then((res) => {
                props.setLoading('');
                props.setSubtitle(props.formatSub(res));
                props.notify({
                    message: t('TRANSLAT_SUCCESS'),
                    level: 'success',
                });
            })
            .catch((err) => {
                props.setLoading('');
                props.notify({
                    message: err.message,
                    level: 'error',
                });
            });
    });

    const onDownload = () => {
        alert('Waiting process complete!')
        props.onDownload()
    }

    return (
        <Style className="player">
            <div className="video" ref={$player}>
                <VideoWrap {...props} />
                {props.player && currentSub ? (
                    <div className="subtitle">
                        {focusing ? (
                            <div className="operate" onClick={onSplit}>
                                <Translate value="SPLIT" />
                            </div>
                        ) : null}
                        <TextareaAutosize
                            className={`textarea ${!props.playing ? 'pause' : ''}`}
                            value={currentSub.text}
                            onChange={onChange}
                            onClick={onClick}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onKeyDown={onFocus}
                            spellCheck={false}
                        />
                    </div>
                ) : null}
            </div>
            <div className='video_tool'>
                <div className="translate">
                    <select value={translate} onChange={(event) => setTranslate(event.target.value)}>
                        {(languages[props.language] || languages.en).map((item) => (
                            <option key={item.key} value={item.key}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                    <span className="btn" onClick={onTranslate}>
                        <Translate value="TRANSLATE" />
                    </span>
                </div>

                <span onClick={() => onSaveSubtitle()}>Save subtitles</span>

                {/* <span onClick={() => onDownload()}>Download</span> */}

            </div>
        </Style>
    );
}
