/* global CustomEvent */
// import * as ramda from 'ramda', // global.rxi = ramda
import jQuery from 'jquery'
import sweetAlert from 'sweetalert2'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

let chartist = {}
if (typeof window !== 'undefined') { chartist = require('chartist') }

const rxu = require('./classes/ulti').default
const translate = require('./classes/lang').default

if (!global.defined) {
  const secret = rxu.secret
  const theme = secret.theme || 'default'

  global.defined = true
  global.rxu = rxu
  global.currentTheme = theme
  global.$ = jQuery
  global.t = translate
  global.sweetAlert = sweetAlert
  global.Chart = Chart

  global.TYPES = [{ name: 'Sub', _id: 'sub' }, { name: 'Dub', _id: 'dub' }, { name: 'Dub + Sub', _id: 'subdub' }]
  global.SUBTYPES = [{ name: 'Soft sub', _id: 'softsub' }, { name: 'Hard sub', _id: 'hardsub' }]
  global.SRTSTATUS = ['Checked', 'To do check']
  global.ITEMSTATUS = ['To do', 'Processing', 'Done', 'Error', 'ReviewSrt', 'ReviewSrtDone', 'DoneSoft']
  global.ITEMSTATUSSELECT = [{ name: 'To do', _id: '0' }, { name: 'Progress', _id: '1' }, { name: 'Done', _id: '2' }, { name: 'Error', _id: '3' }, { name: 'Need check SRT', _id: '4' }]

  global.isclient = typeof window !== 'undefined'
  global.rootbase = __dirname
  global.nodebase = global.rootbase + '../node_modules/'
  global.rootRequire = function (name) {
    // eslint-disable-next-line
    return require(__dirname + '/' + name)
  }

  global.rootRequiretheme = function (name) {
    // eslint-disable-next-line
    return require(__dirname + '/themes/' + theme + '/' + name)
  }

  // global ulti
  global.Chart.defaults.LineWithLine = global.Chart.defaults.line
  global.Chart.controllers.LineWithLine = global.Chart.controllers.line.extend({
    draw: function (ease) {
      global.Chart.controllers.line.prototype.draw.call(this, ease)

      if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
        var activePoint = this.chart.tooltip._active[0]
        var ctx = this.chart.ctx
        var x = activePoint.tooltipPosition().x
        var topY = this.chart.legend.bottom
        var bottomY = this.chart.chartArea.bottom

        ctx.save()
        ctx.beginPath()
        ctx.moveTo(x, topY)
        ctx.lineTo(x, bottomY)
        ctx.lineWidth = 1
        ctx.strokeStyle = '#f62459'
        ctx.stroke()
        ctx.restore()
      }
    }
  })
  global.Chart.plugins.unregister(ChartDataLabels)
  global.Chart.Legend.prototype.afterFit = function () { this.height = this.height + 20 }
  global.Chart.helpers.merge(global.Chart.defaults.global, { layout: { padding: { top: 32 } } })
  global.Chart.helpers.merge(global.Chart.defaults.global.plugins.datalabels, { align: 'end', anchor: 'end', color: '#000000', font: { size: 14, weight: 'bold' } })
  global.rootContext = function (context) {
    if (typeof window !== 'undefined') {
      context = context || window.__STATIC_CONTEXT__
    }
    return context
  }

  global.JSONToCSVConvertor = function (JSONData, ReportTitle, ShowLabel = true) {
    const arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
    let CSV = ''; CSV += ReportTitle + '\r\n\n'
    if (ShowLabel) { let row = ''; for (const index in arrData[0]) { row += index + ',' }; row = row.slice(0, -1); CSV += row + '\r\n' }
    for (let i = 0; i < arrData.length; i++) { let row = ''; for (const index in arrData[i]) { row += '"' + arrData[i][index] + '",' }; row.slice(0, row.length - 1); CSV += row + '\r\n' } if (CSV === '') { return }
    let fileName = 'Report_'; fileName += ReportTitle.replace(/ /g, '_'); const uri = 'data:text/csv;charset=utf-8,' + escape(CSV); const link = document.createElement('a')
    link.href = uri; link.style = 'visibility:hidden'; link.download = fileName + '.csv'
    document.body.appendChild(link); link.click(); document.body.removeChild(link)
  }

  // Jquery addon and jquery global stuff
  const $ = global.$
  global.scrolltop = function () {
    $('html, body').animate({ scrollTop: 0 }, 'fast')
  }
  global.scrollfire = function () {
    window.dispatchEvent(new CustomEvent('scroll'))
  }

  // new chartist.Line('#chart1', { labels: [1, 2, 3, 4], series: [[100, 120, 180, 200]] })
  global.chart = chartist
  global.chartist = function (ele, options, options1, options2) {
    return new chartist.Line(ele, options, options1, options2)
  }

  global.chartistBar = function (ele, options, options1, options2) {
    return new chartist.Bar(ele, options, options1, options2)
  }

  global.chartistPie = function (ele, options, options1, options2) {
    return new chartist.Pie(ele, options, options1, options2)
  }

  global.global_init = global.global_init || 'notyet'
  if (global.global_init === 'notyet' && typeof window !== 'undefined') {
    // Default worker
    $('img').on('error', function () {
      $(this).attr('src', '/images/missing.png')
    })

    // Sidebar navigator
    setTimeout(() => {
      $('.adnav__itemname.rxactive').parent('.adnav__groupwrap').addClass('rxactive')
      $('.adnav__itemname.rxactive').parent('.adnav__groupwrap').prev('.adnav__groupname').addClass('adnav__groupname--expand')
    }, 1000)

      // Plugin
      ; (function ($) {
        let uniqueCntr = 0
        $.fn.scrolled = function (waitTime, fn) {
          if (typeof waitTime === 'function') {
            fn = waitTime
            waitTime = 20
          }
          var tag = 'scrollTimer' + uniqueCntr++
          this.scroll(function () {
            var self = $(this)
            var timer = self.data(tag)
            if (timer) {
              clearTimeout(timer)
            }
            timer = setTimeout(function () {
              self.removeData(tag)
              fn.call(self[0])
            }, waitTime)
            self.data(tag, timer)
          })
        }
      })($)

    global.onResizeWindow = function () { $('.rxwindowheight').css({ height: $(window).height() + 'px' }) }

    $(document).click(function (event) {
      if ($(event.target).hasClass('adminnav__avatarmenu rxactive') ||
        $(event.target).parents('.adminnav__avatarmenu.rxactive').length > 0) {
      } else {
        $(document).find('.adminnav__avatarmenu.rxactive').removeClass('rxactive')
      }

      if ($(event.target).hasClass('showFilterBtn') ||
        $(event.target).hasClass('showFilter rxactive') ||
        $(event.target).parents('.showFilter.rxactive').length > 0) {
      } else {
        $(document).find('.showFilter.rxactive').removeClass('rxactive')
      }
      if ($(event.target).hasClass('noteForGameBtn') ||
        $(event.target).hasClass('noteForGame rxactive') ||
        $(event.target).parents('.noteForGame.rxactive').length > 0) {
      } else {
        $(document).find('.noteForGame.rxactive').removeClass('rxactive')
      }
    })

    $(document).on('click', '.showFilterBtn', function () {
      $('.showFilter').toggleClass('rxactive')
    })

    $(document).on('click', '.noteForGameBtn', function () {
      $('.noteForGame').toggleClass('rxactive')
    })

    $(window).on('load', global.onResizeWindow)
    $(window).resize(global.onResizeWindow)
    $(window).scrolled(function () {
      // Stick top
      const sticky = $('.rx-sticky')
      const scroll = $(window).scrollTop()
      if (scroll >= 220) {
        $('.rx-sticky').each(function (i) {
          const ele = $(this)
          ele.addClass('fixed')
          ele.outerWidth(ele.parent().width())
        })
      } else {
        sticky.removeClass('fixed')
        sticky.outerWidth('')
      }

      // Stick bot
      $('.rx-stickybot').each(function (i) {
        const ele = $(this)
        ele.addClass('fixedbot')
        ele.outerWidth(ele.parent().width())
      })

      if (typeof sticky.offset() !== 'undefined') {
        const stickyBottom = sticky.offset().top + sticky.outerHeight(true) + 100
        sticky.find('.product-content-nav-item').each(function () {
          const ele = $(this)
          const theClass = ele.attr('class').match(/rxid[\w-]*\b/)[0]

          // Check what section currently
          const block = $('#' + theClass.replace('rxid', ''))
          if (typeof block.offset() !== 'undefined' && stickyBottom <= block.offset().top + block.outerHeight(true) && stickyBottom >= block.offset().top) {
            $('.product-content-nav-item').removeClass('rx-active')
            $('.' + theClass).addClass('rx-active')
          }
        })
      }
    })
  }

  global.LANGUAGES = [
    {
      "_id": "af",
      "name": "Afrikaans"
    },
    {
      "_id": "af-ZA",
      "name": "Afrikaans (South Africa)"
    },
    {
      "_id": "ar",
      "name": "Arabic"
    },
    {
      "_id": "ar-AE",
      "name": "Arabic (U.A.E.)"
    },
    {
      "_id": "ar-BH",
      "name": "Arabic (Bahrain)"
    },
    {
      "_id": "ar-DZ",
      "name": "Arabic (Algeria)"
    },
    {
      "_id": "ar-EG",
      "name": "Arabic (Egypt)"
    },
    {
      "_id": "ar-IQ",
      "name": "Arabic (Iraq)"
    },
    {
      "_id": "ar-JO",
      "name": "Arabic (Jordan)"
    },
    {
      "_id": "ar-KW",
      "name": "Arabic (Kuwait)"
    },
    {
      "_id": "ar-LB",
      "name": "Arabic (Lebanon)"
    },
    {
      "_id": "ar-LY",
      "name": "Arabic (Libya)"
    },
    {
      "_id": "ar-MA",
      "name": "Arabic (Morocco)"
    },
    {
      "_id": "ar-OM",
      "name": "Arabic (Oman)"
    },
    {
      "_id": "ar-QA",
      "name": "Arabic (Qatar)"
    },
    {
      "_id": "ar-SA",
      "name": "Arabic (Saudi Arabia)"
    },
    {
      "_id": "ar-SY",
      "name": "Arabic (Syria)"
    },
    {
      "_id": "ar-TN",
      "name": "Arabic (Tunisia)"
    },
    {
      "_id": "ar-YE",
      "name": "Arabic (Yemen)"
    },
    {
      "_id": "az",
      "name": "Azeri (Latin)"
    },
    {
      "_id": "az-AZ",
      "name": "Azeri (Latin) (Azerbaijan)"
    },
    {
      "_id": "az-AZ",
      "name": "Azeri (Cyrillic) (Azerbaijan)"
    },
    {
      "_id": "be",
      "name": "Belarusian"
    },
    {
      "_id": "be-BY",
      "name": "Belarusian (Belarus)"
    },
    {
      "_id": "bg",
      "name": "Bulgarian"
    },
    {
      "_id": "bg-BG",
      "name": "Bulgarian (Bulgaria)"
    },
    {
      "_id": "bs-BA",
      "name": "Bosnian (Bosnia and Herzegovina)"
    },
    {
      "_id": "ca",
      "name": "Catalan"
    },
    {
      "_id": "ca-ES",
      "name": "Catalan (Spain)"
    },
    {
      "_id": "cs",
      "name": "Czech"
    },
    {
      "_id": "cs-CZ",
      "name": "Czech (Czech Republic)"
    },
    {
      "_id": "cy",
      "name": "Welsh"
    },
    {
      "_id": "cy-GB",
      "name": "Welsh (United Kingdom)"
    },
    {
      "_id": "da",
      "name": "Danish"
    },
    {
      "_id": "da-DK",
      "name": "Danish (Denmark)"
    },
    {
      "_id": "de",
      "name": "German"
    },
    {
      "_id": "de-AT",
      "name": "German (Austria)"
    },
    {
      "_id": "de-CH",
      "name": "German (Switzerland)"
    },
    {
      "_id": "de-DE",
      "name": "German (Germany)"
    },
    {
      "_id": "de-LI",
      "name": "German (Liechtenstein)"
    },
    {
      "_id": "de-LU",
      "name": "German (Luxembourg)"
    },
    {
      "_id": "dv",
      "name": "Divehi"
    },
    {
      "_id": "dv-MV",
      "name": "Divehi (Maldives)"
    },
    {
      "_id": "el",
      "name": "Greek"
    },
    {
      "_id": "el-GR",
      "name": "Greek (Greece)"
    },
    {
      "_id": "en",
      "name": "English"
    },
    {
      "_id": "en-AU",
      "name": "English (Australia)"
    },
    {
      "_id": "en-BZ",
      "name": "English (Belize)"
    },
    {
      "_id": "en-CA",
      "name": "English (Canada)"
    },
    {
      "_id": "en-CB",
      "name": "English (Caribbean)"
    },
    {
      "_id": "en-GB",
      "name": "English (United Kingdom)"
    },
    {
      "_id": "en-IE",
      "name": "English (Ireland)"
    },
    {
      "_id": "en-JM",
      "name": "English (Jamaica)"
    },
    {
      "_id": "en-NZ",
      "name": "English (New Zealand)"
    },
    {
      "_id": "en-PH",
      "name": "English (Republic of the Philippines)"
    },
    {
      "_id": "en-TT",
      "name": "English (Trinidad and Tobago)"
    },
    {
      "_id": "en-US",
      "name": "English (United States)"
    },
    {
      "_id": "en-ZA",
      "name": "English (South Africa)"
    },
    {
      "_id": "en-ZW",
      "name": "English (Zimbabwe)"
    },
    {
      "_id": "eo",
      "name": "Esperanto"
    },
    {
      "_id": "es",
      "name": "Spanish"
    },
    {
      "_id": "es-AR",
      "name": "Spanish (Argentina)"
    },
    {
      "_id": "es-BO",
      "name": "Spanish (Bolivia)"
    },
    {
      "_id": "es-CL",
      "name": "Spanish (Chile)"
    },
    {
      "_id": "es-CO",
      "name": "Spanish (Colombia)"
    },
    {
      "_id": "es-CR",
      "name": "Spanish (Costa Rica)"
    },
    {
      "_id": "es-DO",
      "name": "Spanish (Dominican Republic)"
    },
    {
      "_id": "es-EC",
      "name": "Spanish (Ecuador)"
    },
    {
      "_id": "es-ES",
      "name": "Spanish (Castilian)"
    },
    {
      "_id": "es-ES",
      "name": "Spanish (Spain)"
    },
    {
      "_id": "es-GT",
      "name": "Spanish (Guatemala)"
    },
    {
      "_id": "es-HN",
      "name": "Spanish (Honduras)"
    },
    {
      "_id": "es-MX",
      "name": "Spanish (Mexico)"
    },
    {
      "_id": "es-NI",
      "name": "Spanish (Nicaragua)"
    },
    {
      "_id": "es-PA",
      "name": "Spanish (Panama)"
    },
    {
      "_id": "es-PE",
      "name": "Spanish (Peru)"
    },
    {
      "_id": "es-PR",
      "name": "Spanish (Puerto Rico)"
    },
    {
      "_id": "es-PY",
      "name": "Spanish (Paraguay)"
    },
    {
      "_id": "es-SV",
      "name": "Spanish (El Salvador)"
    },
    {
      "_id": "es-UY",
      "name": "Spanish (Uruguay)"
    },
    {
      "_id": "es-VE",
      "name": "Spanish (Venezuela)"
    },
    {
      "_id": "et",
      "name": "Estonian"
    },
    {
      "_id": "et-EE",
      "name": "Estonian (Estonia)"
    },
    {
      "_id": "eu",
      "name": "Basque"
    },
    {
      "_id": "eu-ES",
      "name": "Basque (Spain)"
    },
    {
      "_id": "fa",
      "name": "Farsi"
    },
    {
      "_id": "fa-IR",
      "name": "Farsi (Iran)"
    },
    {
      "_id": "fi",
      "name": "Finnish"
    },
    {
      "_id": "fi-FI",
      "name": "Finnish (Finland)"
    },
    {
      "_id": "fo",
      "name": "Faroese"
    },
    {
      "_id": "fo-FO",
      "name": "Faroese (Faroe Islands)"
    },
    {
      "_id": "fr",
      "name": "French"
    },
    {
      "_id": "fr-BE",
      "name": "French (Belgium)"
    },
    {
      "_id": "fr-CA",
      "name": "French (Canada)"
    },
    {
      "_id": "fr-CH",
      "name": "French (Switzerland)"
    },
    {
      "_id": "fr-FR",
      "name": "French (France)"
    },
    {
      "_id": "fr-LU",
      "name": "French (Luxembourg)"
    },
    {
      "_id": "fr-MC",
      "name": "French (Principality of Monaco)"
    },
    {
      "_id": "gl",
      "name": "Galician"
    },
    {
      "_id": "gl-ES",
      "name": "Galician (Spain)"
    },
    {
      "_id": "gu",
      "name": "Gujarati"
    },
    {
      "_id": "gu-IN",
      "name": "Gujarati (India)"
    },
    {
      "_id": "he",
      "name": "Hebrew"
    },
    {
      "_id": "he-IL",
      "name": "Hebrew (Israel)"
    },
    {
      "_id": "hi",
      "name": "Hindi"
    },
    {
      "_id": "hi-IN",
      "name": "Hindi (India)"
    },
    {
      "_id": "hr",
      "name": "Croatian"
    },
    {
      "_id": "hr-BA",
      "name": "Croatian (Bosnia and Herzegovina)"
    },
    {
      "_id": "hr-HR",
      "name": "Croatian (Croatia)"
    },
    {
      "_id": "hu",
      "name": "Hungarian"
    },
    {
      "_id": "hu-HU",
      "name": "Hungarian (Hungary)"
    },
    {
      "_id": "hy",
      "name": "Armenian"
    },
    {
      "_id": "hy-AM",
      "name": "Armenian (Armenia)"
    },
    {
      "_id": "id",
      "name": "Indonesian"
    },
    {
      "_id": "id-ID",
      "name": "Indonesian (Indonesia)"
    },
    {
      "_id": "is",
      "name": "Icelandic"
    },
    {
      "_id": "is-IS",
      "name": "Icelandic (Iceland)"
    },
    {
      "_id": "it",
      "name": "Italian"
    },
    {
      "_id": "it-CH",
      "name": "Italian (Switzerland)"
    },
    {
      "_id": "it-IT",
      "name": "Italian (Italy)"
    },
    {
      "_id": "ja",
      "name": "Japanese"
    },
    {
      "_id": "ja-JP",
      "name": "Japanese (Japan)"
    },
    {
      "_id": "ka",
      "name": "Georgian"
    },
    {
      "_id": "ka-GE",
      "name": "Georgian (Georgia)"
    },
    {
      "_id": "kk",
      "name": "Kazakh"
    },
    {
      "_id": "kk-KZ",
      "name": "Kazakh (Kazakhstan)"
    },
    {
      "_id": "kn",
      "name": "Kannada"
    },
    {
      "_id": "kn-IN",
      "name": "Kannada (India)"
    },
    {
      "_id": "ko",
      "name": "Korean"
    },
    {
      "_id": "ko-KR",
      "name": "Korean (Korea)"
    },
    {
      "_id": "kok",
      "name": "Konkani"
    },
    {
      "_id": "kok-IN",
      "name": "Konkani (India)"
    },
    {
      "_id": "ky",
      "name": "Kyrgyz"
    },
    {
      "_id": "ky-KG",
      "name": "Kyrgyz (Kyrgyzstan)"
    },
    {
      "_id": "lt",
      "name": "Lithuanian"
    },
    {
      "_id": "lt-LT",
      "name": "Lithuanian (Lithuania)"
    },
    {
      "_id": "lv",
      "name": "Latvian"
    },
    {
      "_id": "lv-LV",
      "name": "Latvian (Latvia)"
    },
    {
      "_id": "mi",
      "name": "Maori"
    },
    {
      "_id": "mi-NZ",
      "name": "Maori (New Zealand)"
    },
    {
      "_id": "mk",
      "name": "FYRO Macedonian"
    },
    {
      "_id": "mk-MK",
      "name": "FYRO Macedonian (Former Yugoslav Republic of Macedonia)"
    },
    {
      "_id": "mn",
      "name": "Mongolian"
    },
    {
      "_id": "mn-MN",
      "name": "Mongolian (Mongolia)"
    },
    {
      "_id": "mr",
      "name": "Marathi"
    },
    {
      "_id": "mr-IN",
      "name": "Marathi (India)"
    },
    {
      "_id": "ms",
      "name": "Malay"
    },
    {
      "_id": "ms-BN",
      "name": "Malay (Brunei Darussalam)"
    },
    {
      "_id": "ms-MY",
      "name": "Malay (Malaysia)"
    },
    {
      "_id": "mt",
      "name": "Maltese"
    },
    {
      "_id": "mt-MT",
      "name": "Maltese (Malta)"
    },
    {
      "_id": "nb",
      "name": "Norwegian (Bokm?l)"
    },
    {
      "_id": "nb-NO",
      "name": "Norwegian (Bokm?l) (Norway)"
    },
    {
      "_id": "nl",
      "name": "Dutch"
    },
    {
      "_id": "nl-BE",
      "name": "Dutch (Belgium)"
    },
    {
      "_id": "nl-NL",
      "name": "Dutch (Netherlands)"
    },
    {
      "_id": "nn-NO",
      "name": "Norwegian (Nynorsk) (Norway)"
    },
    {
      "_id": "ns",
      "name": "Northern Sotho"
    },
    {
      "_id": "ns-ZA",
      "name": "Northern Sotho (South Africa)"
    },
    {
      "_id": "pa",
      "name": "Punjabi"
    },
    {
      "_id": "pa-IN",
      "name": "Punjabi (India)"
    },
    {
      "_id": "pl",
      "name": "Polish"
    },
    {
      "_id": "pl-PL",
      "name": "Polish (Poland)"
    },
    {
      "_id": "ps",
      "name": "Pashto"
    },
    {
      "_id": "ps-AR",
      "name": "Pashto (Afghanistan)"
    },
    {
      "_id": "pt",
      "name": "Portuguese"
    },
    {
      "_id": "pt-BR",
      "name": "Portuguese (Brazil)"
    },
    {
      "_id": "pt-PT",
      "name": "Portuguese (Portugal)"
    },
    {
      "_id": "qu",
      "name": "Quechua"
    },
    {
      "_id": "qu-BO",
      "name": "Quechua (Bolivia)"
    },
    {
      "_id": "qu-EC",
      "name": "Quechua (Ecuador)"
    },
    {
      "_id": "qu-PE",
      "name": "Quechua (Peru)"
    },
    {
      "_id": "ro",
      "name": "Romanian"
    },
    {
      "_id": "ro-RO",
      "name": "Romanian (Romania)"
    },
    {
      "_id": "ru",
      "name": "Russian"
    },
    {
      "_id": "ru-RU",
      "name": "Russian (Russia)"
    },
    {
      "_id": "sa",
      "name": "Sanskrit"
    },
    {
      "_id": "sa-IN",
      "name": "Sanskrit (India)"
    },
    {
      "_id": "se",
      "name": "Sami (Northern)"
    },
    {
      "_id": "se-FI",
      "name": "Sami (Northern) (Finland)"
    },
    {
      "_id": "se-FI",
      "name": "Sami (Skolt) (Finland)"
    },
    {
      "_id": "se-FI",
      "name": "Sami (Inari) (Finland)"
    },
    {
      "_id": "se-NO",
      "name": "Sami (Northern) (Norway)"
    },
    {
      "_id": "se-NO",
      "name": "Sami (Lule) (Norway)"
    },
    {
      "_id": "se-NO",
      "name": "Sami (Southern) (Norway)"
    },
    {
      "_id": "se-SE",
      "name": "Sami (Northern) (Sweden)"
    },
    {
      "_id": "se-SE",
      "name": "Sami (Lule) (Sweden)"
    },
    {
      "_id": "se-SE",
      "name": "Sami (Southern) (Sweden)"
    },
    {
      "_id": "sk",
      "name": "Slovak"
    },
    {
      "_id": "sk-SK",
      "name": "Slovak (Slovakia)"
    },
    {
      "_id": "sl",
      "name": "Slovenian"
    },
    {
      "_id": "sl-SI",
      "name": "Slovenian (Slovenia)"
    },
    {
      "_id": "sq",
      "name": "Albanian"
    },
    {
      "_id": "sq-AL",
      "name": "Albanian (Albania)"
    },
    {
      "_id": "sr-BA",
      "name": "Serbian (Latin) (Bosnia and Herzegovina)"
    },
    {
      "_id": "sr-BA",
      "name": "Serbian (Cyrillic) (Bosnia and Herzegovina)"
    },
    {
      "_id": "sr-SP",
      "name": "Serbian (Latin) (Serbia and Montenegro)"
    },
    {
      "_id": "sr-SP",
      "name": "Serbian (Cyrillic) (Serbia and Montenegro)"
    },
    {
      "_id": "sv",
      "name": "Swedish"
    },
    {
      "_id": "sv-FI",
      "name": "Swedish (Finland)"
    },
    {
      "_id": "sv-SE",
      "name": "Swedish (Sweden)"
    },
    {
      "_id": "sw",
      "name": "Swahili"
    },
    {
      "_id": "sw-KE",
      "name": "Swahili (Kenya)"
    },
    {
      "_id": "syr",
      "name": "Syriac"
    },
    {
      "_id": "syr-SY",
      "name": "Syriac (Syria)"
    },
    {
      "_id": "ta",
      "name": "Tamil"
    },
    {
      "_id": "ta-IN",
      "name": "Tamil (India)"
    },
    {
      "_id": "te",
      "name": "Telugu"
    },
    {
      "_id": "te-IN",
      "name": "Telugu (India)"
    },
    {
      "_id": "th",
      "name": "Thai"
    },
    {
      "_id": "th-TH",
      "name": "Thai (Thailand)"
    },
    {
      "_id": "tl",
      "name": "Tagalog"
    },
    {
      "_id": "tl-PH",
      "name": "Tagalog (Philippines)"
    },
    {
      "_id": "tn",
      "name": "Tswana"
    },
    {
      "_id": "tn-ZA",
      "name": "Tswana (South Africa)"
    },
    {
      "_id": "tr",
      "name": "Turkish"
    },
    {
      "_id": "tr-TR",
      "name": "Turkish (Turkey)"
    },
    {
      "_id": "tt",
      "name": "Tatar"
    },
    {
      "_id": "tt-RU",
      "name": "Tatar (Russia)"
    },
    {
      "_id": "ts",
      "name": "Tsonga"
    },
    {
      "_id": "uk",
      "name": "Ukrainian"
    },
    {
      "_id": "uk-UA",
      "name": "Ukrainian (Ukraine)"
    },
    {
      "_id": "ur",
      "name": "Urdu"
    },
    {
      "_id": "ur-PK",
      "name": "Urdu (Islamic Republic of Pakistan)"
    },
    {
      "_id": "uz",
      "name": "Uzbek (Latin)"
    },
    {
      "_id": "uz-UZ",
      "name": "Uzbek (Latin) (Uzbekistan)"
    },
    {
      "_id": "uz-UZ",
      "name": "Uzbek (Cyrillic) (Uzbekistan)"
    },
    {
      "_id": "vi-VN",
      "name": "Vietnamese (Viet Nam)"
    },
    {
      "_id": "xh",
      "name": "Xhosa"
    },
    {
      "_id": "xh-ZA",
      "name": "Xhosa (South Africa)"
    },
    {
      "_id": "zh",
      "name": "Chinese"
    },
    {
      "_id": "zh-CN",
      "name": "Chinese (S)"
    },
    {
      "_id": "zh-HK",
      "name": "Chinese (Hong Kong)"
    },
    {
      "_id": "zh-MO",
      "name": "Chinese (Macau)"
    },
    {
      "_id": "zh-SG",
      "name": "Chinese (Singapore)"
    },
    {
      "_id": "zh-TW",
      "name": "Chinese (T)"
    },
    {
      "_id": "zu",
      "name": "Zulu"
    },
    {
      "_id": "zu-ZA",
      "name": "Zulu (South Africa)"
    }
  ]

  global.LANGUAGE_SUPPORTED = [
    { "_id": "af", "name": "Afrikaans" },
    { "_id": "ar", "name": "Arabic" },
    { "_id": "az", "name": "Azerbaijani" },
    { "_id": "be", "name": "Belarusian" },
    { "_id": "bg", "name": "Bulgarian" },
    { "_id": "bn", "name": "Bengali" },
    { "_id": "bs", "name": "Bosnian" },
    { "_id": "ca", "name": "Catalan" },
    { "_id": "ceb", "name": "Cebuano" },
    { "_id": "cs", "name": "Czech" },
    { "_id": "cy", "name": "Welsh" },
    { "_id": "da", "name": "Danish" },
    { "_id": "de", "name": "German" },
    { "_id": "el", "name": "Greek" },
    { "_id": "en", "name": "English" },
    { "_id": "eo", "name": "Esperanto" },
    { "_id": "es", "name": "Spanish" },
    { "_id": "et", "name": "Estonian" },
    { "_id": "eu", "name": "Basque" },
    { "_id": "fa", "name": "Persian" },
    { "_id": "fi", "name": "Finnish" },
    { "_id": "fr", "name": "French" },
    { "_id": "ga", "name": "Irish" },
    { "_id": "gl", "name": "Galician" },
    { "_id": "gu", "name": "Gujarati" },
    { "_id": "ha", "name": "Hausa" },
    { "_id": "hi", "name": "Hindi" },
    { "_id": "hmn", "name": "Hmong" },
    { "_id": "hr", "name": "Croatian" },
    { "_id": "ht", "name": "Haitian Creole" },
    { "_id": "hu", "name": "Hungarian" },
    { "_id": "hy", "name": "Armenian" },
    { "_id": "id", "name": "Indonesian" },
    { "_id": "ig", "name": "Igbo" },
    { "_id": "is", "name": "Icelandic" },
    { "_id": "it", "name": "Italian" },
    { "_id": "iw", "name": "Hebrew" },
    { "_id": "ja", "name": "Japanese" },
    { "_id": "jw", "name": "Javanese" },
    { "_id": "ka", "name": "Georgian" },
    { "_id": "kk", "name": "Kazakh" },
    { "_id": "km", "name": "Khmer" },
    { "_id": "kn", "name": "Kannada" },
    { "_id": "ko", "name": "Korean" },
    { "_id": "la", "name": "Latin" },
    { "_id": "lo", "name": "Lao" },
    { "_id": "lt", "name": "Lithuanian" },
    { "_id": "lv", "name": "Latvian" },
    { "_id": "mg", "name": "Malagasy" },
    { "_id": "mi", "name": "Maori" },
    { "_id": "mk", "name": "Macedonian" },
    { "_id": "ml", "name": "Malayalam" },
    { "_id": "mn", "name": "Mongolian" },
    { "_id": "mr", "name": "Marathi" },
    { "_id": "ms", "name": "Malay" },
    { "_id": "mt", "name": "Maltese" },
    { "_id": "my", "name": "Myanmar (Burmese)" },
    { "_id": "ne", "name": "Nepali" },
    { "_id": "nl", "name": "Dutch" },
    { "_id": "no", "name": "Norwegian" },
    { "_id": "ny", "name": "Chichewa" },
    { "_id": "pa", "name": "Punjabi" },
    { "_id": "pl", "name": "Polish" },
    { "_id": "pt", "name": "Portuguese" },
    { "_id": "ro", "name": "Romanian" },
    { "_id": "ru", "name": "Russian" },
    { "_id": "si", "name": "Sinhala" },
    { "_id": "sk", "name": "Slovak" },
    { "_id": "sl", "name": "Slovenian" },
    { "_id": "so", "name": "Somali" },
    { "_id": "sq", "name": "Albanian" },
    { "_id": "sr", "name": "Serbian" },
    { "_id": "st", "name": "Sesotho" },
    { "_id": "su", "name": "Sudanese" },
    { "_id": "sv", "name": "Swedish" },
    { "_id": "sw", "name": "Swahili" },
    { "_id": "ta", "name": "Tamil" },
    { "_id": "te", "name": "Telugu" },
    { "_id": "tg", "name": "Tajik" },
    { "_id": "th", "name": "Thai" },
    { "_id": "tl", "name": "Filipino" },
    { "_id": "tr", "name": "Turkish" },
    { "_id": "uk", "name": "Ukrainian" },
    { "_id": "ur", "name": "Urdu" },
    { "_id": "uz", "name": "Uzbek" },
    { "_id": "vi", "name": "Vietnamese" },
    { "_id": "yi", "name": "Yiddish" },
    { "_id": "yo", "name": "Yoruba" },
    { "_id": "zh-CN", "name": "Chinese (Simplified)" },
    { "_id": "zh-TW", "name": "Chinese (Traditional)" },
    { "_id": "zu", "name": "Zu Zu" }
  ]

}

export default {}
