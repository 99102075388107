import styled from 'styled-components';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Table } from 'react-virtualized';
import unescape from 'lodash/unescape';
import debounce from 'lodash/debounce';
import { t } from 'react-i18nify';

const Style = styled.div`
    position: relative;
    border: solid 1px rgba(0,0,0,0.2);

    .item_time {
        padding-top: 10px;
        padding-bottom: 2px;
        padding-left: 20px;
        font-size: 10px;
        color: #aaa;
    }

    .item_action {
        position: absolute;
        left: 20px;
        bottom: 0px;
        overflow: hidden;

        span {
            padding: 4px 7px;
            font-size: 10px;
            cursor: pointer;
            background-color: #03A9F4;
            color: #fff;
            border-right: solid 1px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        .add {

        }

        .del {
            background-color: #E91E63 !important;
        }
    }
    .rstlM .video .subtitle .textarea.pause {
        font-size: 12px
    }

    .ReactVirtualized__Table {
        .ReactVirtualized__Table__Grid {
            outline: none;
        }

        .ReactVirtualized__Table__row {
            .item {
                height: 100%;
                .textarea {
                    padding: 5px 20px;
                    border: none;
                    width: 100%;
                    height: 100%;
                    color: #000;
                    font-size: 12px;
                    text-align: left;
                    transition: all 0.2s ease;
                    resize: none;
                    outline: none;

                    &.highlight {
                        background-color: #ede7f6;
                    }

                    &.illegal {
                        background-color: #FCE4EC;
                        border: 1px solid rgba($tone, 0.3);
                    }
                }
            }
        }
    }
`;

export default function Subtitles({ currentIndex, subtitle, checkSub, player, updateSub, newSub, addSub, mergeSub, splitSub, removeSub }) {
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [inputItemCursor, setInputItemCursor] = useState(0);
    const myRef = useRef(null);

    const resize = useCallback(() => {
        setHeight(document.subtitleEditorElement.clientHeight - 170);
        setWidth(myRef.current.offsetWidth)
        console.log(document.subtitleEditorElement.clientHeight, myRef.current.offsetWidth)
    }, [setHeight, setWidth]);

    useEffect(() => {
        resize();
        if (!resize.init) {
            resize.init = true;
            const debounceResize = debounce(resize, 500);
            window.addEventListener('resize', debounceResize);
        }
    }, [resize]);

    const onMergeSub = (props) => {
        mergeSub(props.rowData)
    }

    const onSplitSub = (props) => {
        splitSub(props.rowData, inputItemCursor)
    }

    const onAddSub = (currentIndex) => {
        const start = subtitle[currentIndex].end
        const end = (currentIndex + 1) < subtitle.length ? subtitle[currentIndex + 1].start : start

        // CASE: time not valid
        if (start >= end) {
            return
        }

        const sub = newSub({
            start,
            end,
            text: t('SUB_TEXT'),
        })
        addSub(currentIndex + 1, sub)
    }

    const onRemoveSub = (props) => {
        removeSub(props.rowData)
    }



    return (
        <Style className='subtitles' ref={myRef}>
            <Table
                headerHeight={40}
                width={width}
                height={height}
                rowHeight={80}
                scrollToIndex={currentIndex}
                rowCount={subtitle.length}
                rowGetter={({ index }) => subtitle[index]}
                headerRowRenderer={() => null}
                rowRenderer={(props) => {
                    return (
                        <div
                            key={props.key}
                            className={props.className}
                            style={props.style}
                            onClick={() => {
                                if (player) {
                                    player.pause();
                                    if (player.duration >= props.rowData.startTime) {
                                        player.currentTime = props.rowData.startTime + 0.001;
                                    }
                                }
                            }}
                        >
                            <div className='item'>
                                <div className='item_time'>
                                    <span>{props.rowData.start}</span>
                                    <span> - </span>
                                    <span>{props.rowData.end}</span>
                                </div>
                                {currentIndex === props.index ?
                                    <div className='item_action'>
                                        <span className='merge' onClick={() => onMergeSub(props)}>↑ merge</span>
                                        <span className='split' onClick={() => onSplitSub(props)}>⇵ split</span>
                                        <span className='add' onClick={() => onAddSub(currentIndex)}>✛ add</span>
                                        <span className='del' onClick={() => onRemoveSub(props)}>✕ delete</span>
                                    </div>
                                    : <></>}
                                <textarea
                                    maxLength={200}
                                    spellCheck={false}
                                    className={[
                                        'textarea',
                                        currentIndex === props.index ? 'highlight' : '',
                                        checkSub(props.rowData) ? 'illegal' : '',
                                    ]
                                        .join(' ')
                                        .trim()}
                                    value={unescape(props.rowData.text)}
                                    onChange={(event) => {
                                        updateSub(props.rowData, {
                                            text: event.target.value,
                                        });
                                        if (event.target.selectionStart) {
                                            setInputItemCursor(event.target.selectionStart);
                                        }
                                    }}
                                    onClick={(event) => {
                                        if (event.target.selectionStart) {
                                            setInputItemCursor(event.target.selectionStart);
                                        }
                                    }}
                                    onFocus={(event) => {
                                        if (event.target.selectionStart) {
                                            setInputItemCursor(event.target.selectionStart);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    );
                }}
            ></Table>
        </Style>
    );
}
