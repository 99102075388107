export function rxsecret() {
  const result = []
  result.web_port = 4444
  result.base_api = 'http://localhost:4443'
  result.theme = 'default'
  result.MinioBaseUrl = 'http://localhost:4443'
  result.MinioDirectory = '/Users/admin/Source/zen/dubapp/storage/'
  result.PathDownload = '/'
  return result
}

// export function rxsecret() {
//   const result = []
//   result.web_port = 4444
//   result.base_api = 'https://dubapi.aicola.vn'
//   result.theme = 'default'
//   result.MinioBaseUrl = 'https://medevhub.ddns.net'
//   result.MinioDirectory = '/Users/admin/Source/zen/dubapp/storage/'
//   result.PathDownload = '/'
//   return result
// }
