// import { json } from '@rxframework/rxulti'
import * as Jsx from './rxCrud.jsx'
import * as xlsx from 'xlsx'

global.isclient && require('./rxCrud.css')
global.isclient && require('jsoneditor/dist/jsoneditor.css')

const { apiget, apipost } = global.rootRequire('classes/request')
const RxComponent = global.rootRequire('components/shares/rxComponent').default

const rxu = global.rxu

const WAIT_INTERVAL = 500
class RxCrud extends RxComponent {
  constructor(props, context) {
    super(props, context, Jsx)
    this._mounted = false

    this.state = {
      flagUpdate: false,
      paging: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 },
      pagingGame: { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 100 },
      editingData: {},
      extra: {},
      data: {},
      flags: {},
      temp: {},
      error: {},
      loading: false,
      playlistSelected: {
        "name": "Default",
        "slug": "default",
        "originLang": "zh",
        "targetLang": "vi",
        "type": "dub",
        "isHardsub": "hardsub",
        "is_deleted": 0,
        "is_active": 1,
        "created_at": 1698920898,
        "updated_at": 1698920898,
        "_id": this.props.searchId || '',
        "__v": 0
      },
    }
    this.onChangeContentCKE = this.onChangeContentCKE.bind(this)

  }
  componentDidMount() {
    this._mounted = true
    this.fetchAlldata()

  }
  componentWillUnmount() {
    this._mounted = false
    super.componentWillUnmount()
    if (this.jsoneditor) { this.jsoneditor.destroy() }
  }
  componentDidUpdate(prevProps) {
    clearTimeout(this.timerFetchData)

    if ([false, true].indexOf(prevProps.flagUpdate) !== -1 && prevProps.flagUpdate !== this.props.flagUpdate) {
      this.setState({ flagUpdate: prevProps.flagUpdate })
      this.run('parentUpdate', {})
    }
    // if (this.state.data !== prevProps.data) {
    //   this.timerFetchData = setTimeout((e, name) => { console.log(222); this.fetchData(false, false) }, 5000)
    // }
  }

  // D A T A
  fetchAlldata() {
    this.fetchData()
    if (this.props.searchId) {
      this.fetchCate({ searchid_id: this.props.searchId })
    }

  }

  fetchCate(searchid_id) {
    apiget(global.rxu.config.api_playlists, {}, {
      1: (json) => {
        const filteredData = json.data.filter(item => item._id === this.props.searchId)
        this.setState({ playlistSelected: filteredData })
      }
    })
  }

  fetchData(runCallback = false, loading = true) {

    let paging = this.state.paging
    if (this.props.searchId) {
      // paging.searchid_playlist = this.props.searchId
      paging.searchid_folder = this.props.searchId
    }
    console.log(paging)
    this.setState({ loading: loading }, () => {
      apiget(rxu.get(rxu.config, this.props.api), paging, {
        1: (json) => {
          this.setState({ data: json.data, extra: json.extra, loading: false }, () => {
            if (!rxu.get(this.state, ['temp', 'filterFields'])) {
              const temp = this.state.temp; temp.filterFields = rxu.get(this.state, ['data', 0])
              this.setState({ temp })
            }
          })
        }

      })
      // this.timerFetchData = setTimeout((e, name) => { console.log(222); this.fetchData(false, false) }, 5000)
    })

    runCallback ? this.run('dataCallback', {}) : console.log()
  }

  run(name, params) {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }

  toggle(flagname) {
    const flags = this.state.flags
    flags[flagname] = flags[flagname] ? 0 : 1
    this.setState({ flags: flags })
  }

  flag(flagname) {
    return rxu.get(this.state, ['flags', flagname], 0)
  }

  // C L I C K   E V E N T
  onClickData(e, perdata) { }
  onClickSort(e, stcol) {
    const paging = this.state.paging
    paging.st_type = (paging.st_col !== stcol) ? -1 : (-1 * (paging.st_type))
    paging.st_col = stcol
    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  setStateRx(object, callback) {
    if (this._mounted) { this.setState(object, callback) }
  }

  onClickDataImport(e) {
    let scope = this
    let reader = new FileReader()
    let fileDom = e.target || e.srcElement
    if (fileDom.value !== '') {
      let file = fileDom.files[0]
      if (typeof file !== 'undefined') {
        reader.onload = function (e) {
          let datatmp = new Uint8Array(e.target.result);
          let workbook = xlsx.read(datatmp, { type: 'array' });
          workbook.SheetNames.forEach((sheetName) => {
            var data = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName])
            console.log(data);
            scope.setStateRx({ importData: data })

          })
        }
        reader.readAsArrayBuffer(file)
      }
    }
    else { }
  }

  onClickSubmitExcelUpload() {
    let importData = this.state.importData
    importData = JSON.stringify(importData)
    let ob = { data: importData }
    let flags = this.state.flags
    apipost(rxu.get(rxu.config, this.props.api) + '/importExcel', ob, {
      '1': (json) => {
        flags['checkFile'] = false
        this.setStateRx({ importData: [], flags: flags }, () => this.fetchData(true))
        if (json.data && json.data.length > 0) {
          console.log('có ' + json.data.length + ' sản phẩm đã tồn tại')
        }
      }
    })
  }

  onClickExcelUpload(flagname) {
    let flags = this.state.flags
    if (!flags[flagname]) { flags[flagname] = false }
    flags[flagname] = true
    this.setState({ flagname: flagname })
  }

  onClickDataExport(e) {
    apiget(rxu.get(rxu.config, this.props.api), { ...this.state.paging, pg_size: 10000 }, {
      1: (json) => { global.JSONToCSVConvertor(json.data, '') }
    })
  }

  onClickDataNew(e) {
    global.scrolltop()
    // const timeStr = Date.now().toString()
    const clone = {
      name: this.props.name + '_name_',
      originLang: 'zh',
      targetLang: 'vi',
      type: 'dub',
      created_at: 1,
      is_deleted: 0,
      is_active: 1,
    }
    this.setState({ editingData: clone })
  }

  onClickDataEdit(e, perdata) {
    global.scrolltop()
    if (typeof perdata.inthis !== 'undefined') { delete perdata.inthis }
    const clone = rxu.clone(perdata)
    this.setState({ editingData: clone, flags: {} })
  }

  onClickDataDelete(e, perdata) {
    const r = window.confirm('Bạn muốn xoá dữ liệu này !')
    if (r === true) {
      apiget(rxu.get(rxu.config, this.props.api) + '/delete', perdata, {
        1: (json) => { this.fetchData(true) }
      })
    } else { }
  }

  onClickDataRestore(e, perdata) {
    apiget(rxu.get(rxu.config, this.props.api) + '/restore', perdata, {
      1: (json) => { this.fetchData(true) }
    })
  }

  onClickDataUpdateSubmit(e, perdata) {
    global.scrolltop()
    let editingData = this.state.editingData

    apipost(rxu.get(rxu.config, this.props.api) + '/edit', editingData, {
      1: (json) => { this.fetchData(true); this.onClickDataEdit({}, {}) },
      '-2': (json) => { console.log(json); this.setState({ loading: false, error: json.msg }) }

    })
  }

  onClickDataUpdateSubmitRaw(e, perdata) {
    global.scrolltop()
    apipost(rxu.get(rxu.config, this.props.api) + '/edit', { ...this.jsoneditor.get(), _id: this.state.editingData._id, rxraw: 1 }, {
      1: (json) => { this.fetchData(true) },
      default: () => { }
    })
    this.onClickDataEdit({}, {})
  }

  onClickDataCreateSubmit(e, perdata) {
    global.scrolltop()
    let editingData = this.state.editingData
    this.setState({ loading: true }, () => {
      apipost(rxu.get(rxu.config, this.props.api), editingData, {
        1: (json) => { this.setState({ loading: false }, () => { this.fetchData(true); this.onClickDataEdit({}, {}) }) },
        '-2': (json) => { console.log(json); this.setState({ loading: false, error: json.msg }) }
      })
    })

  }

  onClickDataTrash(e, isdeleted) {
    const paging = this.state.paging
    paging.search_is_deleted = isdeleted
    this.setState({ paging: paging }, () => {
      this.fetchData()
    })
  }

  onClickGenPermission() {
    apiget(rxu.config.api_permission_genpermission, {}, {
      1: (json) => {
        this.fetchData()
      }
    })
  }

  // B L U R   E V E N T
  onBlurData(e, name, options) {
    options = options || {}
    const editingData = this.state.editingData

    if (options.strim) {
      editingData[name] = e.target.value.toString().replace(/(,)/g, '')
    } else {
      editingData[name] = e.target.value
    }
    this.setState({ editingData: editingData })
  }

  onChangeValue(e, name) {
    const editingData = this.state.editingData
    editingData[name] = e.target.value
    this.setState({ editingData: editingData })
  }

  onBlurDataValue(value, name, item) {
    const editingData = this.state.editingData
    let playlistSelected = this.state.playlistSelected

    editingData[name] = value
    if (name === 'playlist') {
      editingData.extra = item
      playlistSelected = item
    }
    this.setState({ editingData: editingData, playlistSelected: playlistSelected })
  }

  onBlurOptions(value, name, item) {
    if (value) {
      const editingData = this.state.editingData
      let playlistSelected = this.state.playlistSelected
      playlistSelected[name] = value || value.target.value
      editingData[item] = playlistSelected
      this.setState({ editingData: editingData, playlistSelected: playlistSelected })
    }

  }

  onBlurDatafilter(e, name) {
    clearTimeout(this.timerDatafilter)
    const paging = this.state.paging
    paging['search_' + name] = e.target.value
    this.setState({ paging: paging })

    this.timerDatafilter = setTimeout((e, name) => {
      this.fetchData()
    }, WAIT_INTERVAL)
  }

  // C H A N G E  E V E N T
  onChangeContentCKE(evt) {
    const editingData = this.state.editingData
    const newContent = evt.editor.getData()
    editingData.content = newContent
    this.setState({ editingData: editingData })
  }

  // P A G I N  E V E N T
  onClickPaging(page) {
    const paging = this.state.paging
    const count = rxu.get(this.state.extra, 'count', paging.pg_size)
    const maxpage = Math.ceil(count / paging.pg_size)

    if (page < 1) { page = 1 }
    if (page > maxpage) { page = maxpage }

    paging.pg_page = page
    this.setState({ paging: paging }, () => { this.fetchData() })
  }

  onChangePageSize(evt) {
    clearTimeout(this.timerPageSize)
    const pagesize = parseInt(evt.target.value, 10)
    const paging = this.state.paging
    paging.pg_size = pagesize
    paging.pg_page = 1
    if (pagesize >= 1 && pagesize <= 1000) {
      this.setState({ paging: paging }, () => { this.timerPageSize = setTimeout((e, name) => { this.fetchData() }, WAIT_INTERVAL) })
    }
  }

  // U P L O A D  E V E N T
  callbackUpload(e, name, file) {

    console.log(e, name, file)

    let editingData = this.state.editingData
    if (file) {
      if (name === 'filePath') {
        editingData.fileSize = file.size
        editingData.fileType = file.type
        editingData.fileName = file.name
      } else if (name === 'srt') {
        editingData.fileSrtName = file.name
      }
      editingData[name] = `${rxu.get(e, ['images', 'postURL'])}/${rxu.get(e, ['images', 'formData', 'key'])}`
      console.log(editingData, 123)
      this.setState({ editingData: editingData })
    } else {
      editingData[name] = `${rxu.get(e, ['images', 'postURL'])}/${rxu.get(e, ['images', 'formData', 'key'])}`
    }
    this.setState({ editingData: editingData })
    // this.onBlurData({ target: { value: e.images } }, name)
  }

  callbackUploadDetail(e) {
    this.onBlurData({ target: { value: e.images } }, 'img_detail')
  }

  // H E L P E R S
  helpProductcat(cats) {
    let result = rxu.get(cats, 'name', 'No playlist')
    return result
  }

  helpSortClass(stcol, extraclass) {
    extraclass = extraclass || []
    let result = ''
    if (this.state.paging.st_col === stcol) {
      result = this.state.paging.st_type === 1 ? 'rx-sort-asc' : 'rx-sort-desc'
    }
    for (let i = 0; i < extraclass.length; i++) { result += ' ' + extraclass[i] }
    return result
  }

  helpStatus(status, type) {
    if (status === 10) { return global[type][6] }
    return global[type][status]
  }

  helpParseJson(data, type = 'dubsubHard') {
    const result = `${rxu.config.PathDownload}${rxu.get(JSON.parse(data), [type], '')}`
    return result
  }

  bytesToMB(bytes) {
    if (typeof bytes !== 'number') {
      throw new Error('Input must be a number');
    }
    return bytes / (1024 * 1024);
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
  }
  // R E N D E R S
  render() { return this.renderComponent() }
}

RxCrud.defaultProps = { name: '', api: '', form: [], dataCallback: () => { }, renderPage: () => { }, data: {} }
export default RxCrud
