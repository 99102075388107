import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
global.isclient && require('./footer.css')

class Footer extends Component {
  render() {
    return (
      <div className='footer'>
        <div className='footer__inner'>
          <div className='container'>
            <div className='footer_logo'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/logo.png' /></div>
            <div className='footer__title'></div>
            <div className='footer__block'>
              <div className='footer__accountitem'><span>Email:</span> lam@huayi.vn</div>
              <div className='footer__accountitem'><span>Address:</span> Ho Chi Minh City, Vietnam</div>
            </div>


          </div>
        </div>
        {/* <img className='footer__background' src='/images/static/footerbackground.jpg' alt='' /> */}
        {/* }<div className='footer__bot'>
          <div className='footer__copyright'>© 2018 RxCodeGen Pty Ltd. Terms | Privacy <Link to='/admin/dashboard'>Aaa</Link></div>
          <div className='footer__dkkd'>Giấy chứng nhận Đăng ký Kinh doanh do Sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh cấp ngày 09/12/2015.</div>
        </div> */}
      </div>)
  }
}

export default Footer
