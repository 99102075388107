import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

global.isclient && require('./home.css')

const { apiget } = global.rootRequire('classes/request')
const { cartClear, cartAdd, cartDelete, favoriteAdd, favoriteDelete, rxnavToggle, rxnavClose } = global.rootRequire('redux')

const Home = (props) => {
  // const history = useHistory()

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    apiget(global.rxu.config.api_home, {}, {
      1: (json) => {
        // Assuming that 'json.data' contains 'products' and 'reviews'
        props.setState({ products: json.data.products, reviews: json.data.reviews });
      }
    });
  };

  // const onClickAddCart = (e, product) => {
  //   e.stopPropagation();
  //   props.cartAdd(product._id, { amount: 1, data: product, key: '' });
  //   props.rxnavClose('rxcart', {});
  //   props.rxnavToggle('rxcart', {});
  // };

  // const onClickLoginOrRegister = () => {
  //   history.push('/login')
  // }


  return (<div>
    <div className='slide'>
      <div className='slide__item'>
        {/* <div className='icon-arrow-left nav-icon slide__back' /> */}
        {/* <img className='slide__itemimg' alt='logoimg' src='/images/static/sli3.jpg' /> */}
        <div className='slide__itemtxt'>
          <div className='container container--relative container--relative__banner'>
            <div className='slide__txt_banner slide__txt_banner_left'>
              <div className='slide__txttitle'>
                <p>Imagine the Possibilities</p>
                <p>With aicola's AI Studio</p>
                <p>For Video Editing</p> </div>
              <div className='slide__txtdesc'>Easy to use. Astoundingly powerful for faster turn around. Aicola AI enhances content viewing experiences with the best language solutions.</div>
              {/* <div className='slide__txtbtn-large' onClick={onClickLoginOrRegister}><span>Start Free Trial Now</span><span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch.png' /></span></div> */}
              <Link to="/login">
                <div className='slide__txtbtn-large'>
                  <span>Start Free Trial Now</span>
                  <span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch.png' /></span>
                </div>
              </Link>
              {/* <img className='slide__itemgif' alt='logoimg' src='/images/aicola/banner01.png' /> */}
            </div>
            <div className='slide__txt_banner slide__txt_banner_right'>
              <div className='slide__item_image'><img className='slide__itemgif__' alt='logoimg' src='/images/aicola/banner01.png' /></div>
            </div>

          </div>
        </div>
        {/* <div className='icon-arrow-right nav-icon slide__next' /> */}
      </div>
    </div>
    <div className='block__supportwrap-wrap'>
      <div className='container container--relative container--relative__block__supportwrap'>
        <div className='block__support-wrap'>
          <div className='title-categories block_01'>Our services</div>
          <div className='title'>Let Us Show You The Magic!</div>
          <div className='block__support-content'>
            <div className='block__support-item'>
              <div className='block__support-item-padding'>
                <div className='block__support-item--img'><img className='slide__itemgif__' alt='logoimg' src='/images/aicola/Rectangle6.png' /></div>
                <div className='block__support-item--info'>
                  <div className='block__suport-item--info---title'>Smart Dubbing</div>
                  <div className='block__suport-item--info__desc'>Create unique experiences with Video Dubbing</div>
                  <Link to="/login">
                    <div className='block__supportbtn-btn'>
                      Get started
                      <span className='btn--icon'>
                        <img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch_2.png' />
                      </span>
                    </div>
                  </Link>
                  {/* <div className='block__supportbtn'><div className='block__supportbtn-btn'>Get started<span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch_2.png' /></span></div></div> */}
                </div>
              </div>
            </div>
            <div className='block__support-item'>
              <div className='block__support-item-padding'>
                <div className='block__support-item--img'><img className='slide__itemgif__' alt='logoimg' src='/images/aicola/Rectangle6_2.png' /></div>
                <div className='block__support-item--info'>
                  <div className='block__suport-item--info---title'>Smart Subtitle</div>
                  <div className='block__suport-item--info__desc'>Say more with AI Subtitles Generator</div>
                  <Link to="/login">
                    <div className='block__supportbtn-btn'>
                      Get started
                      <span className='btn--icon'>
                        <img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch_2.png' />
                      </span>
                    </div>
                  </Link>
                  {/* <div className='block__supportbtn'><div className='block__supportbtn-btn'>Get started<span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch_2.png' /></span></div></div> */}
                </div>
              </div>
            </div>
            <div className='block__support-item'>
              <div className='block__support-item-padding'>
                <div className='block__support-item--img'><img className='slide__itemgif__' alt='logoimg' src='/images/aicola/Rectangle6_3.png' /></div>
                <div className='block__support-item--info'>
                  <div className='block__suport-item--info---title'>Smart Video Enhancement</div>
                  <div className='block__suport-item--info__desc'>One-stop video processing service to repair video quality</div>
                  <Link to="/login">
                    <div className='block__supportbtn-btn'>
                      Get started
                      <span className='btn--icon'>
                        <img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch_2.png' />
                      </span>
                    </div>
                  </Link>
                  {/* <div className='block__supportbtn'><div className='block__supportbtn-btn'>Get started<span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch_2.png' /></span></div></div> */}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div className='block__supportwrap_parallax'>
      <div className='container container--relative'>
        <div className='block__supportwrap_parallax--wrap'>
          <div className='slide__txt_banner slide__txt_banner_left'>
            <div className='block__supportwrap_parallax--title'>
              <p>Smart Dubbing</p>
            </div>
            <div className='slide__txtdesc'>
              <p>AI video dubbing has the potential to revolutionize the way we consume media, as it enables the creation of dubbed versions of videos in a wide range of languages quickly and inexpensively.</p>
              <p>The timing of the dubbed audio is adjusted to match the pacing of the original audio, rather than the movements of the characters' mouths in the video.</p>
              <p>Another way to emphasize specific words or phrases in AI dubbing is to use visual cues.</p></div>
            <Link to="/login">
              <div className='slide__txtbtn-large'>
                <span>Get started</span>
                <span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch.png' /></span>
              </div>
            </Link>
            {/* <div className='slide__txtbtn-large'><span>Get started</span><span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch.png' /></span></div> */}
          </div>
          <div className='slide__txt_banner slide__txt_banner_right'>
            <div className='slide__item_image'><img className='slide__itemgif__' alt='logoimg' src='/images/aicola/banner02.png' /></div>
          </div>
        </div>
      </div>
    </div>

    <div className='block__supportwrap_parallax'>
      <div className='container container--relative'>
        <div className='block__supportwrap_parallax--wrap'>
          <div className='slide__txt_banner slide__txt_banner_left'>
            <div className='slide__item_image'><img className='slide__itemgif__' alt='logoimg' src='/images/aicola/banner03.png' /></div>
          </div>
          <div className='slide__txt_banner slide__txt_banner_right'>
            <div className='block__supportwrap_parallax--title'>
              <p>Smart Subtitle</p>
            </div>
            <div className='slide__txtdesc'>
              <p>Subtitles are like magic spectacles that extend your video's reach to a broader audience – not just those with hearing impairments.</p>
              <p>In the world of marketing and SEO, a video without a title and description is like a ship without a compass - it might sail, but it won't reach its destination with full force.</p>
            </div>
            <Link to="/login">
              <div className='slide__txtbtn-large'>
                <span>Get started</span>
                <span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch.png' /></span>
              </div>
            </Link>
            {/* <div className='slide__txtbtn-large'><span>Get started</span><span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch.png' /></span></div> */}
          </div>
        </div>
      </div>
    </div>

    <div className='block__supportwrap_parallax'>
      <div className='container container--relative'>
        <div className='block__supportwrap_parallax--wrap'>
          <div className='slide__txt_banner slide__txt_banner_left'>
            <div className='block__supportwrap_parallax--title'>
              <p>Smart Video Enhancement</p>
            </div>
            <div className='slide__txtdesc'>
              <p>AIcola can upscale low-resolution videos to high-resolution, reduce video noise, and optimize brightness and contrast automatically. Additionally, it has a user-friendly interface and supports a wide range of input video formats.</p>
            </div>
            <Link to="/login">
              <div className='slide__txtbtn-large'>
                <span>Get started</span>
                <span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch.png' /></span>
              </div>
            </Link>
            {/* <div className='slide__txtbtn-large'><span>Get started</span><span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch.png' /></span></div> */}
          </div>
          <div className='slide__txt_banner slide__txt_banner_right'>
            <div className='slide__item_image'><img className='slide__itemgif__' alt='logoimg' src='/images/aicola/banner04.png' /></div>
          </div>
        </div>
      </div>
    </div>

    <div className='block__supportwrap-about-wrap'>
      <div className='block__supportwrap-about-wrap-overlay'></div>
      <div className='container container--relative container--relative__block__supportwrap'>
        <div className='block__support-wrap'>
          <div className='title-categories'>ABOUT US</div>
          <div className='about-title'>How to use AIcola Studio?</div>
          <div className='block__support-content'>
            <div className='block__support-item'>
              <div className='block__support-item-padding'>
                <div className='block__support-item--about-info'>
                  <div className='block__suport-item--info---step'>Step 1</div>
                  <div className='block__suport-item--info__title'>Upload Video File</div>
                  <div className='block__suport-item--info__img'><img className='slide__itemgif__' alt='logoimg' src='/images/aicola/step1.png' /></div>
                </div>
              </div>
            </div>
            <div className='block__support-item'>
              <div className='block__support-item-padding'>
                <div className='block__support-item--about-info'>
                  <div className='block__suport-item--info---step'>Step 2</div>
                  <div className='block__suport-item--info__title'>Edit Video</div>
                  <div className='block__suport-item--info__img'><img className='slide__itemgif__' alt='logoimg' src='/images/aicola/step2.png' /></div>
                </div>
              </div>
            </div>
            <div className='block__support-item'>
              <div className='block__support-item-padding'>
                <div className='block__support-item--about-info'>
                  <div className='block__suport-item--info---step'>Export</div>
                  <div className='block__suport-item--info__title'>Upload Video File</div>
                  <div className='block__suport-item--info__img'><img className='slide__itemgif__' alt='logoimg' src='/images/aicola/step3.png' /></div>
                </div>
              </div>
            </div>
          </div>
          <div className='block__support-footer'>
            <div className='block__support-footer-item-info'>
              <span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-check_2.png' /></span><span>Add watermark Edit timecode</span>
            </div>
            <div className='block__support-footer-item-info'>
              <span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-check_2.png' /></span><span>Target language & voice tone</span>
            </div>
            <div className='block__support-footer-item-info'>
              <span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-check_2.png' /></span><span>Adjust speed and voice volume</span>
            </div>
          </div>

          <div className='block__support-footer_btn'>
            <Link to="/login">
              <div className='slide__txtbtn-large'>
                <span>Get started</span>
                <span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch.png' /></span>
              </div>
            </Link>
            {/* <div className='slide__txtbtn-large'><span>Get started</span><span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch.png' /></span></div> */}
          </div>

        </div>
      </div>
    </div>

    <div className='block__supportwrap-about-wrap'>
      {/* <div className='block__supportwrap-about-wrap-overlay'></div> */}
      <div className='container container--relative container--relative__block__supportwrap'>
        <div className='block__support-wrap'>
          <div className='title-categories'>ABOUT US</div>
          <div className='about-title'>Simple Price, Powerful Effect!</div>
          <div className='block__support-content'>
            <div className='block__support-item rx-custome-with-border rx-custome'>
              <div className='block__support-item--about-info info_price'>
                <div className='block__suport-item--info---step text-aligh-left'><span className='step-key'>Value</span><span className='step-value'>($)</span></div>
                <div className='block__suport-item--info---step text-aligh-left'><span className='step-key'>Minute Quant</span><span className='step-value'>Minute(s)</span></div>
                <div className='block__suport-item--info---step text-aligh-left'><span className='step-key'>Cost</span><span className='step-value'>$ / Minute(s)</span></div>
              </div>
            </div>
            <div className='block__support-item rx-custome-with-border'>
              <div className='block__support-item--about-info info_price'>
                <div className='block__suport-item--info---step custom'>TRIAL</div>
                <div className='block__suport-item--info__price'>2.708</div>
                <div className='block__suport-item--info__price'>2.000</div>
                <div className='block__suport-item--info__price'>1.4</div>
                <div className='block__suport-item--info__trynow'>Try now</div>
              </div>
            </div>
            <div className='block__support-item rx-custome-with-border standard'>
              <div className='block__support-item--about-info info_price'>
                <div className='block__suport-item--info---step custom standard'>STANDARD</div>
                <div className='block__suport-item--info__price'>14.958</div>
                <div className='block__suport-item--info__price'>12.500</div>
                <div className='block__suport-item--info__price'>1.2</div>
                <div className='block__suport-item--info__trynow standard'>Try now</div>
              </div>
            </div>
            <div className='block__support-item rx-custome-with-border'>
              <div className='block__support-item--about-info info_price'>
                <div className='block__suport-item--info---step custom'>PREMIUM</div>
                <div className='block__suport-item--info__price'>21.875</div>
                <div className='block__suport-item--info__price'>20.000</div>
                <div className='block__suport-item--info__price'>1.1</div>
                <div className='block__suport-item--info__trynow'>Try now</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div className='block__supportwrap-about-wrap'>
      <div className='block__supportwrap-about-wrap-overlay overlay_bl_5'></div>
      <div className='container container--relative container--relative__block__supportwrap'>
        <div className='block__support-wrap'>
          <div className='about-title'>Why Aicola?</div>
          <div className='block-desc'>AIcola aims to bridge the language barrier and cultural gap in entertainment experiences through large-scale localization.</div>
          <div className='block__support-content'>
            <div className='slide__txt_banner slide__txt_banner_left'>
              <div className='slide__item_image'><img className='slide__itemgif__' alt='logoimg' src='/images/aicola/banner05.png' /></div>
            </div>
            <div className='slide__txt_banner slide__txt_banner_right custom'>
              <div className='block__supportwrap_parallax--title title_block_5'>
                <p>Rapid, 90% accurate translating</p>
              </div>
              <div className='slide__txtdesc'>
                <p>Skip the usual long and error-prone captioning process. Our tool delivers fast and accurate results, offering captions and voice-overs in  140+ languages.</p>
              </div>
              <div className='block__supportwrap_parallax--title title_block_5'>
                <p>Realistic AI voice-over</p>
              </div>
              <div className='slide__txtdesc'>
                <p>Are you looking for realistic AI voice-over services that avoid robotic and unnatural sounds? We provide a wide selection of natural-sounding  voices in multiple languages for your customization and choice.</p>
              </div>
              <div className='slide__txtbtn-large'><span>Get started</span><span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch.png' /></span></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='block__supportwrap-about-wrap'>
      {/* <div className='block__supportwrap-about-wrap-overlay'></div> */}
      <div className='container container--relative container--relative__block__supportwrap'>
        <div className='block__support-wrap'>
          <div className='title-categories'>TESTIMONIALS</div>
          <div className='about-title'>What Our Happy Users Say</div>
          <div className='block__support-content block_06'>
            <div className='block__support-item block_06'>
              <div className='block__support-item--about-info user_info'>
                <div className='block__suport-item--info__img block_06'>
                  <span className='slide__itemgif__quote'><img className='slide__itemgif__' alt='logoimg' src='/images/aicola/icon-quote.png' /></span>
                  <img className='slide__itemgif__' alt='logoimg' src='/images/aicola/Ellipse1.png' />
                </div>
                <div className='block__suport-item--info--name'>Milan</div>
                <div className='block__suport-item--info--position'>Marketing Director</div>
                <div className='block__suport-item--info--desc'>Aicola has won the hearts of many because it effortlessly whips up subtitles with a dash of AI magic. Say goodbye to the hefty bills of human professionals, especially when you're tackling mammoth projects.</div>
              </div>
            </div>
            <div className='block__support-item block_06'>
              <div className='block__support-item--about-info user_info'>
                <div className='block__suport-item--info__img block_06'>
                  <span className='slide__itemgif__quote'><img className='slide__itemgif__' alt='logoimg' src='/images/aicola/icon-quote.png' /></span>
                  <img className='slide__itemgif__' alt='logoimg' src='/images/aicola/Ellipse1_2.png' />
                </div>
                <div className='block__suport-item--info--name'>Mai Anh</div>
                <div className='block__suport-item--info--position'>Editor</div>
                <div className='block__suport-item--info--desc'>We are very happy with Aicola and the quality of dubbing they can produce for us. Their turnaround time is great and the platform is easy to use. We will highly recommend Aicola and its services.</div>
              </div>
            </div>
            <div className='block__support-item block_06'>
              <div className='block__support-item--about-info user_info'>
                <div className='block__suport-item--info__img block_06'>
                  <span className='slide__itemgif__quote'><img className='slide__itemgif__' alt='logoimg' src='/images/aicola/icon-quote.png' /></span>
                  <img className='slide__itemgif__' alt='logoimg' src='/images/aicola/Ellipse1_3.png' />
                </div>
                <div className='block__suport-item--info--name'>Minho</div>
                <div className='block__suport-item--info--position'>Content Creator</div>
                <div className='block__suport-item--info--desc'>As a content creator, Aicola's AI dubbing has been a game-changer for me. Quick, high-quality, and easy to use, it's a must-have for expanding audience reach.</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div className='block__supportwrap-wrap'>
      <div className='container container--relative container--relative__block__supportwrap'>
        <div className='block__support-wrap'>
          <div className='title'>Contact Us</div>
          <div className='block-desc block_07'><p>Have a question or just want to say hi?</p><p> Please submit the inquiry or send emails to us at lam@huayi.vn</p></div>

          <div className='block__support-content-form-contact'>
            <div className='block-form-contact'>
              <div className='block-form-contact_padding'>
                <label className='contact-form'>
                  <label className='contact-form-name'>Name:</label>
                  <input
                    className='contact-form-input'
                    type="text"
                    name="name"
                  // value={formData.name}
                  // onChange={handleChange}
                  />
                </label>
              </div>
              <div className='block-form-contact_padding'>
                <label className='contact-form'>
                  <label className='contact-form-name'>Email:</label>

                  <input
                    className='contact-form-input'
                    type="email"
                    name="email"
                  // value={formData.email}
                  // onChange={handleChange}
                  />
                </label>
              </div>
            </div>
            <div className='block-form-contact'>
              <div className='block-form-contact_padding'>
                <label className='contact-form'>
                  <label className='contact-form-name'>Company:</label>

                  <input
                    className='contact-form-input'
                    type="text"
                    name="company"
                  // value={formData.company}
                  // onChange={handleChange}
                  />
                </label>
              </div>
              <div className='block-form-contact_padding'>
                <label className='contact-form'>
                  <label className='contact-form-name'>Phone:</label>
                  <input
                    className='contact-form-input'
                    type="tel"
                    name="phone"
                  // value={formData.phone}
                  // onChange={handleChange}
                  />
                </label>
              </div>
            </div>
            <div className='block-form-contact'>
              <div className='block-form-contact_padding ctf-textarea'>
                <label className='contact-form contact-form'>
                  <label className='contact-form-name'>Your company:</label>
                  <textarea
                    className='contact-form-input ctf-textarea'
                    type="text"
                    name="yourCompany"
                  // value={formData.yourCompany}
                  // onChange={handleChange}
                  />
                </label>
              </div>
            </div>

            <div className='button-submit'>
              <div className='slide__txtbtn-large-submit-form'><span>Submit</span><span className='btn--icon'><img className='btn--icon-img' alt='logoimg' src='/images/aicola/icon-launch.png' /></span></div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div className='block__partnerwrap'>
      <div className='block__partner container' />
    </div>
    <div className='block__customer' />
  </div >)

}

const mapStateToProps = (state) => ({
  cart: state.cart,
  auth: state.auth,
  favorite: state.favorite
});

const mapDispatchToProps = {
  cartClear,
  cartAdd,
  cartDelete,
  favoriteAdd,
  favoriteDelete,
  rxnavClose,
  rxnavToggle
};

const HomeConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);

export default HomeConnected;

