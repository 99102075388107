import * as Jsx from './items.jsx'
const rxu = global.rxu

const RxComponent = global.rootRequire('components/shares/rxComponent').default
const { apiget, rxJson, rxgetLocal } = global.rootRequire('classes/request')

class downloadComponent extends RxComponent {
  constructor(props, context) {
    super(props, context, Jsx)
    this.onClickCatItem = this.onClickCatItem.bind(this)
    this.runUpdateCat = this.runUpdateCat.bind(this)

    let searchId = rxu.get(this.props, ['location', 'search'])
    if (searchId) {
      searchId = searchId.split('?slug=')[1]
    } else {
      searchId = rxu.get(this.props, ['match', 'params', 'slug'])
      searchId = searchId.split('?slug=')[1]
    }

    let user = rxgetLocal('rxuser')
    if (user) {
      user = rxJson(user)
      this.isAdmin = global.rxu.get(user, ['user', 'admin'], 0)
    } else {
      this.isAdmin = 0
    }

    this.state = {
      searchId: searchId,
      catName: null,
      flagUpdateCat: false,
      curCat: 0,
      breadcrums: [],
      folders: [],
      cate: [{ "name": "Default", "originLanguage": "zh", "targetLanguge": "vi", "type": "dub", "isHardsub": "harsub", "is_deleted": 0, "is_active": 1, "created_at": 1698920898, "updated_at": 1698920898, "_id": "6544528d152f6e22c4093709", "__v": 0 }]
    }

    this.fetchData()
    this.fetchFolder()

  }

  componentDidMount() {
    const timeoutId = setTimeout(() => {
      this.fetchData()
    }, 1500);

    return () => clearTimeout(timeoutId);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let searchId = rxu.get(nextProps, ['location', 'search'])
    if (searchId) {
      searchId = searchId.split('?slug=')[1]
    } else {
      searchId = rxu.get(nextProps, ['match', 'params', 'slug'])
      searchId = searchId.split('?slug=')[1]
    }

    // let searchId = nextProps?.location?.search
    // if (searchId) { searchId = searchId.split('?slug=')[1] }
    if (searchId !== this.state.searchId) {
      const oldslug = this.state.searchId
      this.setState({ searchId: searchId }, () => {
        // When new slug come, not from server
        if (oldslug) { this.fetchData() }
      })
    }
  }

  fetchData() {
    apiget(global.rxu.config.api_playlists, {}, {
      1: (json) => {
        const filteredData = json.data.filter(item => item._id === this.state.searchId)
        this.setState({ cate: json.data, breadcrums: json.data, catName: rxu.get(filteredData, [0, 'name'], null) }, () => { })
      }
    })
  }

  fetchFolder() {
    apiget(global.rxu.config.api_folder, {}, {
      1: (json) => {
        this.setState({ folders: json.data }, () => { })
      }
    })
  }

  onClickCatItem(e) {
    this.setState({ flagUpdateCat: !this.state.flagUpdateCat, curCat: e._id })
  }

  runUpdateCat(e) {
    const paging = (this.state.curCat) ? { searchid_appdist: this.state.curCat, st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 } : { st_col: 'created_at', st_type: -1, pg_page: 1, pg_size: 10 }
    e.inthis.setState({ paging: paging }, () => { e.inthis.fetchData() })
  }

  onClickDataNew(e) {
    global.scrolltop()
    const timeStr = Date.now().toString()
    const clone = {
      name: 'Item_' + timeStr,
      link: '',
      srt: '',
      fileSrtName: '',
      logoWatermark: '',
      playlist: rxu.get(e.inthis.state, ['playlistSelected', 0, '_id'], 'noname'),
      folder: '',
      linkDownload: '',
      status: 0,
      args: '',
      srtIsCheck: 0,
      created_at: 1,
      is_deleted: 0,
      is_active: 1,
    }

    e.inthis.setState({ editingData: clone })
  }

  parseLog(logs, output) {
    let result = '';
    if (logs) {
      logs.reverse().forEach(log => {

        if ('stderr' in log && 'stdout' in log) {
          result += log.stderr;
          result += log.stdout;
        }
      });
    }
    return result;
  }


  // R E N D E R S
  render() { return this.renderComponent() }
}

export default downloadComponent
