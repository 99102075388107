export function rxsecret() {
  const result = []
  result.web_port = 4444
  result.base_api = 'https://dubapi.aicola.vn'
  result.theme = 'default'
  result.MinioBaseUrl = 'https://medevhub.ddns.net'
  result.MinioDirectory = '/Users/admin/Source/zen/dubapp/storage/'
  result.PathDownload = '/download/'
  return result
}
