import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProgressBar from "./rxProgressBar";
import { toast } from 'react-toastify';

const RxSelectbox = global.rootRequire('components/shares/rxSelectbox').default
const RxMinioUploader = global.rootRequire('components/shares/rxMinioUploader').default
const { apiget } = global.rootRequire('classes/request')

const CreateItemPopup = ({ folders, onClose, onCreate, onUpdate, msgError, loading, isEditData, editingData }) => {

    const itemStatus = [{ id: 0, value: 'Todo' }, { id: 1, value: 'Processing' }, { id: 2, value: 'Done' }, { id: 3, value: 'Error' }, { id: 4, value: 'Review SRT' }, { id: 5, value: 'Review SRT Done' }, { id: 10, value: 'Done sub' }]
    const isProgressHanler = {
        srtInfo: { status: false, hasFile: false },
        videoInfo: { status: false, hasFile: false },
        status: false
    }
    const [newItem, setNewItem] = useState(editingData)
    const [progress, setProgress] = useState(0)
    const [isProgress, setIsProgress] = useState(isProgressHanler)


    const onBlurData = (e, name) => {
        if (name === 'linkYt') { newItem.hasLink = 1 }
        console.log(name)
        setNewItem({
            ...newItem,
            [name]: e.target.value,
        });
    };

    const onBlurChecked = (event, name) => {
        const { checked } = event.target;
        console.log(checked, name)
        setNewItem({
            ...newItem,
            [name]: checked
        });
    };

    const onBlurDataValue = (value, name, obj) => {
        setNewItem({
            ...newItem,
            [name]: value,
            ['folderObj']: obj
        });
    };

    const callbackUpload = (file, field) => {
        if (field === 'videoInfo') {
            setIsProgress(prevState => ({
                ...prevState,
                videoInfo: { status: false, hasFile: true }
            }));
        } else if (field === 'srtInfo') {
            setIsProgress(prevState => ({
                ...prevState,
                srtInfo: { status: false, hasFile: true }
            }));
        }
        setNewItem((prevItem) => {
            const updatedItem = { ...prevItem };
            const { size, type, name } = file
            updatedItem[field] = { file: file, size, type, name }
            if (field === 'srtInfo') {
                updatedItem.hasSrt = 1
            }
            return updatedItem
        });
    };

    const handleCreateClick = async () => {
        if (isProgress.status) {
            await onCreate(newItem)
            onClose()

        } else {
            await handleUpload()
        }

    };

    const handleUpdateClick = async () => {
        onUpdate(newItem)
        onClose()
    }

    useEffect(() => {
        handleCreateClick()
    }, [isProgress.status]);

    const handleCancelClick = () => {
        onClose()
    };

    const handleUpload = async () => {
        try {
            const uploadPromises = [];

            if (isProgress.videoInfo.hasFile) {
                uploadPromises.push(helperHandleUpload('videoInfo'));
            }
            if (isProgress.srtInfo.hasFile) {
                uploadPromises.push(helperHandleUpload('srtInfo'));
            }

            await Promise.all(uploadPromises);

            return true;
        } catch (err) {
            console.error('Error handling uploads:', err);
            return false;
        }
    };

    const helperHandleUpload = async (field) => {
        const filehandle = newItem[field]
        if (!filehandle || !filehandle.name) { return false }
        try {
            await apiget(global.rxu.config.base_api + '/upload/presignedUrl', {
                playlist: global.rxu.get(newItem, ['folderObj', 'slug'], 'images'),
                filename: `input/${filehandle.name}`,
            }, {
                1: async (json) => {
                    if (json && json.data) {
                        try {
                            let dataUpload = new FormData()
                            let arrKeys = ['Content-Type', 'key', 'policy', 'x-amz-algorithm', 'x-amz-credential', 'x-amz-date', 'x-amz-meta-netachat', 'x-amz-signature']
                            for (let key of arrKeys) {
                                if (json.data.formData[key]) {
                                    dataUpload.append(key, json.data.formData[key])
                                }
                            }
                            dataUpload.append('file', filehandle.file, filehandle.name)

                            await axios.post(json.data.postURL, dataUpload, {
                                onUploadProgress: (progressEvent) => {
                                    const progress = (progressEvent.loaded / progressEvent.total) * 100;
                                    setProgress(Math.floor(progress))
                                    if (progress && progress === 100) {
                                        setNewItem((prevItem) => {
                                            const updatedItem = { ...prevItem };
                                            updatedItem[field]['path'] = `${json.data.postURL}/${json.data.formData.key}`
                                            updatedItem[field]['isActive'] = true
                                            return updatedItem;
                                        });

                                        setIsProgress(prevState => {
                                            const updatedProgress = { ...prevState }
                                            updatedProgress[field]['status'] = true
                                            updatedProgress['status'] = prevState['videoInfo']['hasFile'] ? prevState['videoInfo']['status']
                                                : prevState['srtInfo']['hasFile'] ? prevState['srtInfo']['status']
                                                    : (prevState['videoInfo']['hasFile'] && prevState['srtInfo']['hasFile'])
                                                        ? (prevState['videoInfo']['status'] && prevState['srtInfo']['status'])
                                                        : false
                                            return updatedProgress
                                        });

                                        toast.success('File uploaded successfully!');

                                        return true
                                    } else {
                                        return false
                                    }
                                },
                            })

                        } catch (error) {
                            setNewItem((prevItem) => {
                                const updatedItem = { ...prevItem };
                                updatedItem[field]['isActive'] = false
                                setIsProgress(isProgressHanler)
                                return updatedItem;
                            });

                            console.log(error)
                            return false
                        }
                    }
                },
                'default': (err) => {
                    console.log(err)
                    setIsProgress(isProgressHanler)
                    return false
                }
            })
            return true
        } catch (error) {
            console.error('Error uploading the video:', error);
            setIsProgress(isProgressHanler)
            return false
        }
    }


    return (
        <div className="popup-overlay">
            {loading && <div className='bg_loading'><div className='rxloading'></div></div>}

            <div className="CreateDialogContent" tabIndex="-1">
                <div className="dialog-header">
                    <div className="dialog-title">
                        <div className="css-1jvv4el">
                            <div className="css-4g6ai3">Create</div>
                            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 256 256" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                                <path d="M184.49,136.49l-80,80a12,12,0,0,1-17-17L159,128,87.51,56.49a12,12,0,1,1,17-17l80,80A12,12,0,0,1,184.49,136.49Z"></path>
                            </svg>
                            <div className="" data-tooltipped="" aria-describedby="tippy-tooltip-1" data-original-title="Edit Project Name">
                                <div className="css-759u60">
                                    <div className="css-70qvj9">
                                        <div className="css-n41mox">SUB/DUB Project</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" onClick={handleCancelClick}>
                        <svg stroke="currentColor" fill="none" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path>
                        </svg>
                    </button>
                </div>
                <div className="css-y640n9">
                    <div className="css-12dqhs0">
                        <div className="css-1kdh00r">
                            <div className="css-zqgbiu">

                                <div className='rx-form__item'>
                                    <div>
                                        <span>Select a folder (*): </span>
                                        <div style={{ width: '100%' }}>
                                            <RxSelectbox disabled={isEditData} options={folders} results={newItem.folder} onChange={(result, item) => { onBlurDataValue(result, 'folder', item) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='rx-form__item'>
                                    <div>
                                        <span>Name (*): </span>
                                        <div style={{ width: '320px' }}>
                                            <input tabIndex='1' type='text' value={newItem.name} onChange={(e) => onBlurData(e, 'name')} className='fullwidth-input' />
                                            {msgError && Object.keys(msgError) && Object.keys(msgError).indexOf('name') !== -1 && <div className='rxerror'>* This is a required information </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className={!isEditData ? 'rx-hidden' : 'rx-form__item'}>
                                    <div>
                                        <span>Status: </span>
                                        <div style={{ width: '320px' }}>
                                            <select className='fullwidth-input' value={newItem.status} onChange={(e) => onBlurData(e, 'status')}>
                                                {itemStatus.map(ele => (<option key={ele.id} value={ele.id}>{ele.value}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='rx-form__item'>
                                    <div>
                                        <span>Options: </span>
                                        <div style={{ width: '320px', display: 'contents' }}>
                                            <div className='rx-option'>
                                                <span>Soft sub</span>
                                                <input tabIndex='1' type='checkbox' value={newItem.hasSoftsub} defaultChecked={newItem.hasSoftsub} onChange={(e) => onBlurChecked(e, 'hasSoftsub')} className='fullwidth-input' />
                                            </div>
                                            <div className='rx-option'>
                                                <span>Hard sub</span>
                                                <input tabIndex='1' type='checkbox' value={newItem.hasHardsub} defaultChecked={newItem.hasHardsub} onChange={(e) => onBlurChecked(e, 'hasHardsub')} className='fullwidth-input' />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='rx-form__item'>
                                    <div>
                                        <span></span>
                                        <div style={{ width: '320px', display: 'contents' }}>

                                            <div className='rx-option'>
                                                <span>SRT (optional)</span>
                                                <input tabIndex='1' type='checkbox' value={newItem.hasSrt} defaultChecked={newItem.hasSrt} onChange={(e) => onBlurChecked(e, 'hasSrt')} className='fullwidth-input' />
                                            </div>
                                            {/* <div className='rx-option'>
                                                <span>HasLink</span>
                                                <input tabIndex='1' type='checkbox' value={newItem.hasLink} defaultChecked={newItem.hasLink} onChange={(e) => onBlurChecked(e, 'hasLink')} className='fullwidth-input' />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='rx-form__item'>
                                    <div>
                                        <span>Upload video 1 (*): </span>
                                        <div style={{ width: '100%', position: 'relative' }}>
                                            <RxMinioUploader videoInfo={editingData?.videoInfo} isEditData={isEditData} id='uploadaudio' type='video' callback={(e, file) => callbackUpload(e, 'videoInfo', file)} />
                                            {progress > 0 && <ProgressBar bgcolor={'#1990ff'} completed={progress} />}
                                            {msgError && Object.keys(msgError) && Object.keys(msgError).indexOf('videoInfo') !== -1 && <div className='rxerror'>* This is a required information </div>}
                                        </div>
                                    </div>
                                </div>
                                {newItem.hasSrt == 1 && <div className='rx-form__item'>
                                    <div>
                                        <span>Upload SRT File: </span>
                                        <div style={{ width: '100%', position: 'relative' }}>
                                            <RxMinioUploader videoInfo={editingData?.srtInfo} isEditData={isEditData} id='uploadfile' type='file' callback={(e, file) => callbackUpload(e, 'srtInfo', file)} />

                                        </div>
                                    </div>
                                </div>
                                }
                                {/* {newItem.hasLink == 1 && <div className='rx-form__item'>
                                    <div>
                                        <span>Link Input (Youtube): </span>
                                        <div style={{ width: '320px' }}>
                                            <input disabled={isEditData} tabIndex='1' placeholder='https://www.youtube.com/watch?v=9vAyaoaT7yo' type='text' value={newItem.linkYt} onChange={(e) => onBlurData(e, 'linkYt')} className='fullwidth-input' />
                                        </div>
                                    </div>
                                </div>
                                } */}


                                <div className="dialog-footer css-0 button-container">
                                    <div className='css-1rr4qq7'></div>
                                    <button onClick={handleCancelClick} type='button' className='chakra-button css-14jsxci'>Cancel</button>
                                    {!isEditData
                                        ? <button onClick={handleCreateClick} type='button' className='chakra-button css-9il83r'>{'Create'}</button>
                                        : <button onClick={handleUpdateClick} type='button' className='chakra-button css-9il83r'>{'Update'}</button>}
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div >
        </div >
    );
};

export default CreateItemPopup;
