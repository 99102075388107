global.rootRequire = global.rootRequire || function () { return { default: {} } }
const Admin = global.rootRequire('components/admin').default
const userComponent = global.rootRequire('components/admin/rbac/user').default
const roleComponent = global.rootRequire('components/admin/rbac/role').default
const permissionComponent = global.rootRequire('components/admin/rbac/permission').default
const loginComponent = global.rootRequire('components/authenticate/login').default
const registerComponent = global.rootRequire('components/authenticate/register').default


const playlistsComponent = global.rootRequire('components/admin/playlists').default
const downloadComponent = global.rootRequire('components/admin/download').default
const itemsComponent = global.rootRequire('components/admin/items').default
const overviewComponent = global.rootRequire('components/admin/overview').default
const dashboardComponent = global.rootRequire('components/admin/dashboard').default
const folderComponent = global.rootRequire('components/admin/folder').default


// [[RX_ROUTES_IMPORT]] //
const adminRoutes = { path: '/admin', component: Admin, routes: [{ path: '/admin/playlists', component: playlistsComponent, exact: true }] }

adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/playlists', component: playlistsComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/download', component: downloadComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/items', component: itemsComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/items:slug', component: itemsComponent }])

adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/dashboard', component: dashboardComponent }])
adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/dashboard:slug', component: dashboardComponent }])

adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/folder', component: folderComponent }])

adminRoutes.routes = adminRoutes.routes.concat([{ path: '/admin/overview', component: overviewComponent }])

// [[RX_ROUTES_admin]] //
let Modulroutes = []; Modulroutes = Modulroutes.concat([adminRoutes])

const rxgenrbacRoutes = { path: '/rbac', component: Admin, routes: [{ path: '/rbac', component: userComponent, exact: true }] }
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/user', component: userComponent }])
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/role', component: roleComponent }])
rxgenrbacRoutes.routes = rxgenrbacRoutes.routes.concat([{ path: '/rbac/permission', component: permissionComponent }])

const loginRoutes = { path: '/login', component: loginComponent }
const registerRoutes = { path: '/register', component: registerComponent }
// [[RX_ROUTES_RBAC]] //
Modulroutes = Modulroutes.concat([rxgenrbacRoutes, loginRoutes, registerRoutes])
// [[RX_ROUTES]] //
module.exports = Modulroutes
