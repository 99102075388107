import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
global.isclient && require('./header.css')

const { cartClear, cartAdd, cartDelete, rxnavToggle, rxnavClose, rxnavChange, rxsearchChange, rxsearchClear } = global.rootRequire('redux')
class Header extends Component {
  state = {
    menuOpen: false,
  };

  toggleMenu = () => {
    this.setState((prevState) => ({ menuOpen: !prevState.menuOpen }));
  };

  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  render() {
    const { menuOpen } = this.state;

    return (
      <div className={`homenav ${menuOpen ? 'homenav--open' : ''}`}>
        <div className='container homenav__container'>
          <div className='homenav__logo' />
          <div className='homenav__main'>
            <div className='homenav__left'>
              <div className='homenav__logo'>
                <Link to='/'>
                  <img className='homenav__logoimg' alt='logoimg' src={'/images/aicola/logo_2.png'} />
                </Link>
              </div>
            </div>
            <div className='homenav__right'>
              <div className='hamburger' onClick={this.toggleMenu}>
                <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
                <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
                <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
              </div>
              <div className={`menu ${menuOpen ? 'open' : ''}`}>
                <Link to='/login' onClick={this.closeMenu}>
                  <div className='homenav__item'>Home</div>
                </Link>
                <Link to='/login' onClick={this.closeMenu}>
                  <div className='homenav__item'>Tools</div>
                </Link>
                <Link to='/login' onClick={this.closeMenu}>
                  <div className='homenav__item'>Pricing</div>
                </Link>
                <Link to='/about' onClick={this.closeMenu}>
                  <div className='homenav__item'>About us</div>
                </Link>
                <Link to='/contact' onClick={this.closeMenu}>
                  <div className='homenav__item'>Contact</div>
                </Link>
                <div className='homenav__item homenav__item--login__'>
                  <div className='homenav__item--login_btn_box'>
                    <Link to='/login'>Get started</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  cart: state.cart,
  rxnav: state.rxnav,
  auth: state.auth,
  favorite: state.favorite,
  category: state.category,
});

const mapDispatchToProps = {
  rxnavToggle,
  rxnavClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
